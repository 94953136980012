import { TallerFormadorService } from './../services/taller-formador.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { TallerParticipantesService } from '../../taller-participante/services/taller-participantes.service';
import { CrearTallerParticipanteComponent } from '../../taller-participante/crear-taller-participante/crear-taller-participante.component';
import { TallerParticipanteResponse } from '../../taller-participante/interfaces/taller-participante-response';

@Component({
  selector: 'app-detalle-taller-instancia',
  templateUrl: './detalle-taller-instancia.component.html',
  styleUrls: ['./detalle-taller-instancia.component.sass']
})
export class DetalleTallerInstanciaComponent implements OnInit {

  
  tallerId: number;
  @Input() proyectoId: number;
  instanciaId: number;
  private token;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'presente', 'comentario', 'acciones'];
  cargando: boolean;
  uploading: boolean;
  total: number;
  participantes: any;
  searchForm: FormGroup;
  file: any;

  constructor(private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private tallerFormadorService: TallerFormadorService, public dialog: MatDialog, private fB: FormBuilder, private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.tallerId = +this.route.snapshot.paramMap.get('idTaller');
    this.instanciaId = +this.route.snapshot.paramMap.get('idInstancia');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getParticipantes();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getParticipantes() {
    this.cargando = true;
    this.tallerFormadorService.getTallerFormador(this.tallerId, this.instanciaId, this.token)
      .subscribe((respuesta: any) => {
        this.participantes = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
    this.cargando = false;
  }

  crear() {
    const dialogRef = this.dialog.open(CrearTallerParticipanteComponent, {
      width: '700px',
      data: { tallerId: this.tallerId, proyectoId: this.proyectoId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getParticipantes();
      }
    });
  }

  verDetalle(id: number) {
    // const dialogRef = this.dialog.open(DetalleTallerParticipanteComponent, {
    //   width: '700px',
    //   data: { tallerId: this.tallerId, participanteId: id, proyectoId: this.proyectoId }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result === 'updated') {
    //     this.getParticipantes();
    //   }
    // });
  }

  borrar(id: number) {
    // this.tallerFormadorService.borrarTallerFormador(this.tallerId, id)
    //   .subscribe((respuesta: TallerParticipanteResponse) => {
    //     if (respuesta.success) {
    //       this.toastr.success('Participante borrado exitosamente', 'Borrar Participante');
    //     }
    //     this.getParticipantes();
    //   }, (error: HttpErrorResponse) => {
    //     this.errorService.handleError(error);
    //   }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getParticipantes();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getParticipantes();
  }

  pageChange() {
    this.getParticipantes();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  fileChangeEvent(files): void {
    this.uploading = true;
    // this.file = files[0];
    this.participantes = [
      {
        id: 1,
        actor_nombre: 'Sofia',
        actor_apellido: 'Cáceres'
      },
      {
        id: 2,
        actor_nombre: 'Matias',
        actor_apellido: 'Orue'
      },
      {
        id: 3,
        actor_nombre: 'Elias',
        actor_apellido: 'Caballero'
      },
      {
        id: 4,
        actor_nombre: 'John',
        actor_apellido: 'Silver'
      },
      {
        id: 5,
        actor_nombre: 'Josefina',
        actor_apellido: 'Barrios'
      },
      {
        id: 6,
        actor_nombre: 'Inocencio',
        actor_apellido: 'Martinez'
      },
      {
        id: 7,
        actor_nombre: 'Beatriz',
        actor_apellido: 'Osorio'
      },
      {
        id: 8,
        actor_nombre: 'Juan Carlos',
        actor_apellido: 'Benitez'
      },{
        id: 9,
        actor_nombre: 'Americo',
        actor_apellido: 'Samudio'
      }
    ];
    this.toastr.success('Participantes importados exitosamente', 'Importar Participantes');
    this.uploading = false;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  volverProyecto(){
    this.router.navigate(['../../../../../'], { relativeTo: this.route });
  }

}
