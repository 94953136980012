import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class PreguntasTiposService {

  constructor(private http: HttpClient, private global: Globals) { }

  obtenerPreguntasTipos(search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/preguntasTipos', search, pagination);
    return this.http.get(url, { headers });
  }

  obtenerPreguntaTipo(preguntaTipoId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntasTipos/' + preguntaTipoId;
    return this.http.get(url, { headers });
  }

  crearPreguntaTipo(jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntasTipos';
    return this.http.post(url, jsonData, { headers });
  }

  borrarPreguntaTipo(preguntaTipoId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntasTipos/' + preguntaTipoId;
    return this.http.delete(url, { headers });
  }

  editarPreguntaTipo(preguntaTipoId, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntasTipos/' + preguntaTipoId;
    return this.http.put(url, jsonData, { headers });
  }
}
