import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class TallerGestionProyectoService {

  constructor(private http: HttpClient, private global: Globals) { }

  getProyectoTalleres(token, proyectoId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/proyectos/' + proyectoId + '/gruposTalleres', search, pagination);
    return this.http.get(url, { headers });
  }
  getProyectoTaller(proyectoId, tallerId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/gruposTalleres/' + tallerId;
    return this.http.get(url, { headers });
  }
  actualizarProyectoTaller(proyectoId, tallerId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/gruposTalleres/' + tallerId;
    return this.http.put(url, json, { headers });
  }
  crearProyectoTaller(token, proyectoId, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/gruposTalleres';
    return this.http.post(url, json, { headers });
  }
  borrarProyectoTaller(proyectoId, tallerId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/gruposTalleres/' + tallerId;
    return this.http.delete(url, { headers });
  }
}
