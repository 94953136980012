import { FormStatusService } from './../../../../../util/services/form-status/form-status.service';
import { OpcionPregunta } from './interfaces/opcion-pregunta';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from './../../../../../util/services/error.service';
import { OpcionesPreguntaService } from './services/opciones-pregunta.service';
import { Pregunta } from './../../interfaces/pregunta';
import { Component, OnInit, Input } from '@angular/core';
import { OpcionesPreguntaResponse, OpcionPreguntaResponse } from './interfaces/opcion-pregunta-response';

@Component({
  selector: 'app-opciones-pregunta',
  templateUrl: './opciones-pregunta.component.html',
  styleUrls: ['./opciones-pregunta.component.sass']
})
export class OpcionesPreguntaComponent implements OnInit {

  @Input() pregunta: Pregunta;
  opcionesForm: FormGroup;
  opciones: OpcionPregunta[];
  cargando: boolean;
  public borradoTextoOpcion = '¿Desea borrar una Opción?';
  constructor(private opcionesPreguntaService: OpcionesPreguntaService,
    private errorService: ErrorService, private toastr: ToastrService, private fB: FormBuilder,
    private fS: FormStatusService) { }

  ngOnInit() {
    this.cargarFormulario();
    this.getOpciones();
  }

  cargarFormulario() {
    this.opcionesForm = this.fB.group({
      opciones: this.fB.array([])
    });
  }

  getOpciones() {
    this.cargando = true;
    this.opcionesPreguntaService.obtenerOpciones(this.pregunta.id).subscribe((respuesta: OpcionesPreguntaResponse) => {
      this.opciones = respuesta.data;
      if (respuesta.metadata.total > 0) {
        this.opcionesForm.setControl('opciones', this.setOpciones(this.opciones));
        this.opcionesForm.controls.opciones.disable();
      } else {
        this.opcionesForm.removeControl('opciones');
        this.opcionesForm.addControl('opciones', this.fB.array([]));
        this.opcionesForm.controls.opciones.disable();
      }
    }, error => {
      this.errorService.handleError(error);
    }, () => { this.cargando = false; });
  }

  private setOpciones(opciones: OpcionPregunta[]) {
    const formArray = new FormArray([]);
    opciones.forEach(opcion => {
      formArray.push(this.fB.group({
        codigo: opcion.codigo,
        enc_pregunta_id: opcion.enc_pregunta_id,
        id: opcion.id,
        porcentaje_opcion: opcion.porcentaje_opcion,
        texto: opcion.texto
      }));
    });
    return formArray;
  }

  addOpcionBtnClick() {
    (this.opcionesForm.controls.opciones as FormArray).push(this.addOpcionesForm());
  }

  addOpcionesForm(): FormGroup {
    return this.fB.group({
      codigo: undefined,
      enc_pregunta_id: undefined,
      porcentaje_opcion: undefined,
      texto: undefined
    });
  }


  insertarOpcion(nuevaOpcion) {

    const formGroup = this.addOpcionesForm();

    formGroup.patchValue(nuevaOpcion);

    const jsonData = this.fS.getJsonFromForm(formGroup);

    if (nuevaOpcion.id) {
      this.opcionesPreguntaService.editarOpcion(this.pregunta.id, nuevaOpcion.id, jsonData)
        .subscribe((respuesta: OpcionPreguntaResponse) => {
          if (respuesta.success) {
            this.toastr.success('Opcion editada exitosamente', 'Edición de Opción');
            this.getOpciones();
          } else {
            this.toastr.error('Error al editar opción', 'Edición de Opción', { toastClass: 'toastr-custom-error' });
          }
        },
          error => {
            this.errorService.handleError(error);
          }).add(() => {
          });
    } else {
      this.opcionesPreguntaService.crearOpcion(this.pregunta.id, jsonData)
        .subscribe((respuesta: OpcionPreguntaResponse) => {
          if (respuesta.success) {
            this.toastr.success('Opción creada exitosamente', 'Creación de Opción');
            this.getOpciones();
          } else {
            this.toastr.error('Error al crear opción', 'Creación de Opción', { toastClass: 'toastr-custom-error' });
          }
        },
          error => {
            this.errorService.handleError(error);
          }).add(() => {
          });
    }
  }

  borrarOpcion(opcionId, index) {
    if (opcionId) {
      this.opcionesPreguntaService.borrarOpcion(this.pregunta.id, opcionId)
        .subscribe((respuesta: OpcionPreguntaResponse) => {
          if (respuesta.success) {
            this.toastr.success('Opción borrada exitosamente', 'Borrar Opción');
            (this.opcionesForm.controls.opciones as FormArray).removeAt(index);
            this.getOpciones();
          } else {
            this.toastr.error('Error al borrar opción', 'Borrar Opción', { toastClass: 'toastr-custom-error' });
          }
        },
          error => {
            this.errorService.handleError(error);
          }).add(() => {
          });
    } else {
      (this.opcionesForm.controls.opciones as FormArray).removeAt(index);
    }
  }

  editarOpcion(index) {
    (this.opcionesForm.controls.opciones as FormArray).at(index).enable();
  }

}
