/* tslint:disable:variable-name */
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ParametrosData} from '../../../Pages/Login/interfaces/parametros';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CombosService} from '../../services/combos/combos.service';
import {LocalStorageService} from '../../local-storage.service';
import {ErrorService} from '../../services/error.service';
import {ComboPaisesResponse, CombosResponse} from '../../interfaces/combos-response';

@Component({
  selector: 'app-grilla-actores',
  templateUrl: './grilla-actores.component.html',
  styleUrls: ['./grilla-actores.component.sass']
})
export class GrillaActoresComponent implements OnInit {

  cargando: boolean;
  sinBusqueda = true;
  searchForm: FormGroup;
  paramDefault: ParametrosData;
  actores: any[] = [];
  facilitadoresSeleccionados: any[] = [];
  tiposDocumento: any[] = [];
  paises: any[] = [];
  selected = null;
  displayedColumns = ['actor', 'documento', 'acciones'];
  proProyectoId: number;
  proActorRolId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<GrillaActoresComponent>,
    private fB: FormBuilder,
    private comboService: CombosService,
    private localStorage: LocalStorageService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
    this.proProyectoId = this.data.proProyectoId;
    this.proActorRolId = this.data.proActorRolId;
    this.paramDefault = this.localStorage.getParametrosFromStorage();
    this.makeForm();
    this.getPaises();
    this.getTiposDocumentos();
  }

  makeForm() {
    this.searchForm = this.fB.group({
      nombre_completo: null,
      nro_documento: null,
      adm_pais_id: { value: null, disabled: true },
      adm_documento_tipo_id: { value: null, disabled: true },
      pro_actor_rol_id: null,
    });

    this.searchForm.get('nro_documento').valueChanges.subscribe(value => {
      if (value) {
        this.searchForm.controls.adm_pais_id.enable();
        this.searchForm.controls.adm_documento_tipo_id.enable();
      } else {
        this.searchForm.controls.adm_pais_id.disable();
        this.searchForm.controls.adm_documento_tipo_id.disable();
      }
    });
  }

  getActores() {
    this.cargando = true;
    if (this.proActorRolId) {
      this.searchForm.get('pro_actor_rol_id').patchValue(this.proActorRolId);
    }
    this.comboService.getActoresOptimizado(this.proProyectoId, this.searchForm.value)
      .subscribe((response: any) => {
        this.actores = response.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
        this.cargando = false;
      });
  }

  getTiposDocumentos() {
    this.comboService.getTipoDocumentosCombo()
      .subscribe((respuesta: CombosResponse) => {
        this.tiposDocumento = respuesta.data;
        if (this.paramDefault.TIPODOCUMENTO) {
          this.searchForm.controls.adm_documento_tipo_id.setValue(this.paramDefault.TIPODOCUMENTO);
        }
      }, error => {
        this.errorService.handleError(error);
      });
  }

  getPaises() {
    this.comboService.getPaisesCombo()
      .subscribe((respuesta: ComboPaisesResponse) => {
        this.paises = respuesta.data;
        if (this.paramDefault.PAIS) {
          this.searchForm.controls.adm_pais_id.setValue(this.paramDefault.PAIS);
        }
      }, error => {
        this.errorService.handleError(error);
      });
  }

  closeBusqueda() {
    this.matDialogRef.close();
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  filtrar() {
    const nombre_completo = this.searchForm.get('nombre_completo').value;
    const nro_documento = this.searchForm.get('nro_documento').value;
    const adm_pais_id = this.searchForm.get('adm_pais_id').value;
    const adm_documento_tipo_id = this.searchForm.get('adm_documento_tipo_id').value;
    if (!nombre_completo && !nro_documento && !adm_pais_id && !adm_documento_tipo_id) {
      this.sinBusqueda = true;
      return;
    } else {
      this.sinBusqueda = false;
    }
    this.getActores();
  }

  onReset() {
    this.searchForm.reset();
    this.sinBusqueda = true;
  }

  actorSeleccionado(object: any) {
    this.matDialogRef.close(object);
  }

  onCheckboxChange(event: any, facilitador: any) {
    if (event.checked) {
      this.facilitadoresSeleccionados.push(facilitador);
    } else {
      const index = this.facilitadoresSeleccionados.findIndex(f => f.id === facilitador.id);
      if (index >= 0) {
        this.facilitadoresSeleccionados.splice(index, 1);
      }
    }
  }

  onClose(object: any) {
    this.matDialogRef.close(object);
  }

  resetFacilitadores() {
    this.facilitadoresSeleccionados = [];
    this.matDialogRef.close();
  }
}
