import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';


@Component({
  selector: 'app-lista-miembros-pagos',
  templateUrl: './lista-miembros-pagos.component.html',
  styleUrls: ['./lista-miembros-pagos.component.sass']
})
export class ListaMiembrosPagosComponent implements OnInit {

  heading = 'Lista de pagos';
  subheading = 'En este módulo se encuentra la lista de pagos';
  icon = 'pe-7s-study icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'apellido', 'aportes', 'cant_acciones_fondo', 'acciones'];
  miembros;
  searchForm: FormGroup;
  @Input() empresaId: number;
  idEmpresa: any;
  idProyecto: any;

  constructor(private router: Router, private route: ActivatedRoute, 
              private fB: FormBuilder, public dialog: MatDialog,
              private localStorageService: LocalStorageService,
              private toastr: ToastrService,
              private errorService: ErrorService) { 
                this.searchForm = this.fB.group({
                  nombre: '',
                  codigo: ''
                });
              }

  ngOnInit(): void {
    this.idEmpresa = +this.route.snapshot.paramMap.get('idEmpresa');
    if (this.localStorageService.getStoreSearchFrom() === 'mant_acti_laboral') {
      this.searchForm.patchValue(this.localStorageService.getStoreSearch());
    }
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.getMiembros();
  }
  
  getMiembros(){
      this.miembros = [];
      this.miembros.push(
        {
          id: 1,
          nombre: 'Jorge',
          apellido: 'Ruiz Diaz',
          aportes: 10,
          cant_acciones_fondo: 5,
        })
  }

  crear() {
    this.router.navigate(['./miembros/new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.router.navigate(['./miembros', id], {relativeTo: this.route});
  }

 /* borrar(id: number) {
       this.remolcadoresService.borrarRemolcador(this.idEmpresa, id)
       .subscribe((respuesta: any) => {
         if (respuesta.success) {
           this.toastr.success('Remolcador borrado exitosamente', 'Borrar Remolcador');
         }
         this.getRemolcadores();
       }, (error) => {
         this.errorService.handleError(error);
       }, () => { });
  } */

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  onClick(event: any) {
    return;
  }

  pageChange() {
    return;
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }


}

