import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Documento} from './interfaces/documento.interface';
import {DocumentoService} from './services/documento.service';
import {LocalStorageService} from '../../../util/local-storage.service';
import {DocumentosResponse} from './interfaces/documento-response.interface';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from '../../../util/services/error.service';
import {PersonasResponse} from '../../Administracion/personas/interfaces/personas-response.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-documentacion-proyecto',
  templateUrl: './documentacion-proyecto.component.html',
  styleUrls: ['./documentacion-proyecto.component.sass']
})
export class DocumentacionProyectoComponent implements OnInit {
  cargando: boolean;
  private token;
  idProyecto: number;
  total: number;
  documentos: Documento[];
  pagination = { page: 1, per_page: 10 };
  public lottieConfig = {
    path: 'assets/lottie/empty-box.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };
  constructor(
    private documentosService: DocumentoService,
    private router: Router,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private fB: FormBuilder,
    private ngxPermissionService: NgxPermissionsService
  ) {
    this.token = this.localStorage.getTokenFromStorage();
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.getDocumentos();
  }
  private getDocumentos() {
    this.cargando = true;
    this.documentosService.getDocumentos(this.token, this.idProyecto, null, this.pagination)
      .subscribe((respuesta: DocumentosResponse) => {
        this.documentos = respuesta.data;
        this.total = respuesta.metadata.total;
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      this.cargando = false;
    });
  }
  verDetalle(id) {
    this.ngxPermissionService.hasPermission('PRO/mant_proyectos_documentos/get633')
      .then(result => {
        if (result) {
          this.router.navigate(['./', id], { relativeTo: this.route });
        }
      });
  }
  crearDocumentacion() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }
  pageChange() {
    this.getDocumentos();
  }

  borrarDocumento(id: any) {
    this.documentosService.borrarDocumento(this.token, this.idProyecto, id)
      .subscribe((respuesta: PersonasResponse) => {
        this.toastr.success('Documento borrado exitosamente', 'Borrar Documento');
        this.getDocumentos();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => {
      });
  }
  onClick(event: any) {
  }
}
