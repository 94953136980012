import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ParametricosProyectoService {

  constructor(
    private http: HttpClient,
    private global: Globals) {
  }

  getCantidadPorItem(token, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/dashboardsProyectos', search, pagination);
    return this.http.get(url, {headers});
  }
}
