import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DashboardToken, GroupPowerBiReports, PowerBiReport} from '../../services/power-bi/interfaces/power-bi-bix';
import {BixdashboardsService} from '../../services/power-bi/bixdashboards.service';
import {LocalStorageService} from '../../local-storage.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from '../../services/error.service';
import {Globals} from '../../../Globals';
import {retry} from 'rxjs/operators';

@Component({
  selector: 'app-ngx-dashboards-bix',
  templateUrl: './ngx-dashboards-bix.component.html',
  styleUrls: ['./ngx-dashboards-bix.component.scss']
})
export class NgxDashboardsBixComponent implements OnInit {

  @Input() dashboardId: string;
  @Input() filtro: string;
  currentDashboard: PowerBiReport;
  config = {
    type: 'report',
    accessToken: '',
    embedUrl: '',
    id: '',
  };
  @Output() loaded = new EventEmitter();
  cargando: boolean;
  groupPBI: string;
  orgNamePBI: string;

  constructor(private bixDashboardService: BixdashboardsService, private localStorage: LocalStorageService,
              private toastr: ToastrService, private errorService: ErrorService, private globals: Globals) {
  }

  ngOnInit() {
    this.cargando = true;
    this.orgNamePBI = this.localStorage.getParametrosFromStorage().ORG_NAME;
    this.groupPBI = this.localStorage.getParametrosFromStorage().GROUP_ID;
    this.getDashboard();
  }

  getDashboard() {
    const headers = this.globals.getPowerBiHeaders();
    this.bixDashboardService.obtenerReportesDeGrupo(this.orgNamePBI, this.groupPBI, headers).pipe(retry(2))
      .subscribe((response: GroupPowerBiReports) => {
        // TODO: implement here
        this.localStorage.saveItem('bi_data', response.value);
        this.currentDashboard = response.value.find(element => element.id === this.dashboardId);
        // Acá se debe de agregar los filtros a ser aplicados
        if (this.currentDashboard) {
          if (this.filtro) {
            this.config.embedUrl = `${this.currentDashboard.embedUrl}${this.filtro}`;
          } else {
            this.config.embedUrl = `${this.currentDashboard.embedUrl}`;
          }
        } else {
          this.toastr.error('No se ha encontrado el dashboard para esta interfaz, favor contacte con el administrador.', 'Error');
          console.error('El ID de el dashboard buscado no existe, por favor, pida al administrador que verifique los IDs en los parámetros del sistema');
        }
        // this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dashboardURL);
      }, error => {
        this.errorService.handleError(error);
        this.loaded.emit(true);
      }, () => {
        const dashboardToken = this.localStorage.getSavedItem('dashboard_token') as DashboardToken;
        const now = new Date();
        if (!dashboardToken) {
          this.globals.prepareDashboardsTokens().then(token => {
            this.config.accessToken = token;
            this.loaded.emit(false);
            this.cargando = false;
          }).catch(reason => {
            console.error(reason, 'Dashboard Token Service');
          });
        } else if (new Date(dashboardToken.expiration) <= now) {
          this.globals.prepareDashboardsTokens().then(token => {
            this.config.accessToken = token;
            this.loaded.emit(false);
            this.cargando = false;
          }).catch(reason => {
            this.toastr.error(reason, 'Dashboard Token Service');
          });
        } else {
          this.config.accessToken = dashboardToken.token;
          this.loaded.emit(false);
          this.cargando = false;
        }
      });
  }

}
