import { ErrorService } from 'src/app/util/services/error.service';
import { PaisesProyectoService } from './services/paises-proyecto.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PaisesProyectoResponse } from './interfaces/pais-proyecto-response';
import { PaisProyecto } from './interfaces/pais-proyecto';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../Globals';

@Component({
  selector: 'app-pais-proyecto',
  templateUrl: './pais-proyecto.component.html',
  styleUrls: ['./pais-proyecto.component.sass']
})
export class PaisProyectoComponent implements OnInit {
  @Output() selectedCountry: EventEmitter<PaisProyecto> = new EventEmitter<PaisProyecto>();
  private token;
  idPais: number;
  cargando: boolean;
  paises: PaisProyecto[];
  total: number;
  paisElegido: any;
  urlImg: string;
  constructor(private localStorage: LocalStorageService,
              private paisesProyectoService: PaisesProyectoService,
              private errorService: ErrorService,
              private route: ActivatedRoute,
              private router: Router, private global: Globals
            ) {}

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.urlImg = this.global.FILE_ENDPOINT;
    this.getPaises();
  }

  getPaises() {
    this.cargando = true;
    this.paisesProyectoService.getPaisesProyectos(this.token)
      .subscribe((respuesta: PaisesProyectoResponse) => {
        this.paises = respuesta.data;
        if (sessionStorage.getItem('idPais')) {
          this.clickAuto();
        }
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  clickAuto() {
    this.cargando = true;
    this.paisElegido = this.paises.filter( pais => pais.id === +sessionStorage.getItem('idPais'));
    this.selectedCountry.emit(this.paisElegido[0]);
    this.cargando = false;
    sessionStorage.removeItem('idPais');
  }
  onClick(pais) {
    this.selectedCountry.emit(pais);
  }

  crearProyecto(){
    this.router.navigate(['./new'], {relativeTo: this.route});
  }
}
