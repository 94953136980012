import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { GrupoAhorroService } from '../../../../services/grupo-ahorro.service';
import { ReunionesResponse } from '../interfaces/reunion.interface';


@Component({
  selector: 'app-detalle-reunion',
  templateUrl: './detalle-reunion.component.html',
  styleUrls: ['./detalle-reunion.component.sass']
})
export class DetalleReunionComponent implements OnInit {

  private token;
  idProyecto: number;
  idGrupo: number;
  idCiclo: number;
  idReunion: number;
  cargando: boolean;
  process: boolean;
  editable = true;
  editableMiembros = true;
  detalleReunionForm: FormGroup;
  miembrosForm: FormGroup;
  controlForm: FormGroup;
  reunion_detalle;
  reunion_cabecera;
  miembros;
  habilitarBoton = true;
  reunionCabecera: any;


  constructor(
    private toastr: ToastrService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private fs: FormStatusService,
    private combosService: CombosService,
    private route: ActivatedRoute,
    private router: Router,
    private gruposAhorrosService: GrupoAhorroService,
    private readonly sweetAlert2Loader: SweetAlert2LoaderService
  ) {
    this.token = this.localStorage.getTokenFromStorage();

    this.detalleReunionForm = fB.group({
      numero: [{ value: null, disabled: this.editable }],
      fecha: [{ value: null, disabled: this.editable }],
      hora: [{ value: null, disabled: this.editable }],
    });

    this.miembrosForm = fB.group({
      miembro: this.fB.array([])
    });

  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    this.idCiclo = +this.route.snapshot.paramMap.get('idCiclo');
    this.idReunion = +this.route.snapshot.paramMap.get('idReunion');
    this.getCabecera();
  }

  private setMiembro(miembros: any) {
    const formArray = new FormArray([]);
    /* Acá asigne el id  */
    miembros.forEach(miembro => {
      formArray.push(this.fB.group({
        id: [{ value: miembro.id, disabled: true }],
        gru_grupo_ciclo_miembro_id: [{ value: miembro.gru_grupo_ciclo_miembro_id, disabled: true }],
        presente: [{ value: miembro.presente, disabled: this.editableMiembros }],
        monto_fondo_social: [{ value: miembro.monto_fondo_social, disabled: true }],
        monto_fondo_prestamo: [{ value: miembro.monto_fondo_prestamo, disabled: true }],
        cantidad_acciones: [{ value: miembro.cantidad_acciones, disabled: this.editableMiembros }],
        cantidad_multas: [{ value: miembro.cantidad_multas, disabled: this.editableMiembros }],
        comentarios: [{ value: miembro.comentarios, disabled: this.editableMiembros }],
        estado: [miembro.grupo_ciclo_miembro.estado]
      }));
    });
    return formArray;
  }

  get() {
    this.cargando = true;
    this.gruposAhorrosService.getReunion(this.idProyecto, this.idGrupo, this.idCiclo, this.idReunion)
      .subscribe((response: ReunionesResponse) => {
        if (response.success) {
          this.reunion_detalle = response.data;
          this.miembrosForm.setControl('miembro', this.setMiembro(this.reunion_detalle));
          if (this.reunion_detalle.length > 0) {
            this.detalleReunionForm.patchValue(this.reunion_detalle[0].grupo_ciclo_reunion_cabecera);
            this.reunionCabecera = this.reunion_detalle[0].grupo_ciclo_reunion_cabecera;
          }
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  addDocumentoForm(): FormGroup {
    return this.fB.group({
      id: undefined,
      presente: undefined,
      monto_fondo_social: undefined,
      monto_fondo_prestamo: undefined,
      cantidad_acciones: undefined,
      cantidad_multas: undefined,
      estado: undefined
    });
  }

  public editableSwitcher() {
    this.detalleReunionForm = this.fs.cambiarEstado(this.detalleReunionForm, this.editable, ['numero'], true);
    this.editable = !this.editable;
  }

  editarDocumento() {
    this.habilitarBoton = false; // Se habilita el botón en la interfaz para mandar los datos
    // Acá saco los controles que se mandan al backend
    // const presente = item.get('presente') as FormControl;
    // const cantidad_acciones = item.get('cantidad_acciones') as FormControl;
    // const id = item.get('id') as FormControl;
    // Primero deshabilito la fila entera y luego habilito solo los controles que se mandan a backend (los de arriba)
    // Intenté de otras maneras pero esta es la forma que conseguí
    // (this.miembrosForm.controls.miembro as FormArray).at(index).disable();
    // cantidad_acciones.enable();
    // presente.enable();
    // id.enable();
    // this.miembrosForm.get('miembro').enable();
    (this.miembrosForm.controls.miembro as FormArray).controls.forEach(element => {
      if (element.get('estado').value !== 'I') {
        element.get('id').enable();
        element.get('presente').enable();
        element.get('cantidad_acciones').enable();
        element.get('cantidad_multas').enable();
        element.get('comentarios').enable();
        element.get('estado').disable();
      }
    });
    this.editableMiembrosSwitch();
  }

  editableMiembrosSwitch(isBack = null) {
    this.editableMiembros = !this.editableMiembros;
    if (isBack) {
      this.miembrosForm = this.fs.cambiarEstado(this.miembrosForm, this.editableMiembros);
    }
  }

  cancelar(index) {
    (this.miembrosForm.controls.miembro as FormArray).at(index).disable();
    // Si el formulario no está siendo editado, el botón se muestra en disabled
    if (this.miembrosForm.status === 'DISABLED') {
      this.habilitarBoton = true;
    } else {
      this.habilitarBoton = false;
    }
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  volverGrupo() {
    this.router.navigate(['../../../../../'], { relativeTo: this.route });
  }

  volverCiclos() {
    this.router.navigate(['../../../../'], { relativeTo: this.route });
  }

  actualizarCabezera() {
    this.process = true;
    const jsonData = this.fs.getJsonFromForm(this.detalleReunionForm);
    this.gruposAhorrosService.editarReunion(this.idProyecto, this.idGrupo, this.idCiclo, this.idReunion, jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Reunion editada exitosamente', 'Edición de Reunión');
          this.editableSwitcher();
        } else {
          this.toastr.error('Error al editar la reunión', 'Edición de Reunión', { toastClass: 'toastr-custom-error' });
        }
      },
        error => {
          this.errorService.handleError(error);
        }).add(() => {
          this.process = false;
        });
  }

  getActores() {
    this.cargando = true;
    this.gruposAhorrosService.getMiembrosCiclos(this.idProyecto, this.idGrupo, this.idCiclo)
      .subscribe((response: any) => {
        if (response.success) {
          this.miembros = response.data;
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.get();
      });
  }

  getCabecera() {
    this.cargando = true;
    // this.gruposAhorrosService.getReunionCabecera(this.idProyecto, this.idGrupo, this.idCiclo, this.idReunion)
    //   .subscribe((response: ReunionesResponse) => {
    //     if (response.success) {
    //       this.reunion_cabecera = response.data;
    //       this.detalleReunionForm.patchValue(this.reunion_cabecera);
    //       this.miembrosForm.setControl('miembro', this.setMiembro(this.reunion_detalle));
    //     }
    //   }, error => {
    //     this.errorService.handleError(error);
    //   }, ()=> {
    this.getActores();
    // });
  }

  editarMiembro(miembro = null) {
    const text = '<b>' + '¿Está seguro que ha cargado completamente el registro de asistencia de esta reunión? <br> <b>ATENCION:</b> Esta acción es irreversible' + '</b>';
    this.sweetAlert2Loader.swal.then((swal) =>
      swal.fire({
        html: text,
        imageWidth: 50,
        imageHeight: 50,
        showCancelButton: true,
        confirmButtonText: 'Sí, guardar!',
        confirmButtonColor: '#FF0000',
        cancelButtonText: 'No, cancelar',
        focusCancel: true,
        width: 400
      }).then((result) => {
        if (result.value) {
          this.process = true;
          this.quitarEstadosInactivos();
          const data = { miembro: this.miembrosForm.get('miembro').value };
          const jsonData = JSON.stringify(data);
          this.gruposAhorrosService.editarReunion(this.idProyecto, this.idGrupo, this.idCiclo, this.idReunion, jsonData)
            .subscribe((miembro: any) => {
              if (miembro.success) {
                this.toastr.success('Miembros actualizados', 'Miembros de reunión');
                // this.ngOnInit(); // Al llamar el this.get() recibo un error de CORS, con el ngOnInit no. (por ahora está de esta manera)
              } else {
                this.toastr.error('Error al editar el miembro', 'Edición de Miembro', { toastClass: 'toastr-custom-error' });
              }
            },
              error => {
                this.errorService.handleError(error);
              }, () => {
                this.editableMiembrosSwitch();
                this.miembrosForm.get('miembro').disable();
                this.get();
              }).add(() => {
                this.habilitarBoton = true;
                this.process = false;
              });
        }
      })
    )
  }

  changePresencia(event, item) {
    item.get('presente').setValue(event.checked ? 'P' : 'A')
    if (!event.checked) {
      item.get('id').enable();
      item.get('cantidad_acciones').disable();
      item.get('cantidad_multas').disable();
      item.get('comentarios').disable();
    } else {
      item.get('id').enable();
      item.get('cantidad_acciones').enable();
      item.get('cantidad_multas').enable();
      item.get('comentarios').enable();
    }
  }

  quitarEstadosInactivos(){
    (this.miembrosForm.controls.miembro as FormArray).controls.forEach(element => {
      if (element.get('estado').value === 'I') {
        element.get('estado').disable();
      }
    });
  }

}
