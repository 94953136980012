import { ToastrService } from 'ngx-toastr';
import { ErrorService } from './../../../../util/services/error.service';
import { PortafolioResponse } from './../interfaces/portafolio-response';
import { LocalStorageService } from './../../../../util/local-storage.service';
import { PortafolioService } from './../services/portafolio.service';
import { FormStatusService } from './../../../../util/services/form-status/form-status.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Portafolio } from '../interfaces/portafolio';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-detalle-portafolio',
  templateUrl: './detalle-portafolio.component.html',
  styleUrls: ['./detalle-portafolio.component.sass']
})
export class DetallePortafolioComponent implements OnInit {

  public portafolioForm: FormGroup;
  editable = true;
  cargando: boolean;
  private token;
  portafolioId: number;
  portafolio: Portafolio;
  process: boolean;
  puedeListarProgramas = true;

  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private fS: FormStatusService,
    private portafolioService: PortafolioService, private localStorage: LocalStorageService, private errorService: ErrorService,
    private toastr: ToastrService, private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.portafolioId = +this.route.snapshot.paramMap.get('portafolioId');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.portafolioForm = this.fB.group({
      nombre: [{ value: undefined, disabled: this.editable }, Validators.required],
      codigo: [{ value: undefined, disabled: this.editable }],
      descripcion: [{ value: undefined, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.portafolioService.getPortafolio(this.portafolioId, this.token)
      .subscribe((respuesta: PortafolioResponse) => {
        this.portafolio = respuesta.data;
        this.portafolioForm.patchValue(this.portafolio);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.verificarPermisos();
        this.cargando = false;
      });
  }

  verificarPermisos() {
    this.permissionService.hasPermission('PARPRO/mant_programas/list405').then(result => {
      this.puedeListarProgramas = result;
    });
  }

  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.portafolioForm);
    this.portafolioService.actualizarPortafolio(this.portafolioId, this.token, jsonData)
      .subscribe((respuesta: PortafolioResponse) => {
        if (respuesta.success) {
          this.toastr.success('Portafolio actualizado correctamente', 'Actualizar portafolio');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../../../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.portafolioForm = this.fS.cambiarEstado(this.portafolioForm, this.editable, ['codigo'], true);
    this.editable = !this.editable;
  }
}
