import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Overlay} from '@angular/cdk/overlay';
import {GrillaActoresComponent} from './grilla-actores/grilla-actores.component';

@Component({
  selector: 'app-busqueda-actores',
  templateUrl: './busqueda-actores.component.html',
  styleUrls: ['./busqueda-actores.component.sass']
})
export class BusquedaActoresComponent implements OnInit {

  @Input() proProyectoId: number;
  @Input() proActorRolId: number;
  @Output() data: EventEmitter<any> = new EventEmitter();

  constructor(
    private matDialog: MatDialog,
    private overlay: Overlay,
  ) { }

  ngOnInit() {
  }

  buscar() {
    this.matDialog.open(GrillaActoresComponent, {
      width: '65vw',
      maxHeight: '70vh',
      autoFocus: false,
      data: { proProyectoId: this.proProyectoId, proActorRolId: this.proActorRolId },
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    }).afterClosed().subscribe((result: any) => {
      this.data.emit(result);
    });
  }
}
