import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusquedaPersonasComponent} from './busqueda-personas.component';
import {GrillaPersonasComponent} from './grilla-personas/grilla-personas.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {BotonFiltroModule} from '../boton-filtro/boton-filtro.module';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {MatTableModule} from '@angular/material/table';
import {DatosNoEncontradosModule} from '../datos-no-encontrados/datos-no-encontrados.module';
import {SpinnerModule} from '../spinner/spinner.module';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
  declarations: [
    BusquedaPersonasComponent,
    GrillaPersonasComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatSelectModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    BotonFiltroModule,
    NgbAlertModule,
    DatosNoEncontradosModule,
    SpinnerModule
  ],
  exports: [
    BusquedaPersonasComponent
  ],
  bootstrap: [
    GrillaPersonasComponent
  ]
})
export class BusquedaPersonasModule { }
