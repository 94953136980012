import {FormGroup, FormBuilder} from '@angular/forms';
import {ErrorService} from '../../../../../../../util/services/error.service';
import {RespuestaMonitoreoService} from '../../../services/respuesta-monitoreo.service';
import {Router, ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {RespuestaInstanciaActorResponse} from '../interfaces/tabla-respuesta-instancia-response';
import {RespuestaInstanciaActor} from '../interfaces/tabla-respuesta-instancia';
import {MatDialog} from '@angular/material/dialog';
import {EditarRespuestaActorComponent} from './editar-respuesta-actor/editar-respuesta-actor.component';

@Component({
  selector: 'app-respuesta-actor',
  templateUrl: './respuesta-actor.component.html',
  styleUrls: ['./respuesta-actor.component.sass']
})
export class RespuestaActorComponent implements OnInit {

  instanciaId: number;
  encuestaId: number;
  cargando: boolean;
  respuestaActor: RespuestaInstanciaActor;
  selected = null;
  searchRespuesta: FormGroup;
  displayedColumns = ['pregunta_codigo', 'pregunta_texto', 'respuesta'];

  constructor(private router: Router, private route: ActivatedRoute,
              private respuestaMonitoreoService: RespuestaMonitoreoService,
              private errorService: ErrorService,
              private fB: FormBuilder, private matDialog: MatDialog) {
  }

  ngOnInit() {
    this.encuestaId = +this.route.snapshot.paramMap.get('idEncuesta');
    this.instanciaId = +this.route.snapshot.paramMap.get('instanciaId');
    this.makeForm();
    this.getInstanciaActor();
  }

  makeForm() {
    this.searchRespuesta = this.fB.group({
      codigo: null,
      texto: null
    });
  }

  getInstanciaActor() {
    this.cargando = true;
    this.respuestaMonitoreoService.getInstanciaActor(this.encuestaId, this.instanciaId)
      .subscribe((respuesta: RespuestaInstanciaActorResponse) => {
        this.respuestaActor = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  volver() {
    this.router.navigate(['../../'], {relativeTo: this.route});
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  editarRespuesta(element) {
    console.log(element);
    this.matDialog.open(EditarRespuestaActorComponent, {
      width: '700px',
      data: {encuestaId: this.encuestaId, element}
    }).afterClosed().subscribe(response => {
      if (response) {
        this.getInstanciaActor();
      }
    });
  }
}
