import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../../../../../../../util/services/error.service';
import {RespuestaMonitoreoService} from '../../../services/respuesta-monitoreo.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-respuesta-odk-detalle',
  templateUrl: './respuesta-odk-detalle.component.html',
  styleUrls: ['./respuesta-odk-detalle.component.scss']
})
export class RespuestaOdkDetalleComponent implements OnInit {

  private proyectoId: number;
  private encuestaId: number;
  private odkId: string;
  instancia: any;
  cargando: boolean;
  cargandoRedireccion: boolean;

  constructor(private route: ActivatedRoute, private router: Router,
              private errorService: ErrorService, private respuestaMonitoreoService: RespuestaMonitoreoService,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.proyectoId = +this.route.snapshot.paramMap.get('idProyecto');
    this.encuestaId = +this.route.snapshot.paramMap.get('idEncuesta');
    this.odkId = this.route.snapshot.paramMap.get('odk-id');
    this.get();
  }

  get() {
    this.cargando = true;
    this.respuestaMonitoreoService.getEncuestaRespuestas(this.proyectoId, this.encuestaId, {id: this.odkId})
      .subscribe((respuesta: any) => {
        this.instancia = respuesta.data.value[0];
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  volver() {
    this.router.navigate(['../../'], {relativeTo: this.route});
  }

  editarRespuesta() {
    this.cargandoRedireccion = true;
    this.respuestaMonitoreoService.goToEditarRespuestaEnketo(this.proyectoId, this.encuestaId, this.odkId)
      .subscribe((response: any) => {
        if (response.data?.url) {
          window.open(response.data.url, '_blank');
        } else {
          this.toastr.error('No se ha podido encontrar una url de edición', 'Error');
        }
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
        this.cargandoRedireccion = false;
      });
  }
}
