import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatosNoEncontradosComponent } from './datos-no-encontrados.component';
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [DatosNoEncontradosComponent],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [DatosNoEncontradosComponent]
})
export class DatosNoEncontradosModule { }
