import { ErrorService } from './../services/error.service';
import { LoginService } from './../../Pages/Login/services/login.service';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Injectable } from '@angular/core';
import { FuncionalidadMap, ModulosMap, PermisosMap } from '../access-data/accessDataMap';
import { LocalStorageService } from '../local-storage.service';
import { interval } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionControllerService {

  permisosObj = new PermisosMap();
  modulosObj = new ModulosMap();
  funcionalidadObj = new FuncionalidadMap();
  private permisosAux = new PermisosMap();
  usuario;
  private misPermisosSup: any;


  constructor(private localStorageService: LocalStorageService,
    private permissionService: NgxPermissionsService,
    private loginService: LoginService,
    private errorService: ErrorService) {
  }

  /*
    @ Permission Controller: permite controlar si el usuario loggeado tiene disponible algún permiso dado.
  */

  // Ejemplo de uso: codigoModulo: ADM, codigoFuncionalidad: mant_funcionarios, codigoTipo: insert
  public tienePermiso(codigoModulo: string, codigoFuncionalidad: string, codigoTipo): boolean {
    const usuario = this.localStorageService.getUserFromStorage();
    if (usuario !== undefined) {

      usuario.userData.misPermisos.forEach(modulo => {
        if (modulo.codigo === codigoModulo) {
          modulo.funcionalidades.forEach(funcionalidad => {
            if (funcionalidad.codigo === codigoFuncionalidad) {
              funcionalidad.permisos.forEach(permiso => {
                if (permiso.codigo === codigoTipo) {
                  return true;
                }
              })
            }
          });
        }
      });
    }

    return false;
  }

  public cargarPermisos(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let misPermisos = [];
      this.loginService.refrescarPermisos().subscribe((response: any) => {
        if (response.success) {
          misPermisos = response.data;
        }
      }, error => {
        this.errorService.handleError(error);
        reject(false);
      }, ()=>{
        this.permissionService.loadPermissions(misPermisos);
        resolve(true);
      });
    });
  }

  crearPermisosAdm() {
    this.usuario = this.localStorageService.getUserFromStorage();
    // verificar si tiene parametrico
    // en funcionalidade sdel modulo general
    for (let i = 0; i < this.usuario.userData.misPermisos.length; i++) {
      const m = this.usuario.userData.misPermisos[i];
      if (this.modulosObj[m.codigo] === undefined) {
        this.modulosObj[m.codigo] = Array();
      }
    }
  }

  public clearController() {
    this.modulosObj = new ModulosMap();
    this.funcionalidadObj = new FuncionalidadMap();
    this.permisosAux = new PermisosMap();
  }

  private refrescarPermisos() {
    // 360.000 milisegundos  =  1 hora es decir, cada una hora realiza este llamado
    this.misPermisosSup = interval(360 * 1000)
      .pipe(flatMap(() => this.loginService.refrescarPermisos())
      ).subscribe((response: any) => {
        // save it
      }, error => { this.errorService.handleError(error); });
    // this.loginService.refrescarPermisos().subscribe((response: any) => {
    //   console.log(response);
    // });
  }

  public cancelarRefrescarPermisos() {
    this.misPermisosSup.unsubscribe();
  }
}
