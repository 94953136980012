import { MatPaginatorModule } from '@angular/material/paginator';
import { DatosNoEncontradosModule } from './../datos-no-encontrados/datos-no-encontrados.module';
import { SpinnerModule } from './../spinner/spinner.module';
import { MatTableModule } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcelErrorViewerComponent } from './excel-error-viewer.component';

@NgModule({
  declarations: [ExcelErrorViewerComponent],
  imports: [
    CommonModule,
    MatTableModule,
    SpinnerModule,
    DatosNoEncontradosModule,
    MatPaginatorModule
  ], 
  exports: [
    ExcelErrorViewerComponent
  ],
})
export class ExcelErrorViewerModule { }
