import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from '../../../../util/services/error.service';
import {LocalStorageService} from '../../../../util/local-storage.service';
import {CombosService} from '../../../../util/services/combos/combos.service';
import {DocumentoService} from '../services/documento.service';
import {Documento} from '../interfaces/documento.interface';
import {DocumentoResponse} from '../interfaces/documento-response.interface';
import {CombosResponse} from '../../../../util/interfaces/combos-response';
import {Combo} from '../../../../util/interfaces/combo';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Actor, Persona} from '../interfaces/actor.interface';
import {ActoresResponse} from '../interfaces/actores-response.interface';
import {FormStatusService} from '../../../../util/services/form-status/form-status.service';
import {Globals} from '../../../../Globals';

@Component({
  selector: 'app-detalle-documentacion-proyecto',
  templateUrl: './detalle-documentacion-proyecto.component.html',
  styleUrls: ['./detalle-documentacion-proyecto.component.sass']
})
export class DetalleDocumentacionProyectoComponent implements OnInit {
  file: any;
  @ViewChild('inputGroupFile', {static: true})
  labelImport: ElementRef;
  documento: Documento;
  documentoTipos: Combo[];
  documentoEstados: Combo[];
  actores: Actor[];
  cargando: boolean;
  public documentoForm: FormGroup;
  idProyecto: number;
  idDocumentoProyecto: number;
  editable = true;
  private token;

  constructor(
    private toastr: ToastrService,
    private documentosService: DocumentoService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private comboService: CombosService,
    private router: Router,
    private route: ActivatedRoute,
    private fS: FormStatusService,
    private globals: Globals) {
    this.token = this.localStorage.getTokenFromStorage();
    this.documentoForm = fB.group({
      pro_proyecto_documento_tipo_id: [{value: null, disabled: this.editable}, [Validators.required]],
      pro_actor_id: null,
      pro_documento_estado_id: [{value: null, disabled: this.editable}, [Validators.required]],
      nombre: [{value: null, disabled: this.editable}, [Validators.required]],
      fecha_entrega: [{value: null, disabled: this.editable}, [Validators.required]],
      fecha_vencimiento: [{value: null, disabled: this.editable}],
      url: null,
      descripcion: [{value: null, disabled: this.editable}],
      actor: [{value: null, disabled: this.editable}],
    });
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idDocumentoProyecto = +this.route.snapshot.paramMap.get('id');
    this.getDocumentoProyecto();
    this.getAutocompletes();
  }

  getDocumentoProyecto() {
    this.cargando = true;
    this.documentosService.getDocumento(this.token, this.idProyecto, this.idDocumentoProyecto)
      .subscribe((respuesta: DocumentoResponse) => {
          this.documento = respuesta.data;
          const actor = this.documento.actor_nombre + ' ' + this.documento.actor_apellido;
          this.documentoForm.get('actor').patchValue(actor);
          this.documentoForm.patchValue(this.documento);
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      this.cargando = false;
    });
  }

  private getDocumentoTiposCombo() {
    this.comboService.getProyectoDocumentoTipo(this.idProyecto)
      .subscribe((respuesta: CombosResponse) => {
        this.documentoTipos = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
    });
  }

  private getDocumentoEstadosCombo() {
    this.comboService.getDocumentoEstadosCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.documentoEstados = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
    });
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  getAutocompletes() {
    this.getDocumentoEstadosCombo();
    this.getDocumentoTiposCombo();
  }

  actualizarDocumento() {
    const jsonData = this.fS.getJsonFromForm(this.documentoForm);
    this.documentosService.editarDocumento(this.token, this.idProyecto, this.idDocumentoProyecto, jsonData)
      .subscribe((respuesta: DocumentoResponse) => {
          this.toastr.success('Documento editado exitosamente', 'Edición de Documento');
        },
        error => {
          this.toastr.error('Error al editar Documento', 'Edición de Documento', {toastClass: 'toastr-custom-error'});
        }, () => {
          this.getDocumentoProyecto();
          this.editableSwitcher();
        }).add(() => {
    });
  }

  fileChangeEvent(files): void {
    this.file = files[0];
    this.labelImport.nativeElement.innerText = files[0].name;
  }

  descargar() {
    window.open(`${this.globals.FILE_ENDPOINT}/storage/documentos/${this.documento.url}`, '_blank');
  }

  editableSwitcher() {
    this.documentoForm = this.fS.cambiarEstado(this.documentoForm, this.editable, ['actor'], true);
    this.editable = !this.editable;
  }

  onCloseBusqueda(data: any): void {
    if (data) {
      const actor = `${data.nombre} ${data.apellido}`;
      this.documentoForm.get('pro_actor_id').patchValue(data.pro_actor_id);
      this.documentoForm.get('actor').patchValue(actor);
    }
  }
}
