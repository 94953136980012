import {Router, ActivatedRoute} from '@angular/router';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from '../../../../util/services/error.service';
import {CombosService} from '../../../../util/services/combos/combos.service';
import {LocalStorageService} from '../../../../util/local-storage.service';
import {DocumentoService} from '../services/documento.service';
import {Observable} from 'rxjs';
import {CombosResponse} from '../../../../util/interfaces/combos-response';
import {ActoresResponse} from '../interfaces/actores-response.interface';
import {Combo} from '../../../../util/interfaces/combo';
import {Actor, Persona} from '../interfaces/actor.interface';
import {map, startWith} from 'rxjs/operators';
import {DocumentoResponse} from '../interfaces/documento-response.interface';

@Component({
  selector: 'app-crear-documentacion-proyecto',
  templateUrl: './crear-documentacion-proyecto.component.html',
  styleUrls: ['./crear-documentacion-proyecto.component.sass']
})
export class CrearDocumentacionProyectoComponent implements OnInit {
  file: any;
  @ViewChild('inputGroupFile', {static: true})
  labelImport: ElementRef;
  documentoTipos: any[];
  documentoEstados: Combo[];
  actores: Actor[];
  public documentoForm: FormGroup;
  documentoTipoControl = new FormControl();
  filteredOptionsTipoControl: Observable<object[]>;
  documentoEstadoControl = new FormControl();
  filteredOptionsEstadoControl: Observable<object[]>;
  actorControl = new FormControl();
  filteredActorControl: Observable<object[]>;
  idProyecto: number;
  private token;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private documentosService: DocumentoService,
    private toastr: ToastrService,
    private fB: FormBuilder,
    private errorService: ErrorService,
    private comboService: CombosService,
    private localStorage: LocalStorageService
  ) {
    this.token = this.localStorage.getTokenFromStorage();
    this.documentoForm = fB.group({
      pro_proyecto_documento_tipo_id: [null, [Validators.required]],
      pro_actor_id: null,
      pro_documento_estado_id: [null, [Validators.required]],
      nombre: [null, [Validators.required]],
      fecha_entrega: [null, [Validators.required]],
      fecha_vencimiento: null,
      url: null,
      descripcion: null,
      actor: [{value: null, disabled: true}],
    });
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.getAutocompletes();
  }


  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  private getDocumentoTiposCombo() {
    this.comboService.getProyectoDocumentoTipo(this.idProyecto)
      .subscribe((respuesta: CombosResponse) => {
        this.documentoTipos = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
    });
  }

  private getDocumentoEstadosCombo() {
    this.comboService.getDocumentoEstadosCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.documentoEstados = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
    });
  }

  private getActoresCombo() {
    this.comboService.getProyectoActoresCombo(this.token, this.idProyecto)
      .subscribe((respuesta: ActoresResponse) => {
        this.actores = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
    });
  }

  getAutocompletes() {
    this.getActoresCombo();
    this.getDocumentoEstadosCombo();
    this.getDocumentoTiposCombo();
  }

  volver() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  fileChangeEvent(files): void {
    this.file = files[0];
    this.labelImport.nativeElement.innerText = files[0].name;
  }

  crearDocumento() {
    const formData = new FormData();
    if (this.file) {
      formData.append('url', this.file, this.file.name);
    }
    formData.append('pro_proyecto_documento_tipo_id', this.documentoForm.value.pro_proyecto_documento_tipo_id);
    formData.append('pro_actor_id', this.documentoForm.value.pro_actor_id);
    formData.append('pro_documento_estado_id', this.documentoForm.value.pro_documento_estado_id);
    formData.append('nombre', this.documentoForm.value.nombre);
    formData.append('fecha_entrega', this.documentoForm.value.fecha_entrega);
    formData.append('fecha_vencimiento', this.documentoForm.value.fecha_vencimiento);
    formData.append('descripcion', this.documentoForm.value.descripcion);
    this.documentosService.crearDocumento(this.token, this.idProyecto, formData)
      .subscribe((respuesta: DocumentoResponse) => {
          this.toastr.success('Documento creado exitosamente', 'Creación de Documento');
          this.volver();
        },
        error => {
          this.toastr.error('Error al crear Documento', 'Creación de Documento', {toastClass: 'toastr-custom-error'});
        }).add(() => {
    });
  }

  onCloseBusqueda(data: any): void {
    if (data) {
      const actor = `${data.nombre} ${data.apellido} - ${data.documento}`;
      this.documentoForm.get('pro_actor_id').patchValue(data.pro_actor_id);
      this.documentoForm.get('actor').patchValue(actor);
    }
  }
}
