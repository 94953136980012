import { InversorTipo } from './../interfaces/inversor-tipo';
import { InversoresTiposService } from './../services/inversores-tipos.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalle-inversores-tipos',
  templateUrl: './detalle-inversores-tipos.component.html',
  styleUrls: ['./detalle-inversores-tipos.component.sass']
})
export class DetalleInversoresTiposComponent implements OnInit {

  public inversorTipoForm: FormGroup;
  editable = true;
  cargando: boolean;
  inversorTipoId: number;
  inversorTipo: InversorTipo;
  process: boolean;

  constructor(  private fB: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private fS: FormStatusService,
                private inversoresTiposService: InversoresTiposService,
                private errorService: ErrorService,
                private toastr: ToastrService) { }

  ngOnInit() {
    this.inversorTipoId = +this.route.snapshot.paramMap.get('id');
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.inversorTipoForm = this.fB.group({
      nombre: [{ value: '', disabled: this.editable }, Validators.required],
      codigo: [{ value: '', disabled: this.editable }],
      descripcion: [{ value: '', disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.inversoresTiposService.obtenerInversorTipo(this.inversorTipoId)
      .subscribe((respuesta: any) => {
        this.inversorTipo = respuesta.data;
        this.inversorTipoForm.patchValue(this.inversorTipo);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.inversorTipoForm);
    this.inversoresTiposService.actualizarInversorTipo(this.inversorTipoId, jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Inversor Tipo actualizado correctamente', 'Actualizar Inversor');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.inversorTipoForm = this.fS.cambiarEstado(this.inversorTipoForm, this.editable, ['codigo'], true);
    this.editable = !this.editable;
  }


}
