import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args === '') {
      return value;
    }
    const result = [];
    for (const item of value) {
      if (item.nombre.toLowerCase().indexOf(args.toLowerCase()) > -1) {
        result.push(item);
      }
    }
    return result;
  }

}
