import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ConfigProyectoGestionService {

  
  constructor(private http: HttpClient, private global: Globals) { }

  getProyectosGestion(token, proyectoId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/proyectos/' + proyectoId + '/proyectosGestion', search, pagination);
    return this.http.get(url, { headers });
  }
  getProyectoGestion(proyectoId, configuracionId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosGestion/' + configuracionId;
    return this.http.get(url, { headers });
  }
  actualizarProyectoGestion(proyectoId, configuracionId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosGestion/' + configuracionId;
    return this.http.put(url, json, { headers });
  }
  crearProyectoGestion(token, proyectoId, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosGestion';
    return this.http.post(url, json, { headers });
  }
  borrarProyectoGestion(proyectoId, configuracionId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosGestion/' + configuracionId;
    return this.http.delete(url, { headers });
  }
}
