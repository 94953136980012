import { PreguntaResponse } from './../interfaces/pregunta-response';
import { PoolPreguntasService } from './../services/pool-preguntas.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo, ComboIndicadores } from 'src/app/util/interfaces/combo';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { CombosResponse, ComboIndicadoresResponse } from 'src/app/util/interfaces/combos-response';
import { Pregunta } from '../interfaces/pregunta';

@Component({
  selector: 'app-pool-detalle-pregunta',
  templateUrl: './pool-detalle-pregunta.component.html',
  styleUrls: ['./pool-detalle-pregunta.component.sass']
})
export class PoolDetallePreguntaComponent implements OnInit {

  preguntaId: number;
  cargando: boolean;
  pregunta: Pregunta;
  disabled = true;
  public editarPregunta: FormGroup;
  process: boolean;
  tiposPregunta: Combo[];
  indicadores: ComboIndicadores[];
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private poolPreguntaService: PoolPreguntasService,
    private fS: FormStatusService, private toastr: ToastrService, private errorService: ErrorService, private comboService: CombosService) { }

  ngOnInit() {
    this.preguntaId = +this.route.snapshot.paramMap.get('id');
    this.getPregunta();
    this.makeForm();
    this.getIndicadores();
  }

  getPregunta() {
    this.cargando = true;
    this.poolPreguntaService.obtenerPregunta(this.preguntaId).subscribe((respuesta: PreguntaResponse) => {
        this.pregunta = respuesta.data;
        this.editarPregunta.get('pro_indicador_id').setValue(respuesta.data.indicador_id);
        this.editarPregunta.patchValue(this.pregunta);
    }, error => {
      this.errorService.handleError(error);
    }, () => { this.cargando = false; });
  }

  makeForm() {
    this.editarPregunta = this.fB.group({
      enc_pregunta_tipo_id: [{ value: undefined, disabled: this.disabled }, [Validators.required]],
      codigo: [{ value: undefined, disabled: this.disabled }, [Validators.required]],
      texto: [{ value: undefined, disabled: this.disabled }, [Validators.required]],
      pro_indicador_id: [{ value: undefined, disabled: this.disabled }]
    });
  }

  editar() {
    this.editarPregunta = this.fS.cambiarEstado(this.editarPregunta, this.disabled);
    this.disabled = !this.disabled;
  }

  getTiposPregunta() {
    this.comboService.getTiposPregunta().subscribe((respuesta: CombosResponse) => {
      this.tiposPregunta = respuesta.data;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  parseValue(selected) {
    this.editarPregunta.get('enc_pregunta_tipo_id').setValue(+selected);
  }

  getIndicadores() {
    this.comboService.getIndicadoresCombo().subscribe((respuesta: ComboIndicadoresResponse) => {
      this.indicadores = respuesta.data;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  actualizarPregunta() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.editarPregunta);
    this.poolPreguntaService.editarPregunta(this.preguntaId, jsonData)
      .subscribe((respuesta: PreguntaResponse) => {
        if (respuesta.success) {
          this.toastr.success('Pregunta editada exitosamente', 'Edición de Pregunta');
          this.editar();
          this.getPregunta();
        } else {
          this.toastr.error('Error al editar pregunta', 'Edición de Pregunta', { toastClass: 'toastr-custom-error' });
        }
      }, error => {
        this.errorService.handleError(error);
      }).add(() => { this.process = false; });
  }

}
