import { DetalleSubgrupoMiembroComponent } from './detalle-subgrupo-miembro/detalle-subgrupo-miembro.component';
import { CrearSubgrupoMiembroComponent } from './crear-subgrupo-miembro/crear-subgrupo-miembro.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { SubgrupoInstanciaService } from './services/subgrupo-instancia.service';
import { Component, Input, OnInit } from '@angular/core';
import { SubgruposInstanciaResponse, SubgrupoInstanciaResponse } from './interfaces/subgrupo-instancia-response';
import { SubgrupoInstancia } from './interfaces/subgrupo-instancia';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-subgrupo-instancia',
  templateUrl: './subgrupo-instancia.component.html',
  styleUrls: ['./subgrupo-instancia.component.sass']
})
export class SubgrupoInstanciaComponent implements OnInit {

  @Input() tallerId: number;
  @Input() instanciaId: number;
  miembros: SubgrupoInstancia[];
  public pagination = { page: 1, per_page: 10 };
  public total: number;
  searchForm: FormGroup;
  selected: any;
  cargando: boolean;
  displayedColumns = ['nombre', 'presente', 'comentario', 'acciones'];
  constructor(private subgrupoService: SubgrupoInstanciaService, private errorService: ErrorService,
    private toastr: ToastrService, private fB: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.searchForm = this.fB.group({
      presente: ''
    });
  }

  get() {
    this.cargando = true;
    this.subgrupoService.getSubgrupos(this.tallerId, this.instanciaId, this.searchForm.value, this.pagination)
      .subscribe((response: SubgruposInstanciaResponse) => {
        if (response.success) { }
        this.miembros = response.data;
        this.total = response.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  borrar(id){
    this.subgrupoService.borrarSubgrupoMiembro(this.tallerId.toExponential, this.instanciaId, id)
    .subscribe((response: SubgrupoInstanciaResponse) => {
      if(response.success){
        this.toastr.success('Miembro borrado exitosamente.', 'Borrar Miembro');
      }
    }, error => {
      this.errorService.handleError(error);
    }, ()=> {
      this.get();
    });
  }

  pageChange() {
    this.get();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.get();
  }

  filtrar() {
    this.pagination.page = 1;
    this.get();
  }

  crear(){
    const dialogRef = this.dialog.open(CrearSubgrupoMiembroComponent, {
      width: '700px',
      data: { tallerId: this.tallerId, instanciaId: this.instanciaId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.get();
      }
    });
  }

  verDetalle(id){
    const dialogRef = this.dialog.open(DetalleSubgrupoMiembroComponent, {
      width: '700px',
      data: { tallerId: this.tallerId, instanciaId: this.instanciaId, miembroId: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.get();
      }
    });
  }

}
