import {Component, OnInit} from '@angular/core';
import {Proyecto} from '../../interfaces/proyecto';

@Component({
  selector: 'app-config-encuesta-proyecto',
  templateUrl: './config-encuesta-proyecto.component.html',
  styleUrls: ['./config-encuesta-proyecto.component.scss']
})
export class ConfigEncuestaProyectoComponent implements OnInit {
  proyecto: Proyecto;
  proyectoLoaded: Promise<boolean>;

  constructor() {
  }

  ngOnInit() {
  }

  setProyecto(event) {
    this.proyecto = event;
    this.proyectoLoaded = Promise.resolve(true);
  }

}
