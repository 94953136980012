import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesProyectoService {

  constructor(private http: HttpClient, private global: Globals) { }

  getConfiguraciones(token, proyectoId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/proyectos/' + proyectoId + '/configuraciones', search, pagination);
    return this.http.get(url, { headers });
  }
  getConfiguracion(proyectoId, configuracionId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/configuraciones/' + configuracionId;
    return this.http.get(url, { headers });
  }
  actualizarConfiguracion(proyectoId, configuracionId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/configuraciones/' + configuracionId;
    return this.http.put(url, json, { headers });
  }
  crearConfiguracion(token, proyectoId, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/configuraciones';
    return this.http.post(url, json, { headers });
  }
  borrarConfiguracion(proyectoId, configuracionId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/configuraciones/' + configuracionId;
    return this.http.delete(url, { headers });
  }
}
