import { ComboIndicadoresResponse } from './../../../../util/interfaces/combos-response';
import { ComboIndicadores } from './../../../../util/interfaces/combo';
import { OpcionesPreguntaService } from './../pool-detalle-pregunta/opciones-pregunta/services/opciones-pregunta.service';
import { OpcionPreguntaResponse, OpcionesPreguntaResponse } from './../pool-detalle-pregunta/opciones-pregunta/interfaces/opcion-pregunta-response';
import { OpcionesPredeterminadasService } from './../../preguntas-tipos/detalle-pregunta-tipo/crud-opciones-predeterminadas/services/opciones-predeterminadas.service';
import { PoolPreguntasService } from './../services/pool-preguntas.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo } from 'src/app/util/interfaces/combo';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';
import { PreguntaResponse } from '../interfaces/pregunta-response';
import { OpcionPregunta } from '../pool-detalle-pregunta/opciones-pregunta/interfaces/opcion-pregunta';

@Component({
  selector: 'app-pool-crear-pregunta',
  templateUrl: './pool-crear-pregunta.component.html',
  styleUrls: ['./pool-crear-pregunta.component.sass']
})
export class PoolCrearPreguntaComponent implements OnInit {
  public nuevaPregunta: FormGroup;
  process: boolean;
  idObtenido: number;
  tiposPregunta: Combo[];
  opciones: OpcionPregunta[];
  indicadores: ComboIndicadores[];
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private localStorage: LocalStorageService,
    private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private comboService: CombosService,
    private poolPreguntaService: PoolPreguntasService,
    private opcionesPredeterminadasService: OpcionesPredeterminadasService,
    private opcionesPreguntaService: OpcionesPreguntaService) { }

  ngOnInit() {
    this.makeForm();
    this.getTiposPregunta();
    this.getIndicadores();
  }

  makeForm() {
    this.nuevaPregunta = this.fB.group({
      enc_pregunta_tipo_id: [null, Validators.required],
      codigo: [null, Validators.required],
      texto: [null, Validators.required],
      pro_indicador_id: null
    });
  }

  crear() {
    const jsonData = this.fS.getJsonFromForm(this.nuevaPregunta);
    this.poolPreguntaService.crearPregunta(jsonData).subscribe((respuesta: PreguntaResponse) => {
      if (respuesta.success) {
        this.toastr.success('Pregunta creada exitosamente', 'Creación de Pregunta');
        this.idObtenido = respuesta.data.id;
        //        this.volver();
      }
    }, error => {
      this.errorService.handleError(error);
    }, () => {
      this.establecerOpcionesPredeterminadas();
    });
  }

  getTiposPregunta() {
    this.comboService.getTiposPregunta().subscribe((respuesta: CombosResponse) => {
      this.tiposPregunta = respuesta.data;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  getIndicadores() {
    this.comboService.getIndicadoresCombo().subscribe((respuesta: ComboIndicadoresResponse) => {
      this.indicadores = respuesta.data;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  parseValue(selected) {
    if (selected) {
      this.nuevaPregunta.get('enc_pregunta_tipo_id').setValue(+selected);
      this.opcionesPredeterminadasService.obtenerOpcionesPredeterminadas(selected).subscribe((respuesta: OpcionesPreguntaResponse) => {
        if (respuesta.metadata.total > 0) {
          this.opciones = respuesta.data;
        }
      }, error => {
        this.errorService.handleError(error);
      });
    }
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  establecerOpcionesPredeterminadas() {
    if (this.opciones) {
      this.opciones.forEach(opcion => {
        var opcionToJSON = {
          codigo: opcion.codigo,
          enc_pregunta_id: this.idObtenido,
          porcentaje_opcion: opcion.porcentaje_opcion,
          texto: opcion.texto
        };
        this.opcionesPreguntaService.crearOpcion(this.idObtenido, opcionToJSON)
          .subscribe((repsuesta: OpcionPreguntaResponse) => {

          }, error => {
            this.errorService.handleError(error);
          });
      });
      this.volver();
    } else {
      this.volver();
    }
  }
}
