import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../../Globals';

@Injectable({
  providedIn: 'root'
})
export class TallerParticipanteService {

  constructor(
    private http: HttpClient,
    private global: Globals) {
  }

  getTalleresParticipantes(search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url: string = this.global.makeUrl('/talleresParticipantes', search, pagination);
    return this.http.get(url, {headers});
  }
  getTallerParticipante(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/talleresParticipantes/' + id;
    return this.http.get(url, {headers});
  }
  crearTallerParticipante(json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/talleresParticipantes';
    return this.http.post(url, json, { headers });
  }
  actualizarTallerParticipante(id, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/talleresParticipantes/' + id;
    return this.http.put(url, json, { headers });
  }
  borrarTallerParticipante(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/talleresParticipantes/' + id;
    return this.http.delete(url, { headers });
  }
}
