import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class DimensionService {

  constructor(private http: HttpClient, private global: Globals) { }

  getDimensiones(token, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/dimensiones', search, pagination);
    return this.http.get(url, { headers });
  }
  getDimension(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/dimensiones/' + id;
    return this.http.get(url, { headers });
  }
  actualizarDimension(id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/dimensiones/' + id;
    return this.http.put(url, json, { headers });
  }
  crearDimension(token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/dimensiones';
    return this.http.post(url, json, { headers });
  }
  borrarDimension(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/dimensiones/' + id;
    return this.http.delete(url, { headers });
  }
}
