import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CrudUsuariosMovilesService} from '../../crud-usuarios-moviles/services/crud-usuarios-moviles.service';
import {CrudAccessoEncuestaService} from '../services/crud-accesso-encuesta.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from '../../../../../../util/services/error.service';
import {FormStatusService} from '../../../../../../util/services/form-status/form-status.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RespuestaMonitoreoService} from '../../../respuesta-monitoreo-proyecto/services/respuesta-monitoreo.service';

@Component({
  selector: 'app-crear-acceso-encuesta',
  templateUrl: './crear-acceso-encuesta.component.html',
  styleUrls: ['./crear-acceso-encuesta.component.scss']
})
export class CrearAccesoEncuestaComponent implements OnInit {

  usuariosMoviles: any[];
  encuestas: any[];
  accesoForm: FormGroup;
  process: boolean;

  constructor(private matDialogRef: MatDialogRef<CrearAccesoEncuestaComponent>,
              @Inject(MAT_DIALOG_DATA) private proyectoId: number,
              private crudUsuariosMovilesService: CrudUsuariosMovilesService,
              private crudAccessoEncuestaService: CrudAccessoEncuestaService,
              private toastr: ToastrService,
              private errorService: ErrorService,
              private fS: FormStatusService,
              private fB: FormBuilder,
              private respuestaMonitoreoService: RespuestaMonitoreoService) {
  }

  ngOnInit() {
    this.makeForm();
    this.getUsuariosMoviles();
  }

  makeForm() {
    this.accesoForm = this.fB.group({
      actorId: ['', Validators.required],
      xmlFormId: ['', Validators.required]
    });
  }

  getUsuariosMoviles() {
    this.crudUsuariosMovilesService.getList(this.proyectoId, null, {page: 'disable'})
      .subscribe((response: any) => {
        if (response.success) {
          this.usuariosMoviles = response.data;
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.getEncuestas();
      });
  }

  getEncuestas() {
    this.respuestaMonitoreoService.getEncuestas(this.proyectoId, null, {page: 'disable'})
      .subscribe((response: any) => {
        if (response.success) {
          this.encuestas = response.data;
        }
      }, error => {
        this.errorService.handleError(error);
      });
  }

  onNoClick() {
    this.matDialogRef.close(false);
  }

  create() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.accesoForm);
    this.crudAccessoEncuestaService.create(this.proyectoId, jsonData)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('Se ha otorgado el acceso al usuario correctamente.', 'Crear Acceso');
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.matDialogRef.close(true);
      }).add(() => {
      this.process = false;
    });
  }


}
