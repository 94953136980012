import { Globals } from './../../../../Globals';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PoolPreguntasService {

  constructor(private http: HttpClient, private global: Globals) { }

  obtenerPreguntas(search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/preguntas', search, pagination);
    return this.http.get(url, { headers });
  }

  obtenerPregunta(preguntaId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntas/' + preguntaId;
    return this.http.get(url, { headers });
  }

  crearPregunta(jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntas';
    return this.http.post(url, jsonData, { headers });
  }

  borrarPregunta(preguntaId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntas/' + preguntaId;
    return this.http.delete(url, { headers });
  }

  editarPregunta(preguntaId, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntas/' + preguntaId;
    return this.http.put(url, jsonData, { headers });
  }
}
