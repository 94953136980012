import {ComboPaisesResponse} from './../../../util/interfaces/combos-response';
import {ProyectoResponse} from './../interfaces/proyecto-response';
import {Router, ActivatedRoute} from '@angular/router';
import {Component, HostListener, OnInit} from '@angular/core';
import {LocalStorageService} from 'src/app/util/local-storage.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormStatusService} from 'src/app/util/services/form-status/form-status.service';
import {ProyectosService} from '../services/proyectos.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from 'src/app/util/services/error.service';
import {PaisesProyectoService} from '../pais-proyecto/services/paises-proyecto.service';
import {CombosService} from 'src/app/util/services/combos/combos.service';
import {DatePipe} from '@angular/common';
import {CombosResponse} from 'src/app/util/interfaces/combos-response';
import {Combo, ComboPais} from 'src/app/util/interfaces/combo';
import {Proyecto} from '../interfaces/proyecto';

@Component({
  selector: 'app-editar-proyecto',
  templateUrl: './editar-proyecto.component.html',
  styleUrls: ['./editar-proyecto.component.sass'],
  providers: [DatePipe]
})
export class EditarProyectoComponent implements OnInit {

  public ocultarConfig = true;
  proyectoId: number;
  cargando: boolean;
  process: boolean;
  private token;
  editarProyectoForm: FormGroup;
  proyecto: Proyecto;
  paises: ComboPais[];
  proyectoEstados: Combo[];
  programas: Combo[];
  editable = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private fS: FormStatusService,
    private proyectoService: ProyectosService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private paisProyectoService: PaisesProyectoService,
    private comboService: CombosService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    this.proyectoId = +this.route.snapshot.paramMap.get('idProyecto');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.getPaises();
  }

  makeForm() {
    this.editarProyectoForm = this.fB.group({
      pro_programa_id: [{value: undefined, disabled: this.editable}, Validators.required],
      adm_pais_id: [{value: undefined, disabled: this.editable}, Validators.required],
      pro_proyecto_estado_id: [{value: null, disabled: this.editable}, Validators.required],
      moneda: {value: undefined, disabled: this.editable},
      nombre: [{value: undefined, disabled: this.editable}, Validators.required],
      codigo: [{value: undefined, disabled: this.editable}, Validators.required],
      fecha_inicio: [{value: undefined, disabled: this.editable}, Validators.required],
      fecha_fin: [{value: undefined, disabled: this.editable}, Validators.required]
    });
  }

  getProyecto() {
    this.proyectoService.getProyecto(this.proyectoId, this.token)
      .subscribe((respuesta: ProyectoResponse) => {
        this.proyecto = respuesta.data;
        this.editarProyectoForm.patchValue(this.proyecto);
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.obtenerMoneda();
      }).add(() => {
      this.cargando = false;
    });
  }

  getPaises() {
    this.cargando = true;
    this.comboService.getPaisesCombo()
      .subscribe((respuesta: ComboPaisesResponse) => {
        this.paises = respuesta.data;
        // this.editarProyectoForm.get('moneda').setValue()
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.getProyectoEstados();
      });
  }

  obtenerMoneda() {
    const moneda = this.paises.find(pais => pais.id === this.proyecto.adm_pais_id);
    this.editarProyectoForm.get('moneda').setValue(moneda.nombre_moneda);
  }

  getProyectoEstados() {
    this.comboService.getProyectoEstados()
      .subscribe((respuesta: CombosResponse) => {
        this.proyectoEstados = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.getProgramas();
      });
  }

  getProgramas() {
    this.comboService.getProgramas()
      .subscribe((respuesta: CombosResponse) => {
        this.programas = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.getProyecto();
      });
  }

  agregarProyecto() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  cambiarEstadoConfig() {
    this.ocultarConfig = !this.ocultarConfig;
  }

  guardarDato() {
    sessionStorage.setItem('idPais', this.editarProyectoForm.controls.adm_pais_id.value);
  }

  volver($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }
    this.guardarDato();
    this.router.navigate(['../../'], {relativeTo: this.route});
  }

  public editableSwitcher() {
    this.editarProyectoForm = this.fS.cambiarEstado(this.editarProyectoForm, this.editable, ['moneda'], true);
    this.editable = !this.editable;
  }

  actualizar() {
    this.process = true;
    this.editarProyectoForm.controls.fecha_inicio.setValue(this.datePipe.transform(this.editarProyectoForm.controls.fecha_inicio.value, 'yyyy-MM-dd'));
    this.editarProyectoForm.controls.fecha_fin.setValue(this.datePipe.transform(this.editarProyectoForm.controls.fecha_fin.value, 'yyyy-MM-dd'));
    const jsonData = this.fS.getJsonFromForm(this.editarProyectoForm);
    this.proyectoService.actualizarProyecto(this.proyectoId, this.token, jsonData)
      .subscribe((respuesta: ProyectoResponse) => {
          if (respuesta.success) {
            this.toastr.success('Proyecto actualizado correctamente', 'Actualizar Proyecto');
            this.getProyecto();
            this.editableSwitcher();
          }
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      this.process = false;
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.volver();
  }

  fileChangeEvent(files, tipo) {
    const fileName: string = files[0].name;
    const arr = fileName.match('^.*\\.(jpg|JPG|gif|GIF|png|jpeg)$');
    if (arr) {
      const formData = new FormData();
      formData.append('path', files[0]);
      formData.append('tipo', tipo);
      this.proyectoService.cambiarProyectoImagenes(this.proyectoId, formData)
        .subscribe((response: ProyectoResponse) => {
          if (response.success) {
            this.toastr.success('Imagen cargada correctamente', 'Actualización de Imagen');
          }
        }, error => {
          this.errorService.handleError(error);
        });
    } else {
      this.toastr.warning('Sólo se aceptan archivos de tipo jpg | JPG | gif | GIF | png | jpeg', 'Actualización de Imagen');
    }
  }
}
