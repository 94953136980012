import { ConfiguracionParaproResponse } from './../interfaces/configuracion-parapro-response';
import { ConfiguracionesParaproService } from './../services/configuraciones-parapro.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-crear-configuracion-parapro',
  templateUrl: './crear-configuracion-parapro.component.html',
  styleUrls: ['./crear-configuracion-parapro.component.sass']
})
export class CrearConfiguracionParaproComponent implements OnInit {

  public nuevaConfiguracion: FormGroup;
  private token;
  process: boolean;
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private configuracionParaProService: ConfiguracionesParaproService, private localStorage: LocalStorageService,
    private fS: FormStatusService, private toastr: ToastrService, private errorService: ErrorService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
  }

  makeForm() {
    this.nuevaConfiguracion = this.fB.group({
      nombre: [undefined, Validators.required],
      tipo: [undefined, Validators.required],
      valor: [undefined, Validators.required],
      descripcion: [undefined]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevaConfiguracion);
    this.configuracionParaProService.crearConfiguracion(this.token, jsonData)
      .subscribe((respuesta: ConfiguracionParaproResponse) => {
        if (respuesta.success) {
          this.toastr.success('Configuración creada exitosamente', 'Crear configuración');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevaConfiguracion.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
