import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../../../../../util/services/error.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../../../../util/local-storage.service';
import {TallerTipoService} from '../../../../parametricos-proyecto/talleres-tipos/services/taller-tipo.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {
  TalleresTiposResponse,
  TallerTipoResponse
} from '../../../../parametricos-proyecto/talleres-tipos/interfaces/taller-tipo-response';
import {HttpErrorResponse} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {CrearUsuarioMovilComponent} from './crear-usuario-movil/crear-usuario-movil.component';
import {CrudUsuariosMovilesService} from './services/crud-usuarios-moviles.service';

@Component({
  selector: 'app-crud-usuarios-moviles',
  templateUrl: './crud-usuarios-moviles.component.html',
  styleUrls: ['./crud-usuarios-moviles.component.scss']
})
export class CrudUsuariosMovilesComponent implements OnInit {

  cargando: boolean;
  total = 1;
  pagination = {page: 1, per_page: 10};
  selected = null;
  displayedColumns = ['nombre', 'descripcion', 'acciones'];
  usuariosMoviles: any[];
  searchForm: FormGroup;
  @Input() proyectoId: number;

  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder, private errorService: ErrorService,
              private toastr: ToastrService, private localStorage: LocalStorageService,
              private permissionService: NgxPermissionsService, private crudUsuariosMovilesService: CrudUsuariosMovilesService,
              private matDialog: MatDialog) {
  }

  ngOnInit() {
    this.makeSearchForm();
    this.get();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  get() {
    this.cargando = true;
    this.crudUsuariosMovilesService.getList(this.proyectoId, this.searchForm.value, this.pagination)
      .subscribe((respuesta: any) => {
          this.usuariosMoviles = respuesta.data;
          this.total = respuesta.metadata.total;
        }, error => {
          this.errorService.handleError(error);
        }
        , () => {
          this.cargando = false;
        });
  }

  crear() {
    this.router.navigate(['./new'], {relativeTo: this.route});
  }

  borrar(id: number) {
    this.crudUsuariosMovilesService.delete(this.proyectoId, id)
      .subscribe((respuesta: TallerTipoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Usuario móvil borrado exitosamente', 'Borrar Usuario Móvil');
        }
        this.get();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => {
      });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.get();
  }

  filtrar() {
    this.pagination.page = 1;
    this.get();
  }

  pageChange() {
    this.get();
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], {relativeTo: this.route});
  }

  crearUserMovil() {
    this.matDialog.open(CrearUsuarioMovilComponent, {
      width: '600px',
      data: this.proyectoId
    }).afterClosed().subscribe(result => {
      if (result) {
        this.get();
      }
    });
  }

  copiarAlPortapapeles(url) {
    // Crea un campo de texto "oculto"
    const aux = document.createElement('input');

    // Asigna el contenido del elemento especificado al valor del campo
    aux.setAttribute('value', url);

    // Añade el campo a la página
    document.body.appendChild(aux);

    // Selecciona el contenido del campo
    aux.select();

    // Copia el texto seleccionado
    document.execCommand('copy');

    // Elimina el campo de la página
    document.body.removeChild(aux);

    this.toastr.success('URL de CLIENTE copiado exitosamente.', 'Copiar al portapapeles');
  }
}
