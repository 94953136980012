import { ConfigProyectoMonitoreosResponse, ConfigProyectoMonitoreoResponse } from './interfaces/config-proyecto-monitoreo-response';
import { ConfigProyectoMonitoreoService } from './services/config-proyecto-monitoreo.service';
import { CrearProyectoMonitoreoComponent } from './crear-proyecto-monitoreo/crear-proyecto-monitoreo.component';
import { DetalleProyectoMonitoreoComponent } from './detalle-proyecto-monitoreo/detalle-proyecto-monitoreo.component';
import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigProyectoMonitoreo } from './interfaces/config-proyecto-monitoreo';
import { ComboComponentes } from 'src/app/util/interfaces/combo';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ComboComponentesResponse } from 'src/app/util/interfaces/combos-response';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';

@Component({
  selector: 'app-config-proyecto-monitoreo',
  templateUrl: './config-proyecto-monitoreo.component.html',
  styleUrls: ['./config-proyecto-monitoreo.component.sass']
})
export class ConfigProyectoMonitoreoComponent implements OnInit {

  @Input() proyectoId: number;
  cargando: boolean;
  private token;
  componentesMonitoreo: ConfigProyectoMonitoreo[];
  displayedColumns = ['nombre', 'codigo', 'acciones'];
  componentesMonitoreoCombo: ComboComponentes[];

  // Creación & Edición de componente
  public proyectoMonitoreoForm: FormGroup;
  process: boolean;


  constructor(
    private localStorage: LocalStorageService,
    private configProyectoMonitoreoService: ConfigProyectoMonitoreoService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    public dialog: MatDialog,
    private fB: FormBuilder,
    private fS: FormStatusService,
    private comboService: CombosService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.getProyectoMonitoreos();
    this.makeForm();
  }

  makeForm(){
    this.proyectoMonitoreoForm = this.fB.group({
      pro_componente_monitoreo_id: [null, Validators.required],
    });
  }

  getProyectoMonitoreos() {
    this.cargando = true;
    this.configProyectoMonitoreoService.getProyectoMonitoreos(this.token, this.proyectoId)
      .subscribe((respuesta: ConfigProyectoMonitoreosResponse) => {
        this.componentesMonitoreo = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => { this.getComponentesMonitoreo(); });
  }

  crear(value: ComboComponentes) {
    console.log(value);
    this.process = true;
    this.proyectoMonitoreoForm.get('pro_componente_monitoreo_id').setValue(value.id);
    const jsonData = this.fS.getJsonFromForm(this.proyectoMonitoreoForm);
    this.configProyectoMonitoreoService.crearProyectoMonitoreo(this.token, this.proyectoId, jsonData)
      .subscribe((respuesta: ConfigProyectoMonitoreoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto Gestión creado exitosamente', 'Crear Proyecto Gestión');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.proyectoMonitoreoForm.enable();
      }).add(() => { this.process = false; });
  }

  borrar(id: number) {
    this.configProyectoMonitoreoService.borrarProyectoMonitoreo(this.proyectoId, id, this.token)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Componente de Monitoreo borrado exitosamente', 'Borrar Componente de Monitoreo');
        }
        this.getProyectoMonitoreos();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  getComponentesMonitoreo() {
    this.comboService.getComponentesMonitoreoCombo(this.token)
      .subscribe((respuesta: ComboComponentesResponse) => {
        this.componentesMonitoreoCombo = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.verificarActivos();
      });
  }

  verificarActivos() {
    this.componentesMonitoreoCombo.forEach(componenteMonitoreoCombo => {
      this.componentesMonitoreo.forEach(componenteMonitoreoActivo => {
        if (componenteMonitoreoCombo.id === componenteMonitoreoActivo.pro_componente_monitoreo_id) {
          componenteMonitoreoCombo.activo = true;
          componenteMonitoreoCombo.relacion_id = componenteMonitoreoActivo.id;
        }
      });
    });
    this.cargando = false;
  }

}
