import { InversoresService } from './../services/inversores.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { Inversor } from '../interfaces/inversor';
import { Combo } from 'src/app/util/interfaces/combo';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';

@Component({
  selector: 'app-detalle-inversor',
  templateUrl: './detalle-inversor.component.html',
  styleUrls: ['./detalle-inversor.component.sass']
})
export class DetalleInversorComponent implements OnInit {

  public inversorForm: FormGroup;
  editable = true;
  cargando: boolean;
  inversorId: number;
  inversor: Inversor;
  process: boolean;
  public inversorTipos: Combo[];

  constructor(  private fB: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private fS: FormStatusService,
                private inversoresService: InversoresService,
                private errorService: ErrorService,
                private toastr: ToastrService,
                private comboService: CombosService) { }

  ngOnInit() {
    this.inversorId = +this.route.snapshot.paramMap.get('id');
    this.makeForm();
    this.getInversorTipos();
    this.get();
  }

  makeForm() {
    this.inversorForm = this.fB.group({
      nombre: [{ value: '', disabled: this.editable }, Validators.required],
      codigo: [{ value: '', disabled: this.editable }],
      pro_inversor_tipo_id: [{ value: 1, disabled: this.editable }, Validators.required],
      descripcion: [{ value: '', disabled: this.editable }]
    });
  }

  getInversorTipos() {
    this.comboService.getInversoresTiposCombo().subscribe((respuesta: CombosResponse) => {
      if (respuesta.success) {
        this.inversorTipos = respuesta.data;
      }
    }, err => {
      this.errorService.handleError(err);
    });
  }

  get() {
    this.cargando = true;
    this.inversoresService.obtenerInversor(this.inversorId)
      .subscribe((respuesta: any) => {
        this.inversor = respuesta.data;
        this.inversorForm.patchValue(this.inversor);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.inversorForm);
    this.inversoresService.actualizarInversor(this.inversorId, jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Aliado/Actor Institucional actualizado correctamente', 'Actualizar Aliado/Actor Institucional');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.inversorForm = this.fS.cambiarEstado(this.inversorForm, this.editable, ['codigo'], true);
    this.editable = !this.editable;
  }

}
