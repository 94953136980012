import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Combo } from 'src/app/util/interfaces/combo';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { NumberFormatPipe } from 'src/app/util/pipes/numberFormat.pipe';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { GrupoAhorro } from '../../../interfaces/grupo-ahorro.interface';
import { GrupoAhorroService } from '../../../services/grupo-ahorro.service'

@Component({
  selector: 'app-crear-ciclo',
  templateUrl: './crear-ciclo.component.html',
  styleUrls: ['./crear-ciclo.component.sass'],
  providers: [NumberFormatPipe]
})
export class CrearCicloComponent implements OnInit {

  idProyecto: number;
  idGrupo: number;
  periodos = [];
  private token;
  cargando: boolean;
  process: boolean;
  grupoAhorro: GrupoAhorro;
  editable = true;
  selected = null;
  searchForm: FormGroup;
  periodosForm: FormGroup;
  comunidades: Combo[];
  items;
  importedCiclo;
  
  private valorSocial: number;
  private valorPrestamo: number;
  private valorMulta: number;

  constructor(
    private gruposAhorroService: GrupoAhorroService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private fs: FormStatusService,
    private comboService: CombosService,
    private route: ActivatedRoute,
    private router: Router,
    private numberPipe: NumberFormatPipe
  ) {
    this.token = this.localStorage.getTokenFromStorage();
    this.periodosForm = fB.group({
      // nombre: [null, Validators.required],
      valor_multa: [null],
      aporte_fondo_social: [null],
      aporte_fondo_prestamo: [null],
      limite_acciones: [null],
      nombre_presidenta: [null],
      apellido_presidenta: [null],
      telefono_presidenta: [null],
      nombre_registradora: [null],
      apellido_registradora: [null],
      telefono_registradora: [null],
      nro_ciclo: [{ value: null, disabled: true }],
      periodicidad_reuniones: [null],
      fecha_apertura_ciclo: [null],
      fecha_cierre_ciclo: [null],
      cantidad_miembros: [{ value: null, disabled: true }],
      monto_acumulado_social: [{ value: null, disabled: true }],
      notas_adicionales: [null],
      estado_pago_kit: [null],
      observaciones_pago: [null],
      periodicidad_ciclo: [null],
      estado: [{ value: null, disabled: true }],
      gru_grupos_ciclos_dias: this.fB.array([this.createItem()]),
    });
  }


  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    //this.getGrupoGestion();
    //this.getComunidadesCombo();
  }

  crearCiclo() {
    this.process = true;
    this.getRealValues();
    const jsonData = this.fs.getJsonFromForm(this.periodosForm);
    this.gruposAhorroService.crearCiclo(this.idProyecto, this.idGrupo, jsonData)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('Ciclo creado exitosamente', 'Inicio de nuevo ciclo');
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.volver();
      }).add(() => {
        this.process = false;
      });
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  createItem(): FormGroup {
    return this.fB.group({
      dia: [null, Validators.required],
      hora: [null, Validators.required],
    });
  }

  deleteItem(index: number) {
    this.items = this.periodosForm.get('gru_grupos_ciclos_dias') as FormArray;
    this.items.removeAt(index);
  }

  addItem(): void {
    this.items = this.periodosForm.get('gru_grupos_ciclos_dias') as FormArray;
    this.items.push(this.createItem());
  }

  getLastOne(){
    this.gruposAhorroService.getCiclos(this.idProyecto, this.idGrupo)
      .subscribe((response: any) => {
        if (response.success && response.metadata.total > 0) {
          this.importedCiclo = response.data[0];
          this.periodosForm.patchValue(this.importedCiclo);
        } else {
          this.toastr.info('No hay ciclos disponibles para importar', 'Atención');
        }
      }, error => {
        this.errorService.handleError(error);
      }, ()=> {
      
      });
  }

  getRealValues() {
    if (this.valorMulta) {
      this.periodosForm.get('valor_multa').setValue(this.valorMulta);
    }
    if (this.valorSocial) {
      this.periodosForm.get('aporte_fondo_social').setValue(this.valorSocial);
    }
    if (this.valorPrestamo) {
      this.periodosForm.get('aporte_fondo_prestamo').setValue(this.valorPrestamo);
    }
  }

  numberSeparator(event, controlName) {
    const realValue = +event.target.value;
    switch (controlName) {
      case 'aporte_fondo_social':
        this.valorSocial = realValue;
        break;
      case 'aporte_fondo_prestamo':
        this.valorPrestamo = realValue;
        break;
      case 'valor_multa':
        this.valorMulta = realValue;
        break;
    }
    this.periodosForm.get(controlName).setValue(this.numberPipe.transform(realValue));
  }

}

