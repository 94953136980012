import { SubgrupoInstanciaService } from './../services/subgrupo-instancia.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { TallerParticipanteResponse } from '../../../../taller-participante/interfaces/taller-participante-response';
import { TallerParticipantesService } from '../../../../taller-participante/services/taller-participantes.service';

export interface DialogData {
  tallerId: number;
  proyectoId: number;
  instanciaId: number;
}
@Component({
  selector: 'app-crear-subgrupo-miembro',
  templateUrl: './crear-subgrupo-miembro.component.html',
  styleUrls: ['./crear-subgrupo-miembro.component.sass']
})
export class CrearSubgrupoMiembroComponent implements OnInit {

  public participante: FormGroup;
  private tallerId: number;
  private proyectoId: number;
  private instanciaId: number;
  private token;
  process: boolean;
  actores: any[];
  showMessage: boolean;
  public lottieConfig = {
    path: 'assets/lottie/empty-box.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };
  constructor(public dialogRef: MatDialogRef<CrearSubgrupoMiembroComponent>, private fB: FormBuilder,
    private tallerParticipanteService: TallerParticipantesService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private subgrupoInstanciaService: SubgrupoInstanciaService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.tallerId = this.data.tallerId;
    this.instanciaId = this.data.instanciaId;
    this.makeForm();
    this.getActores();
  }

  makeForm() {
    this.participante = this.fB.group({
      miembros: this.fB.array([]),
    });
  }

  private setMiembro(miembros: any) {
    const formArray = new FormArray([]);
    /* Acá asigne el id  */
    miembros.forEach(miembro => {
      formArray.push(this.fB.group({
        pro_grupo_taller_miembro_id: [miembro.id, Validators.required],
        nombre: [{ value: `${miembro.actor.persona.nombre} ${miembro.actor.persona.apellido}`, disabled: true }, Validators.required],
        presente: [true, Validators.required],
        comentario: [null]
      }));
    });
    return formArray;
  }

  getActores() {
    const searchV = { tipo: 'P' };
    this.tallerParticipanteService.getTallerParticipantes(this.token, this.tallerId, searchV, { page: 'disable' })
      .subscribe((respuesta: any) => {
        this.actores = respuesta.data;
        if (this.actores.length > 0) {
          this.participante.setControl('miembros', this.setMiembro(this.actores));
        } else {
          this.showMessage = true;
        }
      }, error => {
        this.errorService.handleError(error);
      });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.participante);
    this.subgrupoInstanciaService.crearSubgrupoMiembro(this.tallerId, this.instanciaId, jsonData)
      .subscribe((respuesta: TallerParticipanteResponse) => {
        if (respuesta.success) {
          this.toastr.success('Miembro registrado exitosamente', 'Registrar Miembro');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => {
        this.dialogRef.close(true);
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close(false);
  }

}
