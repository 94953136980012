import { NgxPermissionsService } from 'ngx-permissions';
import { ProyectoGobierno } from './interfaces/proyecto-gobierno';
import { ProyectosGobiernoService } from './services/proyectos-gobierno.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProyectosGobiernoResponse, ProyectoGobiernoResponse } from './interfaces/proyecto-gobierno-response';

@Component({
  selector: 'app-proyectos-gobierno',
  templateUrl: './proyectos-gobierno.component.html',
  styleUrls: ['./proyectos-gobierno.component.sass']
})
export class ProyectosGobiernoComponent implements OnInit {

  heading = 'Proyectos Gobierno';
  subheading = 'En este módulo se encuentra la lista de proyectos gobierno.';
  icon = 'pe-7s-ribbon icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'descripcion', 'acciones'];

  proyectosGobierno: ProyectoGobierno[];
  searchForm: FormGroup;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private fB: FormBuilder,
    private errorService: ErrorService,
    private toastr: ToastrService,
    private localStorage: LocalStorageService,
    private proyectosGobiernosService: ProyectosGobiernoService,
    private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.makeSearchForm();
    if (this.localStorage.getStoreSearchFrom() === 'mant_proyectos_gobierno') {
      this.searchForm.patchValue(this.localStorage.getStoreSearch());
    }
    this.getProyectosGobierno();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getProyectosGobierno() {
    this.cargando = true;
    this.proyectosGobiernosService.getProyectosGobiernos(this.searchForm.value, this.pagination)
      .subscribe((respuesta: ProyectosGobiernoResponse) => {
        this.proyectosGobierno = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    this.localStorage.storeSearch(this.searchForm, 'mant_proyectos_gobierno');
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_proyectos_gobierno/get477').then(result => {
      if (result) {
        this.localStorage.storeSearch(this.searchForm, 'mant_proyectos_gobierno');
        this.router.navigate(['./', id], { relativeTo: this.route });
      }
    });
  }

  borrar(id: number) {
    this.proyectosGobiernosService.borrarProyectoGobierno(id)
      .subscribe((respuesta: ProyectoGobiernoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto Gobierno borrado exitosamente', 'Borrar Proyecto Gobierno');
        }
        this.getProyectosGobierno();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getProyectosGobierno();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getProyectosGobierno();
  }

  pageChange() {
    this.getProyectosGobierno();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
