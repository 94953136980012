import { FormStatusService } from './../../../../util/services/form-status/form-status.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Persona } from '../../../Administracion/personas/interfaces/persona.interface';
import { Combo } from '../../../../util/interfaces/combo';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../../../util/local-storage.service';
import { ActorService } from '../services/actor.service';
import { ToastrService } from 'ngx-toastr';
import { ActorResponse } from '../interfaces/actor-response.interface';
import { map, startWith } from 'rxjs/operators';
import { PersonasResponse } from '../../../Administracion/personas/interfaces/personas-response.interface';
import { CombosResponse } from '../../../../util/interfaces/combos-response';
import { CombosService } from '../../../../util/services/combos/combos.service';
import { ErrorService } from '../../../../util/services/error.service';

@Component({
  selector: 'app-crear-actores',
  templateUrl: './crear-actores.component.html',
  styleUrls: ['./crear-actores.component.sass']
})
export class CrearActoresComponent implements OnInit {
  personas: Persona[];
  estadoActores: Combo[];
  rolActores: any[];
  process: boolean;
  actorForm: FormGroup;
  idProyecto: number;
  private token;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private actoresService: ActorService,
    private toastr: ToastrService,
    private fB: FormBuilder,
    private fS: FormStatusService,
    private errorService: ErrorService,
    private comboService: CombosService,
    private localStorage: LocalStorageService
  ) {
    this.token = this.localStorage.getTokenFromStorage();
    this.actorForm = this.fB.group({
      adm_persona_id: [null, [Validators.required]],
      pro_actor_estado_id: [null, [Validators.required]],
      pro_proyecto_rol_id: [null, [Validators.required]],
      fecha_entrada: [null, [Validators.required]],
      fecha_salida: null,
      persona: [{value: null, disabled: true}]
    });
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.getAutocompletes();
  }
  crearActor() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.actorForm);
    this.actoresService.crearActor(this.token, this.idProyecto, jsonData)
      .subscribe((respuesta: ActorResponse) => {
        if (respuesta.success) {
          this.toastr.success('Actor creado exitosamente', 'Creación de Actor');
          this.volver();
        }
      },
        error => {
          this.errorService.handleError(error);
        }).add(() => { this.process = false; });
  }

  private getEstadoActoresCombo() {
    this.comboService.getEstadoActoresCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.estadoActores = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => { });
  }
  private getRolActoresCombo() {
    this.comboService.getProyectoRoles(this.idProyecto)
      .subscribe((respuesta: CombosResponse) => {
        this.rolActores = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => { });
  }
  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }
  getAutocompletes() {
    this.getRolActoresCombo();
    this.getEstadoActoresCombo();
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  onCloseBusqueda(data: any): void {
    if (data) {
      const persona = `${data.nombre} ${data.apellido} - ${data.documento}`;
      this.actorForm.get('adm_persona_id').patchValue(data.adm_persona_id);
      this.actorForm.get('persona').patchValue(persona);
    }
  }
}
