import { Injectable } from '@angular/core';
import {Globals} from '../../../../Globals';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ActorService {

  constructor(private global: Globals, private http: HttpClient) {
  }

  getActores(token, idProyecto, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/actores', search, pagination);
    return this.http.get(url, {headers});
  }

  getActor(token, idProyecto, idActor, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/actores/' + idActor, search, pagination);
    return this.http.get(url, {headers});
  }

  crearActor(token, idProyecto, jsonData, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/actores', search, pagination);
    return this.http.post(url, jsonData, {headers});
  }

  editarActor(token, idProyecto, idActor, jsonData, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/actores/' + idActor, search, pagination);
    return this.http.put(url, jsonData, {headers});
  }

  borrarActor(token, idProyecto, idActor, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/actores/' + idActor, search, pagination);
    return this.http.delete(url, {headers});
  }
}
