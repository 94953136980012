import { TallerFormadorResponse } from './../interfaces/taller-formador-response';
import { TallerFormadorService } from './../services/taller-formador.service';
import { Component, OnInit, Inject } from '@angular/core';
import { TallerFormador } from '../interfaces/taller-formador';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { ComboActores } from 'src/app/util/interfaces/combo';
import { ComboActoresResponse } from 'src/app/util/interfaces/combos-response';

export interface DialogData {
  tallerId: number;
  formadorId: number;
  proyectoId: number;
}
@Component({
  selector: 'app-detalle-taller-formador',
  templateUrl: './detalle-taller-formador.component.html',
  styleUrls: ['./detalle-taller-formador.component.sass']
})
export class DetalleTallerFormadorComponent implements OnInit {

  public formadorForm: FormGroup;
  editable = true;
  formadorId: number;
  tallerId: number;
  cargando: boolean;
  private token;
  formador: TallerFormador;
  process: boolean;
  actores: ComboActores[];
  private proyectoId: number;
  constructor(public dialogRef: MatDialogRef<DetalleTallerFormadorComponent>, private fB: FormBuilder, private comboService: CombosService,
    private tallerFormadorService: TallerFormadorService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.tallerId = this.data.tallerId;
    this.formadorId = this.data.formadorId;
    this.proyectoId = this.data.proyectoId;
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
    this.getActores();
  }

  makeForm() {
    this.formadorForm = this.fB.group({
      pro_taller_id: [{ value: null, disabled: this.editable }, Validators.required],
      pro_actor_id: [{ value: null, disabled: this.editable }, Validators.required]
    });
  }

  get() {
    this.cargando = true;
    this.tallerFormadorService.getTallerFormador(this.tallerId, this.formadorId, this.token)
      .subscribe((respuesta: TallerFormadorResponse) => {
        this.formador = respuesta.data;
        this.formadorForm.patchValue(this.formador);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  getActores() {
    this.comboService.getProyectoActoresCombo(this.token, this.proyectoId)
    .subscribe((respuesta: ComboActoresResponse) => {
      this.actores = respuesta.data;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.formadorForm);
    this.tallerFormadorService.actualizarTallerFormador(this.tallerId, this.formadorId, this.token, jsonData)
      .subscribe((respuesta: TallerFormadorResponse) => {
        if (respuesta.success) {
          this.toastr.success('Formador actualizado correctamente', 'Actualizar Formador');
          this.get();
          this.editableSwitcher();
          this.dialogRef.close('updated');
        }
      }, error => {
        this.errorService.handleError(error);
        this.formadorForm.enable();
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

  public editableSwitcher() {
    this.formadorForm = this.fS.cambiarEstado(this.formadorForm, this.editable);
    this.editable = !this.editable;
  }

}
