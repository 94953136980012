import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotoneraCreacionComponent } from './botonera-creacion.component';

@NgModule({
  declarations: [BotoneraCreacionComponent],
  imports: [
    CommonModule
  ],
  exports: [BotoneraCreacionComponent]
})
export class BotoneraCreacionModule { }
