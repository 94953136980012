import {MenuDetalleProyectoModule} from './menu-detalle-proyecto/menu-detalle-proyecto.module';
import {GestionProyectoModule} from './gestion-proyecto/gestion-proyecto.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ConfigDataSliderModule} from './../../util/config-data-slider/config-data-slider.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {NgSelectModule} from '@ng-select/ng-select';
import {DatosNoEncontradosModule} from 'src/app/util/datos-no-encontrados/datos-no-encontrados.module';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BotoneraEdicionModule} from './../../util/botonera-edicion/botonera-edicion.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SpinnerModule} from './../../util/spinner/spinner.module';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgbPaginationModule, NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {BotoneraCreacionModule} from './../../util/botonera-creacion/botonera-creacion.module';
import {BotonFiltroModule} from './../../util/boton-filtro/boton-filtro.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {LayoutModule} from './../../Layout/layout.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProyectosRoutingModule} from './proyectos-routing.module';
import {ProyectosComponent} from './proyectos.component';
import {CrearProyectoComponent} from './crear-proyecto/crear-proyecto.component';
import {DetalleProyectoComponent} from './detalle-proyecto/detalle-proyecto.component';
import {DocumentacionProyectoComponent} from './documentacion-proyecto/documentacion-proyecto.component';
import {ActoresProyectoComponent} from './actores-proyecto/actores-proyecto.component';
import {GestionProyectoComponent} from './gestion-proyecto/gestion-proyecto.component';
import {MonitoreoProyectoComponent} from './monitoreo-proyecto/monitoreo-proyecto.component';
import {IndicadoresProyectoComponent} from './indicadores-proyecto/indicadores-proyecto.component';
import {CrearActoresComponent} from './actores-proyecto/crear-actores/crear-actores.component';
import {DetalleActoresComponent} from './actores-proyecto/detalle-actores/detalle-actores.component';
import {EditarProyectoComponent} from './editar-proyecto/editar-proyecto.component';
import {DimensionMonitoreoProyectoComponent} from './monitoreo-proyecto/dimension-monitoreo-proyecto/dimension-monitoreo-proyecto.component';
import {IndicadorMonitoreoProyectoComponent} from './monitoreo-proyecto/indicador-monitoreo-proyecto/indicador-monitoreo-proyecto.component';
import {RespuestaMonitoreoProyectoComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/respuesta-monitoreo-proyecto.component';
import {CrearDimensionMonitoreoProyectoComponent} from './monitoreo-proyecto/dimension-monitoreo-proyecto/crear-dimension-monitoreo-proyecto/crear-dimension-monitoreo-proyecto.component';
import {DetalleDimensionMonitoreoProyectoComponent} from './monitoreo-proyecto/dimension-monitoreo-proyecto/detalle-dimension-monitoreo-proyecto/detalle-dimension-monitoreo-proyecto.component';
import {CrearIndicadorMonitoreoProyectoComponent} from './monitoreo-proyecto/indicador-monitoreo-proyecto/crear-indicador-monitoreo-proyecto/crear-indicador-monitoreo-proyecto.component';
import {DetalleIndicadorMonitoreoProyectoComponent} from './monitoreo-proyecto/indicador-monitoreo-proyecto/detalle-indicador-monitoreo-proyecto/detalle-indicador-monitoreo-proyecto.component';
import {CrearDocumentacionProyectoComponent} from './documentacion-proyecto/crear-documentacion-proyecto/crear-documentacion-proyecto.component';
import {DetalleDocumentacionProyectoComponent} from './documentacion-proyecto/detalle-documentacion-proyecto/detalle-documentacion-proyecto.component';
import {PaisProyectoComponent} from './pais-proyecto/pais-proyecto.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {ConfiguracionesProyectoComponent} from './editar-proyecto/configuraciones-proyecto/configuraciones-proyecto.component';
import {CrearConfiguracionProyectoComponent} from './editar-proyecto/configuraciones-proyecto/crear-configuracion-proyecto/crear-configuracion-proyecto.component';
import {DetalleConfiguracionProyectoComponent} from './editar-proyecto/configuraciones-proyecto/detalle-configuracion-proyecto/detalle-configuracion-proyecto.component';
import {ConfigProyectoGestionComponent} from './editar-proyecto/config-proyecto-gestion/config-proyecto-gestion.component';
import {CrearConfigProyectoGestionComponent} from './editar-proyecto/config-proyecto-gestion/crear-config-proyecto-gestion/crear-config-proyecto-gestion.component';
import {DetalleConfigProyectoGestionComponent} from './editar-proyecto/config-proyecto-gestion/detalle-config-proyecto-gestion/detalle-config-proyecto-gestion.component';
import {ConfigProyectoRolComponent} from './editar-proyecto/config-proyecto-rol/config-proyecto-rol.component';
import {CrearProyectoRolComponent} from './editar-proyecto/config-proyecto-rol/crear-proyecto-rol/crear-proyecto-rol.component';
import {DetalleProyectoRolComponent} from './editar-proyecto/config-proyecto-rol/detalle-proyecto-rol/detalle-proyecto-rol.component';
import {ConfigDocumentoTipoComponent} from './editar-proyecto/config-documento-tipo/config-documento-tipo.component';
import {CrearConfigDocumentoTipoComponent} from './editar-proyecto/config-documento-tipo/crear-config-documento-tipo/crear-config-documento-tipo.component';
import {DetalleConfigDocumentoTipoComponent} from './editar-proyecto/config-documento-tipo/detalle-config-documento-tipo/detalle-config-documento-tipo.component';
import {ConfigProyectoMonitoreoComponent} from './editar-proyecto/config-proyecto-monitoreo/config-proyecto-monitoreo.component';
import {CrearProyectoMonitoreoComponent} from './editar-proyecto/config-proyecto-monitoreo/crear-proyecto-monitoreo/crear-proyecto-monitoreo.component';
import {DetalleProyectoMonitoreoComponent} from './editar-proyecto/config-proyecto-monitoreo/detalle-proyecto-monitoreo/detalle-proyecto-monitoreo.component';
import {ConfigProyectoUbicacionComponent} from './editar-proyecto/config-proyecto-ubicacion/config-proyecto-ubicacion.component';
import {CrearConfigProyectoUbicacionComponent} from './editar-proyecto/config-proyecto-ubicacion/crear-config-proyecto-ubicacion/crear-config-proyecto-ubicacion.component';
import {DetalleConfigProyectoUbicacionComponent} from './editar-proyecto/config-proyecto-ubicacion/detalle-config-proyecto-ubicacion/detalle-config-proyecto-ubicacion.component';
import {UbicacionDepartamentosComponent} from './editar-proyecto/config-proyecto-ubicacion/ubicacion-departamentos/ubicacion-departamentos.component';
import {UbicacionCiudadesComponent} from './editar-proyecto/config-proyecto-ubicacion/ubicacion-ciudades/ubicacion-ciudades.component';
import {UbicacionComunidadesComponent} from './editar-proyecto/config-proyecto-ubicacion/ubicacion-comunidades/ubicacion-comunidades.component';
import {ActorUbicacionComponent} from './actores-proyecto/detalle-actores/actor-ubicacion/actor-ubicacion.component';
import {CrearActorUbicacionComponent} from './actores-proyecto/detalle-actores/actor-ubicacion/crear-actor-ubicacion/crear-actor-ubicacion.component';
import {DetalleActorUbicacionComponent} from './actores-proyecto/detalle-actores/actor-ubicacion/detalle-actor-ubicacion/detalle-actor-ubicacion.component';
import {UbicacionDepartamentosActorComponent} from './actores-proyecto/detalle-actores/actor-ubicacion/ubicacion-departamentos-actor/ubicacion-departamentos-actor.component';
import {UbicacionCiudadesActorComponent} from './actores-proyecto/detalle-actores/actor-ubicacion/ubicacion-ciudades-actor/ubicacion-ciudades-actor.component';
import {UbicacionComunidadesActorComponent} from './actores-proyecto/detalle-actores/actor-ubicacion/ubicacion-comunidades-actor/ubicacion-comunidades-actor.component';
import {WorkingOnItModule} from 'src/app/util/working-on-it/working-on-it.module';
import {DetalleRespuestaMonitoreoComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/detalle-respuesta-monitoreo.component';
import {TablaRespuestasMonitoreoComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/tabla-respuestas-monitoreo/tabla-respuestas-monitoreo.component';
import {EditorEncuestaComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/editor-encuesta/editor-encuesta.component';
import {CrearEncuestaMonitoreoComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/crear-encuesta-monitoreo/crear-encuesta-monitoreo.component';
import {RespuestaActorComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/tabla-respuestas-monitoreo/respuesta-actor/respuesta-actor.component';
import {ImportarEditorEncuestaDialogComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/editor-encuesta/importar-editor-encuesta-dialog/importar-editor-encuesta-dialog.component';
import {IncludesStrPipePipe} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/editor-encuesta/pipes/includes-str-pipe.pipe';
import {ConfigProyectoInversoresComponent} from './editar-proyecto/config-proyecto-inversores/config-proyecto-inversores.component';
import {CrearConfigProyectoInversoresComponent} from './editar-proyecto/config-proyecto-inversores/crear-config-proyecto-inversores/crear-config-proyecto-inversores.component';
import {DetalleConfigProyectoInversoresComponent} from './editar-proyecto/config-proyecto-inversores/detalle-config-proyecto-inversores/detalle-config-proyecto-inversores.component';
import {ConfigTalleresComponent} from './editar-proyecto/config-talleres/config-talleres.component';
import {ConfigTalleresParticipantesComponent} from './editar-proyecto/config-talleres-participantes/config-talleres-participantes.component';
import {ConfigEncuestaProyectoComponent} from './monitoreo-proyecto/config-encuesta-proyecto/config-encuesta-proyecto.component';
import {CrudUsuariosMovilesComponent} from './monitoreo-proyecto/config-encuesta-proyecto/crud-usuarios-moviles/crud-usuarios-moviles.component';
import {CrudAccesoEncuestasComponent} from './monitoreo-proyecto/config-encuesta-proyecto/crud-acceso-encuestas/crud-acceso-encuestas.component';
import {CrearUsuarioMovilComponent} from './monitoreo-proyecto/config-encuesta-proyecto/crud-usuarios-moviles/crear-usuario-movil/crear-usuario-movil.component';
import {MatDialogModule} from '@angular/material/dialog';
import {CrearAccesoEncuestaComponent} from './monitoreo-proyecto/config-encuesta-proyecto/crud-acceso-encuestas/crear-acceso-encuesta/crear-acceso-encuesta.component';
import {TablaRespuestasQuantiComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/tabla-respuestas-quanti/tabla-respuestas-quanti.component';
import {EditarRespuestaActorComponent} from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/tabla-respuestas-monitoreo/respuesta-actor/editar-respuesta-actor/editar-respuesta-actor.component';
import {NgxPowerBiModule} from 'ngx-powerbi';
import {NgxDashboardsBixModule} from '../../util/ngx-dashboards-bix/ngx-dashboards-bix.module';
import {AppPipesModule} from '../../util/pipes/app-pipes.module';
import { RespuestaOdkDetalleComponent } from './monitoreo-proyecto/respuesta-monitoreo-proyecto/detalle-respuesta-monitoreo/tabla-respuestas-monitoreo/respuesta-odk-detalle/respuesta-odk-detalle.component';
import {BusquedaPersonasModule} from '../../util/busqueda-personas/busqueda-personas.module';
import {BusquedaActoresModule} from '../../util/busqueda-actores/busqueda-actores.module';
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import { faPlus, faInfo } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    ProyectosComponent,
    CrearProyectoComponent,
    DetalleProyectoComponent,
    DocumentacionProyectoComponent,
    ActoresProyectoComponent,
    GestionProyectoComponent,
    MonitoreoProyectoComponent,
    IndicadoresProyectoComponent,
    CrearActoresComponent,
    DetalleActoresComponent,
    EditarProyectoComponent,
    DimensionMonitoreoProyectoComponent,
    IndicadorMonitoreoProyectoComponent,
    RespuestaMonitoreoProyectoComponent,
    CrearDimensionMonitoreoProyectoComponent,
    DetalleDimensionMonitoreoProyectoComponent,
    CrearIndicadorMonitoreoProyectoComponent,
    DetalleIndicadorMonitoreoProyectoComponent,
    CrearDocumentacionProyectoComponent,
    DetalleDocumentacionProyectoComponent,
    PaisProyectoComponent,
    ConfiguracionesProyectoComponent,
    CrearConfiguracionProyectoComponent,
    DetalleConfiguracionProyectoComponent,
    ConfigProyectoGestionComponent,
    CrearConfigProyectoGestionComponent,
    DetalleConfigProyectoGestionComponent,
    ConfigProyectoRolComponent,
    CrearProyectoRolComponent,
    DetalleProyectoRolComponent,
    ConfigDocumentoTipoComponent,
    CrearConfigDocumentoTipoComponent,
    DetalleConfigDocumentoTipoComponent,
    ConfigProyectoMonitoreoComponent,
    CrearProyectoMonitoreoComponent,
    DetalleProyectoMonitoreoComponent,
    ConfigProyectoUbicacionComponent,
    CrearConfigProyectoUbicacionComponent,
    DetalleConfigProyectoUbicacionComponent,
    UbicacionDepartamentosComponent,
    UbicacionCiudadesComponent,
    UbicacionComunidadesComponent,
    ActorUbicacionComponent,
    CrearActorUbicacionComponent,
    DetalleActorUbicacionComponent,
    UbicacionDepartamentosActorComponent,
    UbicacionCiudadesActorComponent,
    UbicacionComunidadesActorComponent,
    DetalleRespuestaMonitoreoComponent,
    TablaRespuestasMonitoreoComponent,
    EditorEncuestaComponent,
    CrearEncuestaMonitoreoComponent,
    RespuestaActorComponent,
    ImportarEditorEncuestaDialogComponent,
    IncludesStrPipePipe,
    ConfigProyectoInversoresComponent,
    CrearConfigProyectoInversoresComponent,
    DetalleConfigProyectoInversoresComponent,
    ConfigTalleresComponent,
    ConfigTalleresParticipantesComponent,
    ConfigEncuestaProyectoComponent,
    CrudUsuariosMovilesComponent,
    CrudAccesoEncuestasComponent,
    CrearUsuarioMovilComponent,
    CrearAccesoEncuestaComponent,
    TablaRespuestasQuantiComponent,
    EditarRespuestaActorComponent,
    RespuestaOdkDetalleComponent],
    imports: [
        CommonModule,
        ProyectosRoutingModule,
        MatFormFieldModule,
        LayoutModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatTableModule,
        MatDatepickerModule,
        BotonFiltroModule,
        BotoneraCreacionModule,
        NgbPaginationModule,
        FormsModule,
        PerfectScrollbarModule,
        SweetAlert2Module,
        SpinnerModule,
        MatAutocompleteModule,
        BotoneraEdicionModule,
        NgbDropdownModule,
        MatButtonModule,
        FlexLayoutModule,
        MatIconModule,
        MatTabsModule,
        DatosNoEncontradosModule,
        MatCheckboxModule,
        FontAwesomeModule,
        MatExpansionModule,
        NgSelectModule,
        WorkingOnItModule,
        MatRadioModule,
        MatSlideToggleModule,
        ConfigDataSliderModule,
        NgxPermissionsModule.forChild(),
        // Proyecto Modules
        GestionProyectoModule,
        // Menu
        MenuDetalleProyectoModule,
        MatDialogModule,
        NgxDashboardsBixModule,
        AppPipesModule,
        BusquedaPersonasModule,
        NgbTooltipModule,
        BusquedaActoresModule
    ],
})
export class ProyectosModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faPlus, faInfo);
  }
}
