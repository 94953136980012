import { PreguntasResponse, PreguntaResponse } from './interfaces/pregunta-response';
import { PoolPreguntasService } from './services/pool-preguntas.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { Pregunta } from './interfaces/pregunta';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-pool-preguntas',
  templateUrl: './pool-preguntas.component.html',
  styleUrls: ['./pool-preguntas.component.sass']
})
export class PoolPreguntasComponent implements OnInit {

  heading = 'Pool de Preguntas';
  subheading = 'En este módulo se encuentra la lista de preguntas.';
  icon = 'pe-7s-id icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['texto', 'codigo', 'indicadores'];
  preguntas: Pregunta[];
  searchForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder, private poolPreguntasService: PoolPreguntasService,
              private errorService: ErrorService, private toastr: ToastrService, private ngxPermissions: NgxPermissionsService) { }

  ngOnInit() {
    this.makeSearchForm();
    this.getPreguntas();
  }


  makeSearchForm() {
    this.cargando = true;
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
    this.cargando = false;
  }

  getPreguntas() {
    this.cargando = true;
    this.poolPreguntasService.obtenerPreguntas(this.searchForm.value, this.pagination).subscribe((respuesta: PreguntasResponse) => {
      if (respuesta.success) {
        this.preguntas = respuesta.data;
        this.total = respuesta.metadata.total;
      }
    }, error => {
      this.errorService.handleError(error);
    }, () => {
      this.cargando = false;
    });
  }

  crear() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.ngxPermissions.hasPermission('PRO/mant_pool_preguntas/get810')
      .then(result => {
        if (result) {
          this.router.navigate(['./', id], { relativeTo: this.route });
        }
      });
  }

  borrar(id: number) {
    this.poolPreguntasService.borrarPregunta(id).subscribe((respuesta: PreguntaResponse) => {
      if (respuesta.success) {
        this.toastr.success('Pregunta borrada exitosamente', 'Borrar Pregunta');
      }
    }, error => {
      this.errorService.handleError(error);
    }).add(() => {
      this.getPreguntas();
    });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getPreguntas();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getPreguntas();
  }

  pageChange() {
    this.getPreguntas();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
