import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-indicador-monitoreo-proyecto',
  templateUrl: './indicador-monitoreo-proyecto.component.html',
  styleUrls: ['./indicador-monitoreo-proyecto.component.sass']
})
export class IndicadorMonitoreoProyectoComponent implements OnInit {


  indicadores = [{
    id: 1,
    nombre: 'Posibilidad económica de acceso a alimentos',
    orden: 1,
    codigo: 'IAA',
    dimension: 'Seguridad Alimentaria',
    descripcion: 'Descripcion de posibilidad economica de acceso a alimentos'
  }, {
    id: 2,
    nombre: 'Producción de Alimentos para el autoconsumo',
    orden: 2,
    codigo: 'IAAC',
    dimension: 'Seguridad Alimentaria',
    descripcion: 'Descripcion de Producción de Alimentos para el autoconsumo'
  }, {
    id: 3,
    nombre: 'Nutrición y dieta balanceada',
    orden: 3,
    codigo: 'INDB',
    dimension: 'Seguridad Alimentaria',
    descripcion: 'Descripcion de Nutrición y dieta balanceada'
  }, {
    id: 4,
    nombre: 'Condiciones de salud',
    orden: 4,
    codigo: 'CDS',
    dimension: 'Seguridad Alimentaria',
    descripcion: 'Descripcion de Condiciones de salud'
  }, {
    id: 5,
    nombre: 'Acceso a servicios de salud',
    orden: 5,
    codigo: 'ASS',
    dimension: 'Seguridad Alimentaria',
    descripcion: 'Descripcion de acceso a servicios de salud'
  }, {
    id: 6,
    nombre: 'Nivel educativo de los miembros de la familia',
    orden: 6,
    codigo: 'NEMF',
    dimension: 'Seguridad Alimentaria',
    descripcion: 'Descripcion de Nivel educativo de los miembros de la familia'
  }];
  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
  }

  verDetalle(id) {
    this.router.navigate(['./', 1], { relativeTo: this.route });
  }
  crearIndicador() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

}
