import { ComponenteGestionResponse } from '../interfaces/componente-gestion-response';
import { ComponentesGestionService } from '../services/componentes-gestion.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { ComponenteGestion } from '../interfaces/componente-gestion';

@Component({
  selector: 'app-detalle-componente-gestion',
  templateUrl: './detalle-componente-gestion.component.html',
  styleUrls: ['./detalle-componente-gestion.component.sass']
})
export class DetalleComponenteGestionComponent implements OnInit {

  public componenteForm: FormGroup;
  editable = true;
  componenteGestionId: number;
  private token;
  cargando: boolean;
  process: boolean;
  componenteGestion: ComponenteGestion;

  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private fS: FormStatusService,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private componenteGestionService: ComponentesGestionService) { }

  ngOnInit() {
    this.componenteGestionId = +this.route.snapshot.paramMap.get('id');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.componenteForm = this.fB.group({
      nombre: [{ value: undefined, disabled: this.editable }, Validators.required],
      codigo: [{ value: undefined, disabled: this.editable }, Validators.required],
      descripcion: [{ value: undefined, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.componenteGestionService.getComponenteGestion(this.componenteGestionId, this.token)
      .subscribe((respuesta: ComponenteGestionResponse) => {
        this.componenteGestion = respuesta.data;
        this.componenteForm.patchValue(this.componenteGestion);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.componenteForm);
    this.componenteGestionService.actualizarComponenteGestion(this.componenteGestionId, this.token, jsonData)
      .subscribe((respuesta: ComponenteGestionResponse) => {
        if (respuesta.success) {
          this.toastr.success('Componente de gestión actualizado correctamente', 'Actualizar componente de gestión');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.componenteForm = this.fS.cambiarEstado(this.componenteForm, this.editable, ['nombre', 'codigo'], true);
    this.editable = !this.editable;
  }

}
