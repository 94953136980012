import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { TallerParticipanteResponse } from '../../../../taller-participante/interfaces/taller-participante-response';
import { TallerParticipantesService } from '../../../../taller-participante/services/taller-participantes.service';
import { SubgrupoInstanciaService } from '../services/subgrupo-instancia.service';

export interface DialogData {
  tallerId: number;
  proyectoId: number;
  instanciaId: number;
  miembroId: number;
}
@Component({
  selector: 'app-detalle-subgrupo-miembro',
  templateUrl: './detalle-subgrupo-miembro.component.html',
  styleUrls: ['./detalle-subgrupo-miembro.component.sass']
})
export class DetalleSubgrupoMiembroComponent implements OnInit {

  public participante: FormGroup;
  private tallerId: number;
  private miembroId: number;
  private instanciaId: number;
  editable = true;
  process: boolean;
  cargando: boolean;
  miembro: any;
  constructor(public dialogRef: MatDialogRef<DetalleSubgrupoMiembroComponent>, private fB: FormBuilder,
    private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private subgrupoInstanciaService: SubgrupoInstanciaService) { }

  ngOnInit() {
    this.tallerId = this.data.tallerId;
    this.instanciaId = this.data.instanciaId;
    this.miembroId = this.data.miembroId;
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.participante = this.fB.group({
      pro_grupo_taller_miembro_id: [{value: null, disabled: this.editable}, Validators.required],
      presente: [{value: null, disabled: this.editable}, Validators.required],
      comentario: [{value: null, disabled: this.editable}]
    });
  }

  get() {
    this.cargando = true;
    this.subgrupoInstanciaService.getSubgrupoMiembro(this.tallerId, this.instanciaId, this.miembroId)
      .subscribe((respuesta: TallerParticipanteResponse) => {
        if (respuesta.success) {
          this.miembro = respuesta.data;
          this.participante.patchValue(this.miembro);
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  update() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.participante);
    this.subgrupoInstanciaService.actualizarSubgrupoMiembro(this.tallerId, this.instanciaId,this.miembroId, jsonData)
      .subscribe((respuesta: TallerParticipanteResponse) => {
        if (respuesta.success) {
          this.toastr.success('Miembro registrado exitosamente', 'Registrar Miembro');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => {
        this.dialogRef.close(true);
      }).add(() => { this.editableSwitcher(); this.process = false; });
  }

  volver() {
    this.dialogRef.close(false);
  }

  editableSwitcher(){
    this.fS.cambiarEstado(this.participante, this.editable, ['pro_grupo_taller_miembro_id'], true);
    this.editable = !this.editable;
  }
}
