import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-informacion-miembro-excel',
  templateUrl: './informacion-miembro-excel.component.html',
  styleUrls: ['./informacion-miembro-excel.component.sass']
})
export class InformacionMiembroExcelComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<InformacionMiembroExcelComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { }

  ngOnInit() {
  }

}
