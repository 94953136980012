import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hasValue'
})
export class HasValuePipe implements PipeTransform {

  transform(value: any[], args?: any): any {
    let result = false;
    if (value) {
      value.forEach(element => {
        if (element.value) {
          result = true;
        }
      });
    }
    return result;
  }

}
