import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-respuestas-predeterminadas',
  templateUrl: './respuestas-predeterminadas.component.html',
  styleUrls: ['./respuestas-predeterminadas.component.sass']
})
export class RespuestasPredeterminadasComponent implements OnInit {
 
  constructor() { }

  ngOnInit() {
  }

}
