import { CombosResponse } from './../../../util/interfaces/combos-response';
import { CombosService } from './../../../util/services/combos/combos.service';
import { PreguntaTipoResponse, PreguntasTiposResponse } from './interfaces/pregunta-tipo-response';
import { PreguntasTiposService } from './services/preguntas-tipos.service';
import { PreguntaTipo } from './interfaces/pregunta-tipo';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { Combo } from 'src/app/util/interfaces/combo';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-preguntas-tipos',
  templateUrl: './preguntas-tipos.component.html',
  styleUrls: ['./preguntas-tipos.component.sass']
})
export class PreguntasTiposComponent implements OnInit {

  heading = 'Preguntas Tipo';
  subheading = 'En este módulo se encuentra la lista de preguntas tipo.';
  icon = 'pe-7s-id icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'acciones'];
  preguntas: Combo[];
  searchForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder, private preguntasTiposService: PreguntasTiposService,
    private errorService: ErrorService, private toastr: ToastrService, private comboService: CombosService,
    private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.makeSearchForm();
    this.getPreguntasTipo();
  }


  makeSearchForm() {
    this.cargando = true;
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
    this.cargando = false;
  }

  getPreguntasTipo() {
    this.cargando = true;
    this.comboService.getTiposPregunta()
      .subscribe((respuesta: CombosResponse) => {
        if (respuesta.success) {
          this.preguntas = respuesta.data;
          this.total = respuesta.metadata.total;
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  crear() {
     this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_preguntas_tipos/get218').then(result => {
      if (result) {
        this.router.navigate(['./', id], { relativeTo: this.route });
      }
    });
  }

  borrar(id: number) {
    this.preguntasTiposService.borrarPreguntaTipo(id).subscribe((respuesta: PreguntaTipoResponse) => {
      if (respuesta.success) {
        this.toastr.success('Pregunta Tipo borrada exitosamente', 'Borrar Pregunta Tipo');
      }
    }, error => {
      this.errorService.handleError(error);
    }).add(() => {
      this.getPreguntasTipo();
    });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getPreguntasTipo();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getPreguntasTipo();
  }

  pageChange() {
    this.getPreguntasTipo();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
