import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GrupoAhorroService } from '../../../../services/grupo-ahorro.service';

@Component({
  selector: 'app-crear-miembro',
  templateUrl: './crear-miembro.component.html',
  styleUrls: ['./crear-miembro.component.sass']
})
export class CrearMiembroComponent implements OnInit {
  public nuevoMiembro: FormGroup;
  private token;
  process: boolean;
  option: number;
  editable: false;
  miembros = [];
  form_miembros = new FormControl();
  file: any;
  idProyecto;
  idGrupo;
  idCiclo;
  actorId;

  constructor(
    private fB: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fS: FormStatusService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private miembrosService: CombosService,
    public dialogRef: MatDialogRef<CrearMiembroComponent>,
    private gruposAhorroService: GrupoAhorroService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.token = this.localStorage.getTokenFromStorage();
    this.idProyecto = this.data.idProyecto;
    this.idGrupo = this.data.idGrupo;
    this.idCiclo = this.data.idCiclo;
    console.log(` Id del Proyecto ${this.idProyecto}, Id del Grupo ${this.idGrupo}, Id del Ciclo ${this.idCiclo} `);
    this.makeForm();
  }

  makeForm() {
    this.nuevoMiembro = this.fB.group({
      actor_nombre: [{ value: null, disabled: true }],
      actor_id: [null, Validators.required],
    });
  }

  fileChangeEvent(files): void {
    this.file = files[0];
  }

  agregarMiembro() {
    const jsonData = this.fS.getJsonFromForm(this.nuevoMiembro);
    this.gruposAhorroService.crearMiembroCiclos(this.token, this.idProyecto, this.idGrupo, this.idCiclo, jsonData)
      .subscribe((res: any) => {
        this.toastr.success('Miembro agregado exitosamente', 'Agregar miembro');
        this.dialogRef.close(true);
      },
        error => {
          this.errorService.handleError(error);
        }).add(() => { });
  }

  volver() {
    this.dialogRef.close(false);
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
  }

  saveData(event) {
    const nombre = `${event.nombre} ${event.apellido} - ${event.edad} años - ${event.documento_completo}`;
    this.nuevoMiembro.get('actor_nombre').setValue(nombre);
    this.nuevoMiembro.get('actor_id').setValue(event.pro_actor_id);
  }


}

