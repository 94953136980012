import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '../../../../util/local-storage.service';
import {FormStatusService} from '../../../../util/services/form-status/form-status.service';
import {ToastrService} from 'ngx-toastr';
import {TallerParticipanteService} from '../services/taller-participante.service';
import {HttpErrorResponse} from '@angular/common/http';
import {TallerParticipanteResponse} from '../interfaces/taller-participante-response.interface';
import {ErrorService} from '../../../../util/services/error.service';

@Component({
  selector: 'app-crear-taller-participante',
  templateUrl: './crear-taller-participante.component.html',
  styleUrls: ['./crear-taller-participante.component.sass']
})
export class CrearTallerParticipanteComponent implements OnInit {
  public nuevoTallerParticipante: FormGroup;
  private token;
  process: boolean;

  constructor(private fB: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private tallerParticipanteService: TallerParticipanteService,
              private localStorage: LocalStorageService,
              private fS: FormStatusService,
              private errorService: ErrorService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.makeForm();
  }

  makeForm() {
    this.nuevoTallerParticipante = this.fB.group({
      nombre: [null, Validators.required],
      orden: [null, Validators.required],
      descripcion: [null]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoTallerParticipante);
    this.tallerParticipanteService.crearTallerParticipante(jsonData)
      .subscribe((respuesta: TallerParticipanteResponse) => {
        if (respuesta.success) {
          this.toastr.success('Taller participante creado exitosamente', 'Crear Taller participante');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoTallerParticipante.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
