import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from '../../../../util/services/error.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ActorService} from '../services/actor.service';
import {LocalStorageService} from '../../../../util/local-storage.service';
import {Actor} from '../interfaces/actor.interface';
import {ActorResponse} from '../interfaces/actor-response.interface';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CombosService} from '../../../../util/services/combos/combos.service';
import {PersonaResponse, PersonasResponse} from '../../../Administracion/personas/interfaces/personas-response.interface';
import {Persona} from '../../../Administracion/personas/interfaces/persona.interface';
import {CombosResponse} from '../../../../util/interfaces/combos-response';
import {Combo} from '../../../../util/interfaces/combo';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {FormStatusService} from '../../../../util/services/form-status/form-status.service';

@Component({
  selector: 'app-detalle-actores',
  templateUrl: './detalle-actores.component.html',
  styleUrls: ['./detalle-actores.component.sass']
})
export class DetalleActoresComponent implements OnInit {
  personas: Persona[];
  estadoActores: Combo[];
  rolActores: any[];
  cargando: boolean;
  idActor: number;
  actor: Actor;
  idProyecto: number;
  actorForm: FormGroup;
  editable = true;
  private token;
  dashboardId: string;
  filter: string;
  constructor(
    private actoresService: ActorService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private comboService: CombosService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private fS: FormStatusService
  ) {
    this.token = this.localStorage.getTokenFromStorage();
    this.actorForm = this.fB.group({
      adm_persona_id: [{value: null}, [Validators.required]],
      pro_actor_estado_id: [{value: null}, [Validators.required]],
      pro_proyecto_rol_id: [{value: null}, [Validators.required]],
      fecha_entrada: null,
      fecha_salida: null,
      persona: [{value: null, disabled: true}]
    });
  }

  ngOnInit() {
    this.idActor = +this.route.snapshot.paramMap.get('id');
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.dashboardId = this.localStorage.getParametrosFromStorage().DASHBOARD2_ID;
    this.filter = `&filter=vw_preguntas_respuestas/proyecto_id%20eq%20${this.idProyecto}`;
    this.getActor();
    this.getEstadoActoresCombo();
    this.getRolActoresCombo();
  }
  private getActor() {
    this.cargando = true;
    this.actoresService.getActor(this.token, this.idProyecto, this.idActor)
      .subscribe((respuesta: ActorResponse) => {
          this.actor = respuesta.data;
          this.actorForm.patchValue(this.actor);
          const persona = this.actor.persona_nombre + ' ' + this.actor.persona_apellido;
          this.actorForm.get('persona').patchValue(persona);
          this.actorForm.disable();
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      this.cargando = false;
    });
  }

  private getEstadoActoresCombo() {
    this.comboService.getEstadoActoresCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.estadoActores = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {});
  }
  private getRolActoresCombo() {
    this.comboService.getProyectoRoles(this.idProyecto)
      .subscribe((respuesta: CombosResponse) => {
        this.rolActores = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {});
  }
  volver() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }
  irAPersona() {
    window.open(`#/administracion/personas/${this.actor.adm_persona_id}`, '_blank');
  }
  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }
  getAutocompletes() {
    this.getRolActoresCombo();
    this.getEstadoActoresCombo();
  }
  editarActor() {
    this.actorForm = this.fS.cambiarEstado(this.actorForm, this.editable, ['persona'], true);
    this.editable = !this.editable;
  }

  actualizarActor() {
    this.actoresService.editarActor(this.token, this.idProyecto, this.idActor, JSON.stringify(this.actorForm.value))
      .subscribe((respuesta: ActorResponse) => {
        this.toastr.success('Actor editado exitosamente', 'Edición de Actor');
        this.actorForm.disable();
        this.editable = !this.editable;
        this.getActor();
        },
        error => {
          this.errorService.handleError(error);
        }).add(() => {
    });
  }
}
