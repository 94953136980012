import { Globals } from './../../../../../../../Globals';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditorEncuestaService {

  constructor(private http: HttpClient, private global: Globals) { }

  crearPregunta(encuestaId, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/encuestas/' + encuestaId + '/preguntas';
    return this.http.post(url, jsonData, { headers });
  }
}
