import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ConfigProyectoMonitoreoService {

  constructor(private http: HttpClient, private global: Globals) { }

  getProyectoMonitoreos(token, proyectoId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/proyectos/' + proyectoId + '/proyectosMonitoreo', search, pagination);
    return this.http.get(url, { headers });
  }
  getProyectoMonitoreo(proyectoId, monitoreoId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosMonitoreo/' + monitoreoId;
    return this.http.get(url, { headers });
  }
  actualizarProyectoMonitoreo(proyectoId, monitoreoId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosMonitoreo/' + monitoreoId;
    return this.http.put(url, json, { headers });
  }
  crearProyectoMonitoreo(token, proyectoId, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosMonitoreo';
    return this.http.post(url, json, { headers });
  }
  borrarProyectoMonitoreo(proyectoId, monitoreoId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosMonitoreo/' + monitoreoId;
    return this.http.delete(url, { headers });
  }
}
