import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class TallerParticipantesService {

  constructor(private http: HttpClient, private global: Globals) { }

  getTallerParticipantes(token, tallerId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/gruposTalleres/' + tallerId + '/gruposTalleresMiembros', search, pagination);
    return this.http.get(url, { headers });
  }
  getTallerParticipante(tallerId, participanteId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleres/' + tallerId + '/gruposTalleresMiembros/' + participanteId;
    return this.http.get(url, { headers });
  }
  actualizarTallerParticipante(tallerId, participanteId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleres/' + tallerId + '/gruposTalleresMiembros/' + participanteId;
    return this.http.put(url, json, { headers });
  }
  crearTallerParticipante(token, tallerId, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleres/' + tallerId + '/gruposTalleresMiembros';
    return this.http.post(url, json, { headers });
  }
  borrarTallerParticipante(tallerId, participanteId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleres/' + tallerId + '/gruposTalleresMiembros/' + participanteId;
    return this.http.delete(url, { headers });
  }

  importarTalleresParticipantes(tallerId, formData) {
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleres/' + tallerId + '/gruposTalleresMiembrosExcel';
    return this.http.post(url, formData);
  }
}
