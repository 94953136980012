import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GrupoAhorroService } from './../../services/grupo-ahorro.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-editar-grupo-ahorro',
  templateUrl: './editar-grupo-ahorro.component.html',
  styleUrls: ['./editar-grupo-ahorro.component.sass']
})
export class EditarGrupoAhorroComponent implements OnInit {

  editable = true;
  process: boolean;
  cargando: boolean;
  proyectoId: number;
  grupoAhorroId: number;
  grupoAhorro: any;
  grupoAhorroForm: FormGroup;

  constructor(
    private matDialogRef: MatDialogRef<EditarGrupoAhorroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private errorService: ErrorService,
    private grupoAhorroService: GrupoAhorroService,
    private toastr: ToastrService,
    private fB: FormBuilder,
    private fS: FormStatusService
  ) { }

  ngOnInit() {
    this.proyectoId = this.data.proyectoId;
    this.grupoAhorroId = this.data.grupoAhorroId;
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.grupoAhorroForm = this.fB.group({
      nombre: [{ value: null, disabled: this.editable }, Validators.required],
      codigo: [{ value: null, disabled: this.editable }, Validators.required],
      fecha_apertura: [{ value: null, disabled: this.editable }, Validators.required],
      fecha_cierre: [{ value: null, disabled: this.editable }],
    });
  }

  get() {
    this.cargando = true;
    this.grupoAhorroService.getGrupoAhorro(null, this.proyectoId, this.grupoAhorroId)
      .subscribe((response: any) => {
        if (response.success) {
          this.grupoAhorro = response.data;
          this.grupoAhorroForm.patchValue(this.grupoAhorro);
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }

  update() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.grupoAhorroForm);
    this.grupoAhorroService.editarGrupoAhorro(null, this.proyectoId, this.grupoAhorroId, jsonData)
      .subscribe((response: any) => {
        if (response.success) {
          this.toastr.success('El grupo de ahorro ha sido actualizado correctamente.', 'Editar Grupo de Ahorro');
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.matDialogRef.close(true);
      }).add(() => {
        this.process = false;
      });
  }

  editableSwitcher() {
    this.grupoAhorroForm = this.fS.cambiarEstado(this.grupoAhorroForm, this.editable);
    this.editable = !this.editable;
  }

  close() {
    this.matDialogRef.close(false);
  }

}
