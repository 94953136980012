import { NumberFormatPipe } from './../../../../../util/pipes/numberFormat.pipe';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorService } from '../../../../../util/services/error.service';
import { CombosService } from '../../../../../util/services/combos/combos.service';
import { LocalStorageService } from '../../../../../util/local-storage.service';
import { GrupoAhorroService } from '../services/grupo-ahorro.service';
import { ActorResponse } from '../../../actores-proyecto/interfaces/actor-response.interface';
import { GrupoAhorroResponse } from '../interfaces/grupo-ahorro-response.interface';
import { Combo } from '../../../../../util/interfaces/combo';
import { CombosResponse } from '../../../../../util/interfaces/combos-response';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';

@Component({
  selector: 'app-crear-grupo',
  templateUrl: './crear-grupo.component.html',
  styleUrls: ['./crear-grupo.component.sass'],
  providers: [NumberFormatPipe]
})
export class CrearGrupoComponent implements OnInit {
  idProyecto: number;
  private token;
  comunidades: Combo[];
  grupoAhorroForm: FormGroup;
  items;
  crear_ciclo: boolean;
  test: boolean;

  private valorSocial: number;
  private valorPrestamo: number;
  private valorMulta: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private gruposAhorroService: GrupoAhorroService,
    private toastr: ToastrService,
    private fB: FormBuilder,
    private errorService: ErrorService,
    private comboService: CombosService,
    private localStorage: LocalStorageService,
    private fS: FormStatusService,
    private numberPipe: NumberFormatPipe
  ) {
    this.token = this.localStorage.getTokenFromStorage();

  }
  ngOnInit() {
    this.makeForm();
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
  }

  makeForm() {

    this.grupoAhorroForm = this.fB.group({
      nombre: [null, Validators.required],
      //codigo: [null, Validators.required],
      fecha_apertura: [null],
      // fecha_cierre: [null],
      //cantidad_miembros: [null, Validators.required],
      //ciclo_activo: [null, Validators.required],
      //monto_fondo_social: [null, Validators.required],
      // gru_grupos_ciclos: this.fB.group({
      // nombre: [null, Validators.required],

      // })
      // here
      aporte_fondo_social: [null],
      aporte_fondo_prestamo: [null],
      valor_multa: [null],
      limite_acciones: [null],
      nombre_presidenta: [null],
      apellido_presidenta: [null],
      telefono_presidenta: [null],
      nombre_registradora: [null],
      apellido_registradora: [null],
      telefono_registradora: [null],
      nro_ciclo: [{ value: null, disabled: true }],
      periodicidad_reuniones: [null],
      periodicidad_ciclo: [null],
      fecha_apertura_ciclo: [null],
      fecha_cierre_ciclo: [null],
      cantidad_miembros: [{ value: null, disabled: true }],
      monto_acumulado_social: [{ value: null, disabled: true }],
      notas_adicionales: [null],
      estado_pago_kit: [null],
      observaciones_pago: [null],
      estado: [{ value: null, disabled: true }],
      gru_grupos_ciclos_dias: this.fB.array([this.createItem()]),
    });

  }


  crearGrupoAhorro() {
    this.getRealValues();
    const jsonData = this.fS.getJsonFromForm(this.grupoAhorroForm);
    this.gruposAhorroService.crearGrupoAhorro(this.token, jsonData, this.idProyecto)
      .subscribe((respuesta: GrupoAhorroResponse) => {
        this.toastr.success('Grupo creado exitosamente', 'Creación de Grupo');
        this.volver();
      },
        error => {
          this.errorService.handleError(error);
        }).add(() => { });
  }

  getRealValues() {
    if (this.valorMulta) {
      this.grupoAhorroForm.get('valor_multa').setValue(this.valorMulta);
    }
    if (this.valorSocial) {
      this.grupoAhorroForm.get('aporte_fondo_social').setValue(this.valorSocial);
    }
    if (this.valorPrestamo) {
      this.grupoAhorroForm.get('aporte_fondo_prestamo').setValue(this.valorPrestamo);
    }
  }

  patchValues() {
    this.grupoAhorroForm.get('ciclos.nro_ciclo').patchValue('1');
  }

  createItem(): FormGroup {
    return this.fB.group({
      dia: [null, Validators.required],
      hora: [null, Validators.required],
    });
  }

  deleteItem(index: number) {
    this.items = this.grupoAhorroForm.get('gru_grupos_ciclos_dias') as FormArray;
    this.items.removeAt(index);
  }

  addItem(): void {
    this.items = this.grupoAhorroForm.get('gru_grupos_ciclos_dias') as FormArray;
    this.items.push(this.createItem());
  }

  private getComunidadesCombo() {
    this.comboService.getComunidadesCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.comunidades = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => { });
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  checkValues(e: any) {
    this.crear_ciclo = e.checked;
  }

  getControls() {
    return (this.grupoAhorroForm.get('gru_grupos_ciclos_dias') as FormArray).controls;
  }

  numberSeparator(event, controlName) {
    const realValue = +event.target.value;
    switch (controlName) {
      case 'aporte_fondo_social':
        this.valorSocial = realValue;
        break;
      case 'aporte_fondo_prestamo':
        this.valorPrestamo = realValue;
        break;
      case 'valor_multa':
        this.valorMulta = realValue;
        break;
    }
    this.grupoAhorroForm.get(controlName).setValue(this.numberPipe.transform(realValue));
  }

  setFechaAperturaCiclo(event) {
    const value = event.target.value;
    this.grupoAhorroForm.get('fecha_apertura_ciclo').setValue(value); 
  }

}
