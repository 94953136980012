import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { Combo } from 'src/app/util/interfaces/combo';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';

@Component({
  selector: 'app-crear-respuesta-predeterminada',
  templateUrl: './crear-respuesta-predeterminada.component.html',
  styleUrls: ['./crear-respuesta-predeterminada.component.sass']
})
export class CrearRespuestaPredeterminadaComponent implements OnInit {

  public nuevaRespuestaPredeterminada: FormGroup;
  process: boolean;
  tiposPregunta: Combo[];
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private localStorage: LocalStorageService,
    private fS: FormStatusService, private toastr: ToastrService, private errorService: ErrorService, private comboService: CombosService) { }

  ngOnInit() {
    this.makeForm();
    this.getTiposPregunta();
  }

  makeForm() {
    this.nuevaRespuestaPredeterminada = this.fB.group({
      nombre: [null, Validators.required],
      codigo: [null, Validators.required],
      descripcion: [undefined]
    });
  }

  crear() {
    // Implements CREATE here
  }

  getTiposPregunta() {
    this.comboService.getTiposPregunta().subscribe((respuesta: CombosResponse) => {
      this.tiposPregunta = respuesta.data;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
