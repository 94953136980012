import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigDataSliderComponent } from './config-data-slider.component';
import {MatDividerModule} from '@angular/material/divider';
import {AppPipesModule} from '../pipes/app-pipes.module';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    ConfigDataSliderComponent
  ],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    MatDividerModule,
    AppPipesModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule
  ], exports : [
    ConfigDataSliderComponent
  ]
})
export class ConfigDataSliderModule { }
