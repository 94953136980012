import { DatePipe } from '@angular/common';
import { ReunionesCrudService } from './../../detalle-ciclos/reuniones-crud/reuniones-crud.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { GrupoAhorroService } from '../../../../services/grupo-ahorro.service';


@Component({
  selector: 'app-crear-reunion',
  templateUrl: './crear-reunion.component.html',
  styleUrls: ['./crear-reunion.component.sass'],
  providers: [DatePipe]
})
export class CrearReunionComponent implements OnInit {

  private token;
  idProyecto: number;
  idGrupo: number;
  idCiclo: number;
  idGrupoCicloMiembro: number;
  cargando: boolean;
  process: boolean;
  nuevaReunionForm: FormGroup;
  reuniones;
  public displayedColumns = ['name', 'asistencia', 'aporte', 'update'];
  valorFormulario;
  idReunion;
  date = new Date();

  constructor(
    private gruposAhorroService: GrupoAhorroService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private fs: FormStatusService,
    private reunionesCrudService: ReunionesCrudService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.token = this.localStorage.getTokenFromStorage();
    this.nuevaReunionForm = fB.group({
      fecha: [this.datePipe.transform(this.date, 'yyyy-MM-dd'), Validators.required],
      hora: [null, Validators.required],
      /*monto_fondo_social: [null],
      monto_fondo_prestamo: [null],
      cantidad_acciones: [null],
      miembros_participantes: this.fB.array([
        fB.group({
          miembro: [null],
          asistencia: [null],
          aporte_fondo_social: [null],
          comentarios: [null],
        })
    ])*/
  }); 
  }


  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    this.idCiclo = +this.route.snapshot.paramMap.get('idCiclo');
    this.getReuniones();
  }

  createItem(): FormGroup {
    return this.fB.group({
      miembro: [null],
      presente: [null],
      monto_fondo_social: [null],
      monto_fondo_prestamo: [null],
      cantidad_acciones: [null],
    });
  }

  crearReunion() {
    const jsonData = this.fs.getJsonFromForm(this.nuevaReunionForm);
    console.log(jsonData);
    this.gruposAhorroService.crearReuniones(this.token, this.idProyecto, this.idGrupo, this.idCiclo, jsonData)
      .subscribe((respuesta: any) => {
          this.toastr.success('Reunión creada exitosamente', 'Creación de Reunión');
          this.idReunion = respuesta.data.id;
        },
        error => {
          this.errorService.handleError(error);
        }).add(() => {
          this.redirectDetalle(this.idReunion);
        });
  } 

  redirectDetalle(id: number) {
    this.router.navigate(['../../reuniones', id], { relativeTo: this.route });
  }

  getReuniones(){
    this.reunionesCrudService.getDiasReunion(this.idCiclo)
      .subscribe( (res:any) => {
        this.reuniones = res.data;
      });
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  volverGrupo() {
    this.router.navigate(['../../../../../'], { relativeTo: this.route });
  }

  volverCiclos() {
    this.router.navigate(['../../../../'], { relativeTo: this.route });
  }

  logData(row) {
    this.valorFormulario = row;
  }

  editUser(usuario){
    this.toastr.success('Se registraron los datos del miembro en la reunión')
  }

}

