import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../../../../Globals';

@Injectable({
  providedIn: 'root'
})
export class CrudUsuariosMovilesService {

  constructor(
    private http: HttpClient,
    private global: Globals
  ) { }
  getList(idProyecto, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/usuariosMoviles', search, pagination);
    return this.http.get(url, {headers});
  }

  create(idProyecto, jsonData, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/usuariosMoviles', search, pagination);
    return this.http.post(url, jsonData, {headers});
  }

  delete(idProyecto, id, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/usuariosMoviles/' + id, search, pagination);
    return this.http.delete(url, {headers});
  }
}
