import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsuariosServices} from '../../Administracion/usuarios/services/usuarios.services';
import {LocalStorageService} from '../../../util/local-storage.service';
import {User} from '../../../auth/user';
import {UsuarioPerfilResponse} from '../../Administracion/usuarios/interfaces/usuarios-response.interface';
import {UsuarioPerfil} from '../../Administracion/usuarios/interfaces/usuarios.interface';
import {ErrorService} from '../../../util/services/error.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormStatusService} from '../../../util/services/form-status/form-status.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.sass']
})
export class PerfilComponent implements OnInit {
  private token;
  usuario: User;

  perfil: UsuarioPerfil;
  public usuarioForm: FormGroup;
  public disabled = true;

  noEditable;

  constructor(
    private router: Router,
    private usuariosService: UsuariosServices,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private formStatus: FormStatusService,
    private errorService: ErrorService,
    private toastr: ToastrService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.noEditable = ['active', 'acceso_web', 'acceso_app'];
    this.token = this.localStorageService.getTokenFromStorage();
    this.usuario = this.localStorageService.getUserFromStorage();
    this.loadForm();
    this.getPerfil();
  }

  loadForm() {
    this.usuarioForm = this.formBuilder.group({
      adm_persona_id: {value: '', disabled: this.disabled},
      active: {value: '', disabled: this.disabled},
      name: [{value: '', disabled: this.disabled}, [Validators.maxLength(8)]],
      email: [{value: '', disabled: this.disabled}, [Validators.email]],
      acceso_web: {value: '', disabled: this.disabled},
      acceso_app: {value: '', disabled: this.disabled},
      password: {value: '', disabled: this.disabled},
      password_rep: {value: '', disabled: this.disabled},
      foto_usuario: {value: '', disabled: this.disabled}
    });
  }

  private getPerfil() {
    this.usuariosService.getUsuario(this.usuario.userData.user_id)
      .subscribe((respuesta: UsuarioPerfilResponse) => {
          this.perfil = respuesta.data;
          this.usuarioForm.patchValue(respuesta.data);

        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
    });
  }


  validar() {
    if (!this.usuarioForm.value.password && !this.usuarioForm.value.password_rep) {
      this.usuarioForm.controls.password.disable();
      this.usuarioForm.controls.password_rep.disable();
      this.updateUsuario();
    } else {
      if (this.usuarioForm.value.password === this.usuarioForm.value.password_rep) {
        this.updateUsuario();
      } else {
        this.toastr.error('Las contraseñas no coinciden. Ingrese las contraseñas de nuevo', 'Creación de Usuario');
      }
    }
  }

  volver() {
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  public editableSwitcher() {
    this.usuarioForm = this.formStatus.cambiarEstado(this.usuarioForm, this.disabled, this.noEditable, true);
    this.disabled = !this.disabled;
  }


  updateUsuario() {
    this.usuarioForm.controls.password.enable();
    this.usuarioForm.controls.password_rep.enable();
    const jsonData = this.formStatus.getJsonFromForm(this.usuarioForm);
    this.usuariosService.editarUsuario(this.usuario.userData.user_id, jsonData)
      .subscribe((respuesta: UsuarioPerfilResponse) => {
          this.perfil = respuesta.data;
          if (respuesta.success) {
            this.toastr.success('Usuario editado exitosamente', 'Edición de Usuario');
            this.editableSwitcher();
            this.getPerfil();
          } else {
            this.toastr.error('Error al editar usuario', 'Edición de Usuario');
          }
        }, error => {
          this.errorService.handleError(error);
        }
      );
  }

}
