import { InversoresService } from './../../../../parametricos-proyecto/inversores/services/inversores.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ConfigProyectoInversoresService } from '../services/config-proyecto-inversores.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';

export interface DialogData {
  proyectoId: number;
}

@Component({
  selector: 'app-crear-config-proyecto-inversores',
  templateUrl: './crear-config-proyecto-inversores.component.html',
  styleUrls: ['./crear-config-proyecto-inversores.component.sass']
})
export class CrearConfigProyectoInversoresComponent implements OnInit {

  public nuevoInversor: FormGroup;
  private proyectoId: number;
  inversores: Combo[];
  process: boolean;
  constructor(public dialogRef: MatDialogRef<CrearConfigProyectoInversoresComponent>, private fB: FormBuilder,
              private configProyectoInversoresService: ConfigProyectoInversoresService, private fS: FormStatusService, private toastr: ToastrService, private comboService: CombosService,
              private errorService: ErrorService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private inversoresService: InversoresService) { }

  ngOnInit() {
    this.proyectoId = this.data.proyectoId;
    this.makeForm();
    this.getInversoresCombo();
  }

  makeForm() {
    this.nuevoInversor = this.fB.group({
      pro_inversor_id: [null, Validators.required],
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoInversor);
    this.configProyectoInversoresService.crearInversor(this.proyectoId, jsonData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success(' Aliado/Actor Institucional creado exitosamente', 'Crear Aliad/Actor Institucional');
          this.dialogRef.close('created');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoInversor.enable();
      }).add(() => { this.process = false; });
  }

  // getInversoresCombo() {
  //   this.comboService.getInversorescombo()
  //     .subscribe((respuesta: CombosResponse) => {
  //       this. = respuesta.data;
  //     }, error => {
  //       this.errorService.handleError(error);
  //     }
  //     ).add(() => {
  //     });
  // }

   getInversoresCombo() {
    this.inversoresService.obtenerInversores()
      .subscribe((respuesta: CombosResponse) => {
        this.inversores = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

  volver() {
    this.dialogRef.close();
  }

}
