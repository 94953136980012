import { ProyectoRolService } from './../services/proyecto-rol.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ConfigProyectoGestionResponse } from '../../config-proyecto-gestion/interfaces/config-proyecto-gestion-response';
import { HttpErrorResponse } from '@angular/common/http';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';

export interface DialogData {
  proyectoId: number;
}
@Component({
  selector: 'app-crear-proyecto-rol',
  templateUrl: './crear-proyecto-rol.component.html',
  styleUrls: ['./crear-proyecto-rol.component.sass']
})
export class CrearProyectoRolComponent implements OnInit {

  public nuevoRol: FormGroup;
  private proyectoId: number;
  private token;
  rolesActores: Combo[];
  process: boolean;
  constructor(public dialogRef: MatDialogRef<CrearProyectoRolComponent>, private fB: FormBuilder,
    private proyectoRolService: ProyectoRolService, private fS: FormStatusService, private toastr: ToastrService, private comboService: CombosService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.proyectoId = this.data.proyectoId;
    this.makeForm();
    this.getActoresRoles();
  }

  makeForm() {
    this.nuevoRol = this.fB.group({
      pro_actor_rol_id: [null, Validators.required],
      activo: [null, Validators.required],
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoRol);
    this.proyectoRolService.crearProyectoRol(this.token, this.proyectoId, jsonData)
      .subscribe((respuesta: ConfigProyectoGestionResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto Gestión creado exitosamente', 'Crear Proyecto Gestión');
          this.dialogRef.close('created');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoRol.enable();
      }).add(() => { this.process = false; });
  }

  getActoresRoles() {
    this.comboService.getRolActoresCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.rolesActores = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

  volver() {
    this.dialogRef.close();
  }

}
