import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-swal-component',
  templateUrl: './swal-component.component.html',
  styleUrls: ['./swal-component.component.sass']
})
export class SwalComponentComponent implements OnInit {
  @Output() clickDeleteEvent = new EventEmitter();
  @Input() isDeleted: boolean;
  @Input() texto: string;
  @Input() disabled: boolean;

  constructor(private readonly sweetAlert2Loader: SweetAlert2LoaderService) { }

  ngOnInit() {
  }

  opensweetalertcst() {
    const text = '<b>' + this.texto + '</b>';
    this.sweetAlert2Loader.swal.then((swal) =>
      swal.fire({
        html: text,
        imageUrl: './assets/images/trash-can.png',
        imageWidth: 50,
        imageHeight: 50,
        showCancelButton: true,
        confirmButtonText: 'Sí, borrar!',
        confirmButtonColor: '#FF0000',
        cancelButtonText: 'No, cancelar',
        focusCancel: true,
        width: 400
      }).then((result) => {
        if (result.value) {
          this.clickDeleteEvent.emit(true);
          // if (this.isDeleted) {
          //   Swal.fire({
          //     title: 'Borrado!',
          //     text: 'Borrado exitosamente',
          //     type: 'success'
          //     }
          //   );
          // }
        }
        // else if (result.dismiss === Swal.DismissReason.cancel) {
        //   Swal.fire(
        //     'Cancelled',
        //     'Your imaginary file is safe :)',
        //     'error'
        //   );
        // }
      })
    )
  }


}
