import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class SubgrupoInstanciaService {

  constructor(private http: HttpClient, private global: Globals) { }

  getSubgrupos(tallerId, instanciaId, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url: string = this.global.makeUrl(`/gruposTalleres/${tallerId}/instanciasTalleres/${instanciaId}/subgrupos`, search, pagination);
    return this.http.get(url, { headers });
  }
  getSubgrupoMiembro(tallerId, instanciaId, miembroId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/gruposTalleres/${tallerId}/instanciasTalleres/${instanciaId}/subgrupos/${miembroId}`;
    return this.http.get(url, { headers });
  }
  actualizarSubgrupoMiembro(tallerId, instanciaId, miembroId, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/gruposTalleres/${tallerId}/instanciasTalleres/${instanciaId}/subgrupos/${miembroId}`;
    return this.http.put(url, json, { headers });
  }
  crearSubgrupoMiembro(tallerId, instanciaId, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/gruposTalleres/${tallerId}/instanciasTalleres/${instanciaId}/subgrupos`;
    return this.http.post(url, json, { headers });
  }
  borrarSubgrupoMiembro(tallerId, instanciaId, miembroId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/gruposTalleres/${tallerId}/instanciasTalleres/${instanciaId}/subgrupos/${miembroId}`;
    return this.http.delete(url, { headers });
  }
}
