import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TallerParticipante} from './interfaces/taller-participante.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {TallerParticipanteService} from './services/taller-participante.service';
import {TalleresParticipantesResponse, TallerParticipanteResponse} from './interfaces/taller-participante-response.interface';
import {ErrorService} from '../../../util/services/error.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
  selector: 'app-talleres-participantes',
  templateUrl: './talleres-participantes.component.html',
  styleUrls: ['./talleres-participantes.component.sass']
})
export class TalleresParticipantesComponent implements OnInit {
  heading = 'Talleres participantes';
  subheading = 'En este módulo se encuentra la lista de talleres participantes.';
  icon = 'pe-7s-study icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'orden', 'descripcion', 'acciones'];

  talleresParticipante: TallerParticipante[];
  searchForm: FormGroup;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private fB: FormBuilder,
              private tallerParticipanteService: TallerParticipanteService,
              private errorService: ErrorService,
              private toastr: ToastrService,
              private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.makeSearchForm();
    this.getTalleresParticipante();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getTalleresParticipante() {
    this.cargando = true;
    this.tallerParticipanteService.getTalleresParticipantes(this.searchForm.value, this.pagination)
    .subscribe( (respuesta: TalleresParticipantesResponse) => {
      this.talleresParticipante = respuesta.data;
      this.total = respuesta.metadata.total;
    }, error => {
      this.errorService.handleError(error);
    }
    ).add(() => {
      this.cargando = false;
    });
  }

  crear() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_talleres_participantes/get512').then(result => {
      if (result) {
        this.router.navigate(['./', id], { relativeTo: this.route });
      }
    });
  }

  borrar(id: number) {
    this.tallerParticipanteService.borrarTallerParticipante(id)
      .subscribe((respuesta: TallerParticipanteResponse) => {
        if (respuesta.success) {
          this.toastr.success('Taller participante borrado exitosamente', 'Borrar Taller Participante');
        }
        this.getTalleresParticipante();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();

  }

  filtrar() {
    this.pagination.page = 1;
    this.getTalleresParticipante();
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getTalleresParticipante();
  }

  pageChange() {
    this.getTalleresParticipante();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
