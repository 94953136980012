import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';

@Component({
  selector: 'app-registro-pagos-miembro',
  templateUrl: './registro-pagos-miembro.component.html',
  styleUrls: ['./registro-pagos-miembro.component.sass']
})
export class RegistroPagosMiembroComponent implements OnInit {

  public miembro;
  idGrupoAhorrador;
  detalleForm: FormGroup;
  editable = true;
  cargando: boolean;
  process: boolean;
  valorSelect = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fB: FormBuilder,
    private fS: FormStatusService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<RegistroPagosMiembroComponent>
  ) {

  }

  ngOnInit(): void {
    this.idGrupoAhorrador = this.route.snapshot.paramMap.get('idGrupo');
    this.makeForm();
    //this.get();
  }

  makeForm() {
    this.detalleForm = this.fB.group({
      cantidad_acciones: [null, Validators.required],
      fecha_pago: [null, Validators.required],
      descripcion: null,
    });
  }

  get() {
    this.miembro = { id: 1,
      nombre: 'Jorge',
      apellido: 'Ruiz Diaz',
      descripcion: 'La persona se unio al grupo en condicion de ahorrador',
    };
    this.detalleForm.patchValue(this.miembro);
  }

  actualizar() {
    this.process = true;
    this.toastr.success('Se registro el pago correctamente', 'Registro de pagos');
    //this.get();
    this.editableSwitcher();
    this.dialogRef.close();
  }

  public editableSwitcher() {
    this.detalleForm = this.fS.cambiarEstado(this.detalleForm, this.editable);
    this.editable = !this.editable;
  }

  volver() {
    this.dialogRef.close();
    // this.router.navigate(['../../'], { relativeTo: this.route });
  }

    evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  selectChangeHandler (event: any) {
    this.valorSelect = event.target.value;
  }

}
