import { CombosService } from 'src/app/util/services/combos/combos.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { TallerGestionProyectoResponse } from './../interfaces/taller-gestion-proyecto-response';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { TallerGestionProyectoService } from '../services/taller-gestion-proyecto.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { TallerTipoResponse } from 'src/app/Pages/parametricos-proyecto/talleres-tipos/interfaces/taller-tipo-response';
import { TallerGestionProyecto } from '../interfaces/taller-gestion-proyecto';
import { ComboActoresResponse } from 'src/app/util/interfaces/combos-response';

@Component({
  selector: 'app-detalle-taller-gestion-proyecto',
  templateUrl: './detalle-taller-gestion-proyecto.component.html',
  styleUrls: ['./detalle-taller-gestion-proyecto.component.sass']
})
export class DetalleTallerGestionProyectoComponent implements OnInit {


  public tallerForm: FormGroup;
  public personaForm: FormGroup;
  cargando: boolean;
  editable = true;
  process: boolean;
  idProyecto: number;
  idTaller: number;
  taller: TallerGestionProyecto;
  private token;
  editandoAsistencia = false;
  tipoTaller: string;
  constructor(
      private fB: FormBuilder, private router: Router, private route: ActivatedRoute,
      private tallerGestionProyectoService: TallerGestionProyectoService,
      private localStorage: LocalStorageService,
      private fS: FormStatusService, private toastr: ToastrService,
      private errorService: ErrorService, private permissionService: NgxPermissionsService,
      private comboService: CombosService
  ) { }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idTaller = +this.route.snapshot.paramMap.get('idTaller');
    if (this.route.snapshot.data.extraParameter === 'facilitadores') {
      this.tipoTaller = 'F';
    } else {
      this.tipoTaller = 'P';
    }
    console.log(this.route.snapshot.data.extraParameter);
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.tallerForm = this.fB.group({
      // pro_grupo_taller_tipo_id: [{ value: undefined, disabled: this.editable }, Validators.required],
      nombre: [{ value: undefined, disabled: this.editable }, Validators.required],
      codigo: [{ value: undefined, disabled: this.editable }, Validators.required],
      descripcion: [{ value: undefined, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.tallerGestionProyectoService.getProyectoTaller(this.idProyecto, this.idTaller, this.token)
      .subscribe((respuesta: TallerGestionProyectoResponse) => {
        this.taller = respuesta.data;
        this.tallerForm.patchValue(this.taller);
      }, error => {
        this.errorService.handleError(error);
      }, () => {}
      ).add(() => {
        this.cargando = false;
      });
  }

  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.tallerForm);
    this.tallerGestionProyectoService.actualizarProyectoTaller(this.idProyecto, this.idTaller, this.token, jsonData)
      .subscribe((respuesta: TallerTipoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Taller actualizado correctamente', 'Actualizar Taller');
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }, ()=> {
        this.get();
      }).add(() => { this.process = false; });
  }
  public editableSwitcher() {
    this.tallerForm = this.fS.cambiarEstado(this.tallerForm, this.editable);
    this.editable = !this.editable;
  }
  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  volverProyecto() {
    this.router.navigate(['../../../'], { relativeTo: this.route });
  }

}
