import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-working-on-it',
  templateUrl: './working-on-it.component.html',
  styleUrls: ['./working-on-it.component.sass']
})
export class WorkingOnItComponent implements OnInit {

  public lottieConfig = {
    path: 'assets/lottie/gear.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };
  @ViewChild('anim', {static: true}) animation;
  constructor() { }

  ngOnInit() {
  }

}
