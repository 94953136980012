import { Globals } from './../../../../../../Globals';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OpcionesPreguntaService {

  constructor(private http: HttpClient, private global: Globals) { }

  obtenerOpciones(preguntaId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntas/' + preguntaId + '/opciones';
    return this.http.get(url, { headers });
  }

  obtenerOpcion(preguntaId, opcionId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntas/' + preguntaId + '/opciones/' + opcionId;
    return this.http.get(url, { headers });
  }

  crearOpcion(preguntaId, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntas/' + preguntaId + '/opciones';
    return this.http.post(url, jsonData, { headers });
  }

  borrarOpcion(preguntaId, opcionId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntas/' + preguntaId + '/opciones/' + opcionId;
    return this.http.delete(url, { headers });
  }

  editarOpcion(preguntaId, opcionId, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntas/' + preguntaId + '/opciones/' + opcionId;
    return this.http.put(url, jsonData, { headers });
  }
}
