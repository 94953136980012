import { ComboComponentesResponse } from 'src/app/util/interfaces/combos-response';
import { ComboComponentes } from 'src/app/util/interfaces/combo';
import { DetalleConfiguracionProyectoComponent } from './detalle-configuracion-proyecto/detalle-configuracion-proyecto.component';
import { CrearConfiguracionProyectoComponent } from './crear-configuracion-proyecto/crear-configuracion-proyecto.component';
import { ConfiguracionesProyectoService } from './services/configuraciones-proyecto.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ConfiguracionesProyectoResponse, ConfiguracionProyectoResponse } from './interfaces/configuracion-proyecto-response';
import { ConfiguracionProyecto } from './interfaces/configuracion-proyecto';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ConfigProyectoGestionResponse } from '../config-proyecto-gestion/interfaces/config-proyecto-gestion-response';

@Component({
  selector: 'app-configuraciones-proyecto',
  templateUrl: './configuraciones-proyecto.component.html',
  styleUrls: ['./configuraciones-proyecto.component.sass']
})
export class ConfiguracionesProyectoComponent implements OnInit {

  @Input() proyectoId: number;
  cargando: boolean;
  process: boolean;
  private token;
  configuraciones: ConfiguracionProyecto[];
  configuracionesCombo: ComboComponentes[];
  public configuracionForm: FormGroup;
  displayedColumns = ['nombre', 'codigo', 'tipo', 'valor', 'acciones'];
  selected = null;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private fS: FormStatusService,
    private configuracionProyectoService: ConfiguracionesProyectoService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private comboService: CombosService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.getConfiguraciones();
  }

  makeForm() {
    this.configuracionForm = this.fB.group({
      pro_configuracion_id: [null, Validators.required],
    });
  }

  getConfiguraciones() {
    this.cargando = true;
    this.configuracionProyectoService.getConfiguraciones(this.token, this.proyectoId)
      .subscribe((respuesta: ConfiguracionesProyectoResponse) => {
        this.configuraciones = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.getConfiguracionesCombo();
      });
  }

  getConfiguracionesCombo() {
    this.comboService.getConfiguracionesCombo(this.token)
      .subscribe((respuesta: ComboComponentesResponse) => {
        this.configuracionesCombo = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => { this.verificarActivos(); });
  }

  crear(value: ConfiguracionProyecto) {
    this.process = true;
    this.configuracionForm.get('pro_configuracion_id').setValue(value.id);
    const jsonData = this.fS.getJsonFromForm(this.configuracionForm);
    this.configuracionProyectoService.crearConfiguracion(this.token, this.proyectoId, jsonData)
      .subscribe((respuesta: ConfiguracionProyectoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Configuración creada exitosamente', 'Crear Configuración');
          this.getConfiguraciones();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.configuracionForm.enable();
      }).add(() => { this.process = false; });
  }

  // verDetalle(id: number) {
  //   const dialogRef = this.dialog.open(DetalleConfiguracionProyectoComponent, {
  //     width: '700px',
  //     data: { proyectoId: this.proyectoId, configuracionId: id }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result === 'updated') {
  //       this.getConfiguraciones();
  //     }
  //   });
  // }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  borrar(id: number) {
    this.configuracionProyectoService.borrarConfiguracion(this.proyectoId, id, this.token)
      .subscribe((respuesta: ConfiguracionProyectoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Configuración borrada exitosamente', 'Borrar Configuración');
        }
        this.getConfiguraciones();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  verificarActivos() {
    this.configuracionesCombo.forEach(configuracionCombo => {
      this.configuraciones.forEach(configuracion => {
        if (configuracionCombo.id === configuracion.pro_configuracion_id) {
          configuracionCombo.activo = true;
          configuracionCombo.descripcion = 'Tipo: ' + configuracion.configuracion_tipo + ' Valor: ' + configuracion.configuracion_valor;
          configuracionCombo.relacion_id = configuracion.id;
        }
      });
    });
    this.cargando = false;
  }

}
