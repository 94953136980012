import { ErrorService } from './../../../../../util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { FormacionDigitalService } from './../services/formacion-digital.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-apptitude-report',
  templateUrl: './apptitude-report.component.html',
  styleUrls: ['./apptitude-report.component.sass']
})
export class ApptitudeReportComponent implements OnInit {

  proyectoId: number;
  formacionDigitalId: number;
  apptitude: any;
  total: number;
  pagination = { page: 1, per_page: 10 };
  cargando: boolean;
  formacionDigital: any;
  columnas: string[] = [];
  constructor(private route: ActivatedRoute, private router: Router,
    private formacionDigitalService: FormacionDigitalService, private toastr: ToastrService,
    private errorService: ErrorService) { }

  ngOnInit() {
    this.proyectoId = +this.route.snapshot.paramMap.get('idProyecto');
    this.formacionDigitalId = +this.route.snapshot.paramMap.get('idFormDigi');
    this.getData();
  }

  getData() {
    this.cargando = true;
    this.formacionDigitalService.getFormacionDigital(this.proyectoId, this.formacionDigitalId)
      .subscribe((response: any) => {
        if (response.success) {
          this.formacionDigital = response.data;
        }
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.getColumnas();
      });
  }

  getColumnas() {
    const toObj = JSON.parse(this.formacionDigital.formaciones_digitales_detalles[0].datos);
    for (let key in toObj) {
      this.columnas.push(key);
    }
    this.getDatosParsed();
  }

  getDatosParsed() {
    this.formacionDigital.formaciones_digitales_detalles.forEach(element => {
      element.jsonParsed = JSON.parse(element.datos);
    });
    this.cargando = false;
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
