import { ReportesGrupoAhorroService } from './services/reportes-grupo-ahorro.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../../../util/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '../../../../util/services/error.service';
import { GrupoAhorroService } from './services/grupo-ahorro.service';
import { GrupoAhorroResponse, GruposAhorroResponse } from './interfaces/grupo-ahorro-response.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EditarGrupoAhorroComponent } from './detalle-grupo-gestion/editar-grupo-ahorro/editar-grupo-ahorro.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-grupo-ahorro-gestion-proyecto',
  templateUrl: './grupo-ahorro-gestion-proyecto.component.html',
  styleUrls: ['./grupo-ahorro-gestion-proyecto.component.sass']
})
export class GrupoAhorroGestionProyectoComponent implements OnInit {
  gruposAhorro;
  cargando: boolean;
  private token;
  idProyecto: number;
  total: number;
  pagination = { page: 1, per_page: 10 };
  public lottieConfig = {
    path: 'assets/lottie/empty-box.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };
  selected = null;
  displayedColumns = ['nombre', 'fechas', 'cantidad_miembros', 'ciclos', 'acciones'];
  public rolSearch: FormGroup;
  downloading: boolean;
  reporteData: any;
  proyectoData: any;

  constructor(
    private gruposAhorroService: GrupoAhorroService,
    private router: Router,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private reportesGrupoAhorroService: ReportesGrupoAhorroService
  ) {
    this.token = this.localStorage.getTokenFromStorage();
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.makeForm();
    this.getGruposAhorro();
  }

  makeForm() {
    this.rolSearch = this.formBuilder.group({
      nombre: null,
      codigo: null,
      fecha_apertura_ini: null,
      fecha_apertura_fin: null,
      estado: null
    });
  }

  private getGruposAhorro() {
    this.cargando = true;
    this.gruposAhorroService.getGruposAhorro(this.token, this.idProyecto, this.rolSearch.value, this.pagination)
      .subscribe((respuesta: GruposAhorroResponse) => {
        this.gruposAhorro = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  verDetalle(idProyecto: number) {
    this.permissionService.hasPermission('GRU/mant_grupos_ahorros/get1201').then((result) => {
      if (result) {
        this.router.navigate(['./', idProyecto], { relativeTo: this.route, state: {data: this.proyectoData} });
      }
    });
  }

  crearGrupoAhorro() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../../../../'], { relativeTo: this.route });
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getGruposAhorro();
  }

  onReset() {
    this.pagination = { page: 1, per_page: 10 };
    this.rolSearch.reset();
    this.getGruposAhorro();
  }

  pageChange() {
    this.getGruposAhorro();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type === 'mouseover' ? row.id : null;
  }

  borrarCampo(nombreControl: any) {
    this.rolSearch.controls[nombreControl].reset();
  }


  // borrar(id: any) {
  //   this.gruposAhorroService.borrarGrupoAhorro(this.token, this.idProyecto, id)
  //     .subscribe((respuesta: GrupoAhorroResponse) => {
  //       this.toastr.success('Grupo borrado exitosamente', 'Borrar Grupo');
  //       this.getGruposAhorro();
  //     }, (error: HttpErrorResponse) => {
  //       this.errorService.handleError(error);
  //     }, () => {
  //     });
  // }

  openModal(id) {
    this.matDialog.open(EditarGrupoAhorroComponent, {
      width: '700px',
      data: { proyectoId: this.idProyecto, grupoAhorroId: id }
    }).afterClosed().subscribe((response: any) => {
      if (response) {
        this.getGruposAhorro();
      }
    });
  }

  descargarReporte() {
    this.downloading = true;
    this.reportesGrupoAhorroService.getGruposAhorroReporte(this.idProyecto)
      .subscribe((response: any) => {
        this.reporteData = response.data;
      }, error => {
        this.errorService.handleError(error);
        this.downloading = false;
      }, () => {
        this.exportAsExcel();
      });
  }

  exportAsExcel() {
    const exportedData = [];
    const excelFileName =  'grupos-ahorro'
    this.reporteData.forEach(value => {
      const obj = {
        nombre: value.nombre,
        ciclo_activo: value.ciclo_activo,
        cantidad_miembros: (value.cantidad_miembros) ? value.cantidad_miembros : 'ND',
        monto_fondo_social: (value.monto_fondo_social) ? value.monto_fondo_social : '0',
        monto_fondo_prestamo: (value.monto_fondo_prestamo) ? value.monto_fondo_prestamo : '0',
        cantidad_acciones: (value.cantidad_acciones) ? value.cantidad_acciones : '0',
        monto_multas: (value.monto_multas) ? value.monto_multas : '0',
        cantidad_multas: (value.cantidad_multas) ? value.cantidad_multas : '0'
      }
      exportedData.push(obj);
    });
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(exportedData);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, `${excelFileName}.xlsx`);
    this.downloading = false;
  }

  setProyecto(event){
    this.proyectoData = event;
  }

}
