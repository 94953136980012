import { DetalleActorUbicacionComponent } from './../detalle-actor-ubicacion/detalle-actor-ubicacion.component';
import { ActorUbicacionService } from './../services/actor-ubicacion.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UbicacionDepartamento } from 'src/app/Pages/proyectos/editar-proyecto/config-proyecto-ubicacion/interfaces/config-proyecto-ubicacion';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-ubicacion-departamentos-actor',
  templateUrl: './ubicacion-departamentos-actor.component.html',
  styleUrls: ['./ubicacion-departamentos-actor.component.sass']
})
export class UbicacionDepartamentosActorComponent implements OnInit {

  @Input() departamentos: UbicacionDepartamento[];
  @Input() actorId: number;
  @Output() changed?: EventEmitter<boolean> = new EventEmitter<boolean>();
  displayedColumns = ['nombre', 'acciones'];
  selected = null;
  private token;

  constructor(private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private actorUbicacionService: ActorUbicacionService, public dialog: MatDialog) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  verDetalle(id: number) {
    const dialogRef = this.dialog.open(DetalleActorUbicacionComponent, {
      width: '700px',
      data: { actorId: this.actorId, ubicacionId: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'updated') {
        this.changed.emit(true);
      }
    });
  }

  borrar(id: number) {
    this.actorUbicacionService.borrarActorUbicacion(this.actorId, id, this.token)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Ubicación borrada exitosamente', 'Borrar Ubicación');
        }
        this.changed.emit(true);
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

}
