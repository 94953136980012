import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class DimensionTemaService {

  constructor(private http: HttpClient, private global: Globals) { }

  getDimensionTemas(token, dimensionId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/dimensiones/' + dimensionId + '/temas', search, pagination);
    return this.http.get(url, { headers });
  }
  getDimensionTema(dimensionId, id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/dimensiones/' + dimensionId + '/temas/' + id;
    return this.http.get(url, { headers });
  }
  actualizarDimensionTema(dimensionId, id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/dimensiones/' + dimensionId + '/temas/' + id;
    return this.http.put(url, json, { headers });
  }
  crearDimensionTema(dimensionId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/dimensiones/' + dimensionId + '/temas';
    return this.http.post(url, json, { headers });
  }
  borrarDimensionTema(dimensionId, id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/dimensiones/' + dimensionId + '/temas/' + id;
    return this.http.delete(url, { headers });
  }
}
