import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ComponenteMonitoreoService {
  constructor(private http: HttpClient, private global: Globals) { }

  getComponentesMonitoreo(token, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/componentesMonitoreo', search, pagination);
    return this.http.get(url, { headers });
  }
  getComponenteMonitoreo(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/componentesMonitoreo/' + id;
    return this.http.get(url, { headers });
  }
  actualizarComponenteMonitoreo(id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/componentesMonitoreo/' + id;
    return this.http.put(url, json, { headers });
  }
  crearComponenteMonitoreo(token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/componentesMonitoreo';
    return this.http.post(url, json, { headers });
  }
  borrarComponenteMonitoreo(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/componentesMonitoreo/' + id;
    return this.http.delete(url, { headers });
  }
}
