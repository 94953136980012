import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkingOnItComponent } from './working-on-it.component';
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [WorkingOnItComponent],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: [WorkingOnItComponent]
})
export class WorkingOnItModule { }
