import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crear-indicador-monitoreo-proyecto',
  templateUrl: './crear-indicador-monitoreo-proyecto.component.html',
  styleUrls: ['./crear-indicador-monitoreo-proyecto.component.sass']
})
export class CrearIndicadorMonitoreoProyectoComponent implements OnInit {

  public ocultarConfig = true;
  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
  }

  agregarProyecto() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
  cambiarEstadoConfig() {
    this.ocultarConfig = !this.ocultarConfig;
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
