/* tslint:disable:variable-name */
import { TallerGestionProyectoResponse } from './../interfaces/taller-gestion-proyecto-response';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { TallerGestionProyectoService } from '../services/taller-gestion-proyecto.service';
import { HttpErrorResponse } from '@angular/common/http';
import {CombosService} from '../../../../../util/services/combos/combos.service';

@Component({
  selector: 'app-crear-taller-gestion-proyecto',
  templateUrl: './crear-taller-gestion-proyecto.component.html',
  styleUrls: ['./crear-taller-gestion-proyecto.component.sass']
})
export class CrearTallerGestionProyectoComponent implements OnInit {

  public tallerForm: FormGroup;
  persona = new FormControl();
  private token;
  process: boolean;
  idProyecto: number;
  selectedTipo: any;
  facilitadoresSeleccionados: any[] = [];
  constructor(private modalService: NgbModal,
              private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private tallerGestionProyectoService: TallerGestionProyectoService, private localStorage: LocalStorageService,
              private fS: FormStatusService, private toastr: ToastrService, private errorService: ErrorService, private comboService: CombosService) { }

  ngOnInit() {
    this.route.data.subscribe(obj => {
      if (obj.extraParameter === 'participantes') {
        this.selectedTipo = 2;
      } else {
        this.selectedTipo = 1;
      }
    });
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
  }

  openLarge(content) {
    this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  makeForm() {
    this.tallerForm = this.fB.group({
      pro_grupo_taller_tipo_id: [{ value: this.selectedTipo, disabled: true }, Validators.required],
      // adm_comunidad_id: [undefined, Validators.required],
      nombre: [undefined, Validators.required],
      // fecha: [undefined],
      descripcion: [undefined],
      facilitadores: null
    });
  }

  crear() {
    this.process = true;
    this.tallerForm.get('pro_grupo_taller_tipo_id').enable();
    const jsonData = this.fS.getJsonFromForm(this.tallerForm);
    this.tallerGestionProyectoService.crearProyectoTaller(this.token, this.idProyecto, jsonData)
      .subscribe((respuesta: TallerGestionProyectoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Taller creado exitosamente', 'Crear Taller');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.tallerForm.get('nombre').enable();
        this.tallerForm.get('descripcion').enable();
        this.tallerForm.get('pro_grupo_taller_tipo_id').disable();
      }).add(() => { this.process = false; });
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  setFacilitadorValue(values) {
    const arr = [];
    values.forEach(value => {
      const obj = {pro_actor_id: value.pro_actor_id};
      arr.push(obj);
    });
    this.tallerForm.get('facilitadores').setValue(arr);
    this.facilitadoresSeleccionados = values;
  }

  onCloseBusqueda(data: any): void {
    if (data) {
      this.setFacilitadorValue(data);
    }
  }

  removeFacilitador(pro_actor_id: number) {
    this.facilitadoresSeleccionados = this.facilitadoresSeleccionados.filter(f => f.pro_actor_id !== pro_actor_id);
    this.setFacilitadorValue(this.facilitadoresSeleccionados);
  }
}
