import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ProgramaService {

  constructor(private http: HttpClient, private global: Globals) { }

  getProgramas(token, portafolioId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/portafolios/' + portafolioId + '/programas', search, pagination);
    return this.http.get(url, { headers });
  }
  getPrograma(portafolioId, id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/portafolios/' + portafolioId + '/programas/' + id;
    return this.http.get(url, { headers });
  }
  actualizarPrograma(portafolioId, id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/portafolios/' + portafolioId + '/programas/' + id;
    return this.http.put(url, json, { headers });
  }
  crearPrograma(portafolioId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/portafolios/' + portafolioId + '/programas';
    return this.http.post(url, json, { headers });
  }
  borrarPrograma(portafolioId, id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/portafolios/' + portafolioId + '/programas/' + id;
    return this.http.delete(url, { headers });
  }
}
