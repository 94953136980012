import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class OpcionesPredeterminadasService {

  constructor(private http: HttpClient, private global: Globals) { }

  obtenerOpcionesPredeterminadas(preguntaTipoId, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/preguntasTipos/' + preguntaTipoId + '/opcionesPredeterminadas', search, pagination);
    return this.http.get(url, { headers });
  }

  obtenerOpcionPredeterminada(preguntaTipoId, opcionPredeterminadaID) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntasTipos/' + preguntaTipoId + '/opcionesPredeterminadas/' + opcionPredeterminadaID;
    return this.http.get(url, { headers });
  }

  crearOpcionPredeterminada(preguntaTipoId, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntasTipos/' + preguntaTipoId + '/opcionesPredeterminadas';
    return this.http.post(url, jsonData, { headers });
  }

  borrarOpcionPredeterminada(preguntaTipoId, opcionPredeterminadaID) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntasTipos/' + preguntaTipoId + '/opcionesPredeterminadas/' + opcionPredeterminadaID;
    return this.http.delete(url, { headers });
  }

  editarOpcionPredeterminada(preguntaTipoId, opcionPredeterminadaID, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/preguntasTipos/' + preguntaTipoId + '/opcionesPredeterminadas/' + opcionPredeterminadaID;
    return this.http.put(url, jsonData, { headers });
  }

}
