import { Injectable } from '@angular/core';
import {Globals} from '../../../Globals';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BixdashboardsService {

  constructor(private globals: Globals, private http: HttpClient) { }

  obtenerReportesDeGrupo(orgName, groupId, headers) {
    const url = `${environment.API_POWERBI}/${orgName}/groups/${groupId}/reports`;
    return this.http.get(url, {headers});
  }
}
