import { NgxPermissionsService } from 'ngx-permissions';
import { DimensionTemaResponse, DimensionTemasResponse } from './interfaces/dimension-tema-response';
import { DimensionTema } from './interfaces/dimension-tema';
import { DimensionTemaService } from './services/dimension-tema.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-dimension-temas',
  templateUrl: './dimension-temas.component.html',
  styleUrls: ['./dimension-temas.component.sass']
})
export class DimensionTemasComponent implements OnInit {

  heading = 'Programas';
  subheading = 'En este módulo se encuentra la lista de programas.';
  icon = 'pe-7s-note2 icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'porcentaje', 'descripcion', 'acciones'];
  @Input() dimensionId: number;
  private token;


  temas: DimensionTema[];
  searchForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder,
    private localStorage: LocalStorageService, private toastr: ToastrService, private errorService: ErrorService,
    private dimensionTemaService: DimensionTemaService, private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getTemas();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getTemas() {
    this.cargando = true;
    this.dimensionTemaService.getDimensionTemas(this.token, this.dimensionId, this.searchForm.value, this.pagination)
      .subscribe((respuesta: DimensionTemasResponse) => {
        this.temas = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    this.router.navigate(['./temas/new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_temas/get432').then(result => {
      if (result) {
        this.router.navigate(['./temas/', id], { relativeTo: this.route });
      }
    });
  }

  borrar(id: number) {
    this.dimensionTemaService.borrarDimensionTema(this.dimensionId, id, this.token)
      .subscribe((respuesta: DimensionTemaResponse) => {
        if (respuesta.success) {
          this.toastr.success('Tema borrado exitosamente', 'Borrar Tema');
        }
        this.getTemas();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getTemas();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getTemas();
  }

  pageChange() {
    this.getTemas();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

}
