import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../../Globals';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient, private global: Globals) {
  }

  loginUsuario(usuario: string, password: string, application: string) {
    const body = 'username=' + usuario + '&password=' + password + '&application=' + application;

    const headers = new HttpHeaders(
      { 'Content-Type': 'application/x-www-form-urlencoded' }
    );

    return this.httpClient.post(this.global.API_ENDPOINT_ES + '/login', body, { headers, withCredentials: true });
  }

  logoutUsuario(token) {
    const headers = new HttpHeaders(
      {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      });
    const url = this.global.API_ENDPOINT_ES + '/logout';
    return this.httpClient.post(url, null, { headers });
  }

  /*servicio que solicita los permisos del usuario logueado*/
  refrescarPermisos() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/misPermisos';
    return this.httpClient.get(url, { headers });
  }

  parametrosPorDefecto() {
    const headers = this.global.getHeader();

    const url = this.global.API_ENDPOINT_ES + '/parametrosDefault';
    return this.httpClient.get(url, { headers });
  }

  refreshToken(token: string) {
    const body = 'refresh_token=' + token + '&application=app1';

    const headers = new HttpHeaders(
      { 'Content-Type': 'application/x-www-form-urlencoded' }
    );

    return this.httpClient.post(this.global.API_ENDPOINT_ES + '/refreshToken', body, { headers });
  }
  recuperarPassword(form) {
    const headers = new HttpHeaders(
      { 'Content-Type': 'application/json' }
    );
    const url = this.global.API_ENDPOINT_ES + '/passwords';
    return this.httpClient.put(url, JSON.stringify(form), { headers });
  }

  getParametrosLogin() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/parametrosLogin';
    return this.httpClient.get(url, { headers });
  }

  enviarSolicitudCambioContrasenha(jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/passwords';
    return this.httpClient.post(url, jsonData, { headers });
  }

  verificarTokenChangePassword(token) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/passwords/' + token;
    return this.httpClient.get(url, { headers });
  }

  registrarUsuario(jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/register';
    return this.httpClient.post(url, jsonData, { headers })
  }
}
