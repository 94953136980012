import {ProyectosService} from './../services/proyectos.service';
import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {LocalStorageService} from 'src/app/util/local-storage.service';
import {ErrorService} from 'src/app/util/services/error.service';
import {ToastrService} from 'ngx-toastr';
import {Proyecto} from '../interfaces/proyecto';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {BixdashboardsService} from '../../../util/services/power-bi/bixdashboards.service';
import * as pbi from 'powerbi-client';
import {
  DashboardToken,
  GroupPowerBiReports,
  PowerBiLoginResponse,
  PowerBiReport
} from '../../../util/services/power-bi/interfaces/power-bi-bix';
import {Globals} from '../../../Globals';

@Component({
  selector: 'app-detalle-proyecto',
  templateUrl: './detalle-proyecto.component.html',
  styleUrls: ['./detalle-proyecto.component.sass']
})
export class DetalleProyectoComponent implements OnInit {
  private token;
  proyectoId: number;
  cargando: boolean;
  proyecto: Proyecto;
  dashboardId: string;
  filter: string;

  constructor(private router: Router, private route: ActivatedRoute, private globals: Globals,
              private localStorage: LocalStorageService) {
  }

  ngOnInit() {
    this.proyectoId = +this.route.snapshot.paramMap.get('idProyecto');
    this.filter = `&filter=vw_preguntas_respuestas/proyecto_id%20eq%20${this.proyectoId}`;
    this.token = this.localStorage.getTokenFromStorage();
    this.dashboardId = this.localStorage.getParametrosFromStorage().DASHBOARD1_ID;
  }

  get(proyecto) {
    this.proyecto = proyecto;
  }

}
