import { CrearTallerGestionProyectoComponent } from './talleres-gestion-proyecto/crear-taller-gestion-proyecto/crear-taller-gestion-proyecto.component';
import { ApptitudeReportComponent } from './formacion-digital-gestion-proyecto/apptitude-report/apptitude-report.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FocalizacionGestionProyectoComponent } from './focalizacion-gestion-proyecto/focalizacion-gestion-proyecto.component';
import { DetalleTallerGestionProyectoComponent } from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/detalle-taller-gestion-proyecto.component';
import { TalleresGestionProyectoComponent } from './talleres-gestion-proyecto/talleres-gestion-proyecto.component';
import { FormacionDigitalGestionProyectoComponent } from './formacion-digital-gestion-proyecto/formacion-digital-gestion-proyecto.component';
import { GrupoAhorroGestionProyectoComponent } from './grupo-ahorro-gestion-proyecto/grupo-ahorro-gestion-proyecto.component';
import { CrearGrupoComponent } from './grupo-ahorro-gestion-proyecto/crear-grupo/crear-grupo.component';
import { DetalleGrupoGestionComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/detalle-grupo-gestion.component';
import { NegocioSeguimientoGestionProyectoComponent } from './negocio-seguimiento-gestion-proyecto/negocio-seguimiento-gestion-proyecto.component';
import { MercadoGestionProyectoComponent } from './mercado-gestion-proyecto/mercado-gestion-proyecto.component';
import { DetalleTallerInstanciaComponent } from './talleres-gestion-proyecto/detalle-taller-gestion-proyecto/taller-formador/detalle-taller-instancia/detalle-taller-instancia.component';
import { PeriodosComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/detalle-ciclos/periodos.component';
import { CrearCicloComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/crear-ciclo/crear-ciclo.component';
import { DetalleMiembroComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/miembros/detalle-miembro/detalle-miembro.component';
import { ReunionesComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/reuniones/reuniones.component';
import { CrearReunionComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/reuniones/crear-reunion/crear-reunion.component';
import { DetalleReunionComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/reuniones/detalle-reunion/detalle-reunion.component';
import { DetalleMiembroGrupoAhorroComponent } from './grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/miembros-grupo-ahorro/detalle-miembro-grupo-ahorro/detalle-miembro-grupo-ahorro.component';


const routes: Routes = [{
  path: '',
  children: [
    {
      path: 'focalizacion',
      component: FocalizacionGestionProyectoComponent,
      data: { extraParameter: 'proyectoMenu' }
    },
    {
      path: 'talleres-participantes',
      children: [{
        path: '',
        component: TalleresGestionProyectoComponent,
        data: { extraParameter: 'participantes' }
      }, {
        path: 'new',
        component: CrearTallerGestionProyectoComponent,
        data: { extraParameter: 'participantes' }
      }, {
        path: ':idTaller',
        children: [
          {
            path: '',
            component: DetalleTallerGestionProyectoComponent,
            data: { extraParameter: 'participantes' }
          },
          {
            path: 'instancia',
            children: [
              {
                path: ':idInstancia',
                component: DetalleTallerInstanciaComponent,
                data: { extraParameter: 'participantes' }
              }
            ]
          }
        ]
      }
      ],
      data: { extraParameter: 'proyectoMenu' }
    }, {
      path: 'talleres-facilitadores',
      children: [{
        path: '',
        component: TalleresGestionProyectoComponent,
        data: { extraParameter: 'facilitadores' }
      }, {
        path: 'new',
        component: CrearTallerGestionProyectoComponent,
        data: { extraParameter: 'facilitadores' }
      }, {
        path: ':idTaller',
        children: [
          {
            path: '',
            component: DetalleTallerGestionProyectoComponent,
            data: { extraParameter: 'facilitadores' }
          },
          {
            path: 'instancia',
            children: [
              {
                path: ':idInstancia',
                component: DetalleTallerInstanciaComponent,
                data: { extraParameter: 'facilitadores' }
              }
            ]
          }
        ]
      }
      ],
      data: { extraParameter: 'proyectoMenu' }
    },
    {
      path: 'formacion-digital',
      children: [{
        path: '',
        component: FormacionDigitalGestionProyectoComponent,
        data: { extraParameter: 'proyectoMenu' }
      }, {
        path: ':idFormDigi',
        component: ApptitudeReportComponent
      }]
    },
    {
      path: 'grupo-ahorro',
      children: [{
        path: '',
        component: GrupoAhorroGestionProyectoComponent,
        data: { extraParameter: 'proyectoMenu' }
      }, {
        path: 'new',
        component: CrearGrupoComponent,
        data: { extraParameter: 'proyectoMenu' }
      },
      {
        path: ':idGrupo',
        children: [
          {
            path: '',
            component: DetalleGrupoGestionComponent
          },
          {
            path: 'ciclos',
            children: [
              {
                path: '',
                component: DetalleGrupoGestionComponent
              },
              {
                path: 'new',
                component: CrearCicloComponent
              },
              {
                path: ':idCiclo',
                children: [
                  {
                    path: '',
                    component: PeriodosComponent
                  },
                  {
                    path: 'miembros',
                    children: [
                      {
                        path: ':id',
                        component: DetalleMiembroComponent
                      }
                    ]
                  },
                  {
                    path: 'reuniones',
                    children: [
                      {
                        path: '',
                        component: ReunionesComponent
                      },
                      {
                        path: 'new',
                        component: CrearReunionComponent
                      },
                      {
                        path: ':idReunion',
                        component: DetalleReunionComponent
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'miembro-grupo-ahorro',
            children: [
              {
                path: ':id',
                component: DetalleMiembroGrupoAhorroComponent
              }
            ]
          },
        ]
      }
      ],
      data: { extraParameter: 'proyectoMenu' }
    },
    {
      path: 'negocio-seguimiento',
      component: NegocioSeguimientoGestionProyectoComponent,
      data: { extraParameter: 'proyectoMenu' }
    },
    {
      path: 'mercado',
      component: MercadoGestionProyectoComponent,
      data: { extraParameter: 'proyectoMenu' }
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionProyectoRoutingModule { }
