import { ParametricosProyectoService } from './services/parametricos-proyecto.service';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ParametricoProyectoResponse } from './interfaces/parametrico-proyecto-response';
import { ParametricoProyecto } from './interfaces/parametrico-proyecto';

@Component({
  selector: 'app-parametricos-proyecto',
  templateUrl: './parametricos-proyecto.component.html',
  styleUrls: ['./parametricos-proyecto.component.sass']
})
export class ParametricosProyectoComponent implements OnInit {

  heading = 'Referencias del Proyecto';
  subheading = 'En este apartado de encuentran todos las referencias de proyectos';
  icon = 'pe-7s-note2 icon-gradient bg-happy-fisher';
  parametricosCount: ParametricoProyecto;
  cargando: boolean;
  constructor(private localStorageService: LocalStorageService,
              private parametricosProyecto: ParametricosProyectoService,
              private errorService: ErrorService) { }

  ngOnInit() {
    this.getCantidadesPorItem();
  }

  private getCantidadesPorItem() {
    this.cargando = true;
    const token = this.localStorageService.getTokenFromStorage();
    this.parametricosProyecto.getCantidadPorItem(token)
      .subscribe((respuesta: ParametricoProyectoResponse) => {
        this.parametricosCount = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => { this.cargando = false; });
  }

}
