import { Injectable } from '@angular/core';
import { Globals } from 'src/app/Globals';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdministracionDashboardService {

  constructor(private global: Globals, private http: HttpClient) { }

  getAdministracionDashboard() {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/dashboardsAdministracion';
    return this.http.get(url, { headers });
  }
}
