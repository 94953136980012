import { TallerTipoService } from './../services/taller-tipo.service';
import { TallerTipoResponse } from './../interfaces/taller-tipo-response';
import { Component, OnInit } from '@angular/core';
import { TallerTipo } from '../interfaces/taller-tipo';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalle-taller-tipo',
  templateUrl: './detalle-taller-tipo.component.html',
  styleUrls: ['./detalle-taller-tipo.component.sass']
})
export class DetalleTallerTipoComponent implements OnInit {

  public tallerTipoForm: FormGroup;
  editable = true;
  tallerTipoId: number;
  tallerTipo: TallerTipo;
  private token;
  cargando: boolean;
  process: boolean;

  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private fS: FormStatusService,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private tallerTipoService: TallerTipoService) { }

  ngOnInit() {
    this.tallerTipoId = +this.route.snapshot.paramMap.get('id');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.tallerTipoForm = this.fB.group({
      nombre: [{ value: undefined, disabled: this.editable }, Validators.required],
      codigo: [{ value: undefined, disabled: this.editable }],
      descripcion: [{ value: undefined, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.tallerTipoService.getTallerTipo(this.tallerTipoId, this.token)
      .subscribe((respuesta: TallerTipoResponse) => {
        this.tallerTipo = respuesta.data;
        this.tallerTipoForm.patchValue(this.tallerTipo);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.tallerTipoForm);
    this.tallerTipoService.actualizarTallerTipo(this.tallerTipoId, this.token, jsonData)
      .subscribe((respuesta: TallerTipoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Taller tipo actualizado correctamente', 'Actualizar Taller tipo');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.tallerTipoForm = this.fS.cambiarEstado(this.tallerTipoForm, this.editable, ['codigo'], true);
    this.editable = !this.editable;
  }

}
