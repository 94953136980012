import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ReunionesCrudService {

  constructor(
    private global: Globals,
    private http: HttpClient
  ) { }

  getDiasReunion(idGrupoCiclo, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/gruposCiclos/' + idGrupoCiclo + '/gruposCiclosDias', search, pagination);
    return this.http.get(url, { headers });
  }

  crearDiaReunion(token, idGrupoCiclo, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposCiclos/' + idGrupoCiclo + '/gruposCiclosDias';
    return this.http.post(url, json, { headers });
  }

  obtenerDiaReunion(idCiclo, idDia) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/gruposCiclos/' + idCiclo + '/gruposCiclosDias/' + idDia;
    return this.http.get(url, { headers });
  }

  editarDiaReunion(idCiclo, idDia, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/gruposCiclos/' + idCiclo + '/gruposCiclosDias/' + idDia;
    return this.http.put(url, jsonData, { headers });
  }

  borrarDiaReunion(idCiclo, idDia) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/gruposCiclos/' + idCiclo + '/gruposCiclosDias/' + idDia;
    return this.http.delete(url, { headers });
  }

}
