import { ComboPoolPreguntas } from './../../../../../../../util/interfaces/combo';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ErrorService } from './../../../../../../../util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { EditorEncuestaService } from './../services/editor-encuesta.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ComboPoolPreguntasResponse } from 'src/app/util/interfaces/combos-response';

export interface encuestaData {
  encuestaId: number;
  proyectoId: number;
}
@Component({
  selector: 'app-importar-editor-encuesta-dialog',
  templateUrl: './importar-editor-encuesta-dialog.component.html',
  styleUrls: ['./importar-editor-encuesta-dialog.component.sass']
})
export class ImportarEditorEncuestaDialogComponent implements OnInit {

  encuestaId: number;
  selected: number;
  preguntaForm: FormGroup;
  preguntasPool: ComboPoolPreguntas[];
  cargando: boolean;
  process: boolean;
  constructor(public dialogRef: MatDialogRef<ImportarEditorEncuestaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: encuestaData, private editorDeEncuestaService: EditorEncuestaService,
    private comboService: CombosService, private toastr: ToastrService, private errorService: ErrorService,
    private fB: FormBuilder, private fS: FormStatusService) {

  }

  ngOnInit() {
    this.encuestaId = this.data.encuestaId;
    this.cargarFormulario();
    this.getComboPreguntasPool();
  }

  cargarFormulario() {
    this.preguntaForm = this.fB.group({
      enc_pregunta_tipo_id: [null, Validators.required],
      codigo: [null, Validators.required],
      texto: [null, Validators.required]
      //indicador_id: [null, Validators.required]
    });
  }

  getComboPreguntasPool() {
    this.comboService.getPreguntasPoolCombo().subscribe((response: ComboPoolPreguntasResponse) => {
      this.preguntasPool = response.data;
    }, error => {
      this.errorService.handleError(error);
    });
  }

  crearPregunta() {
    this.process = true;
    const code = this.preguntaForm.get('codigo').value;
    this.preguntaForm.get('codigo').setValue(code.toUpperCase());
    const jsonData = this.fS.getJsonFromForm(this.preguntaForm);
    this.editorDeEncuestaService.crearPregunta(this.encuestaId, jsonData).subscribe((respuesta: any) => {
      this.toastr.success('Pregunta importada exitosamente', 'Importar Pregunta');
      this.dialogRef.close(true);
    }, error => {
      this.errorService.handleError(error);
    }, () => { this.process = false; });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  parseValue(selected) {
    if (selected) {
      this.preguntaForm.get('enc_pregunta_tipo_id').setValue(+selected.enc_pregunta_tipo_id);
      this.preguntaForm.get('codigo').setValue(selected.codigo);
      this.preguntaForm.get('texto').setValue(selected.texto);
    }
  }

}
