import { ConfiguracionParaproResponse } from './../interfaces/configuracion-parapro-response';
import { ConfiguracionesParaproService } from './../services/configuraciones-parapro.service';
import { Component, OnInit } from '@angular/core';
import { ConfiguracionParapro } from '../interfaces/configuracion-parapro';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalle-configuracion-parapro',
  templateUrl: './detalle-configuracion-parapro.component.html',
  styleUrls: ['./detalle-configuracion-parapro.component.sass']
})
export class DetalleConfiguracionParaproComponent implements OnInit {

  public configuracionForm: FormGroup;
  editable = true;
  configuracionId: number;
  private token;
  cargando: boolean;
  process: boolean;
  configuracion: ConfiguracionParapro;

  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private fS: FormStatusService,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private configuracionPraProService: ConfiguracionesParaproService) { }

  ngOnInit() {
    this.configuracionId = +this.route.snapshot.paramMap.get('id');
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.get();
  }

  makeForm() {
    this.configuracionForm = this.fB.group({
      nombre: [{ value: undefined, disabled: this.editable }, Validators.required],
      codigo: [{ value: undefined, disabled: this.editable }],
      tipo: [{ value: undefined, disabled: this.editable }, Validators.required],
      valor: [{ value: undefined, disabled: this.editable }, Validators.required],
      descripcion: [{ value: undefined, disabled: this.editable }]
    });
  }

  get() {
    this.cargando = true;
    this.configuracionPraProService.getConfiguracion(this.configuracionId, this.token)
      .subscribe((respuesta: ConfiguracionParaproResponse) => {
        this.configuracion = respuesta.data;
        this.configuracionForm.patchValue(this.configuracion);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.configuracionForm);
    this.configuracionPraProService.actualizarConfiguracion(this.configuracionId, this.token, jsonData)
      .subscribe((respuesta: ConfiguracionParaproResponse) => {
        if (respuesta.success) {
          this.toastr.success('Configuración actualizada correctamente', 'Actualizar Configuración');
          this.get();
          this.editableSwitcher();
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  public editableSwitcher() {
    this.configuracionForm = this.fS.cambiarEstado(this.configuracionForm, this.editable, ['codigo'], true);
    this.editable = !this.editable;
  }

}
