import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class TalleresFacilitadoresService {

  constructor(private http: HttpClient, private global: Globals) { }


  obtenerTalleresFacilitadores(search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url: string = this.global.makeUrl('/talleresFacilitadores', search, pagination);
    return this.http.get(url, { headers });
  }

  obtenerTallerFacilitador(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/talleresFacilitadores/' + id;
    return this.http.get(url, { headers });
  }

  actualizarTallerFacilitador(id, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/talleresFacilitadores/' + id;
    return this.http.put(url, json, { headers });
  }

  crearTallerFacilitador(json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/talleresFacilitadores';
    return this.http.post(url, json, { headers });
  }

  borrarTallerFacilitador(id) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/talleresFacilitadores/' + id;
    return this.http.delete(url, { headers });
  }

}
