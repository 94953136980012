import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Perfil} from '../interfaces/perfil.interface';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UsuarioPerfilResponse} from '../../../../../../../Pages/Administracion/usuarios/interfaces/usuarios-response.interface';
import {FormStatusService} from '../../../../../../../util/services/form-status/form-status.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from '../../../../../../../util/services/error.service';
import {UserProfileService} from '../services/user-profile.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.sass']
})
export class UserInfoComponent implements OnInit {
  public disabledEmail = true;
  public disabledName = true;
  changeInfoForm: FormGroup;
  @Input() userInfo: Perfil;
  @Output() clickUpdateEvent = new EventEmitter();
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private userProfileServices: UserProfileService,
    private formStatus: FormStatusService
  ) { }

  ngOnInit() {
    this.loadForm();
  }
  loadForm() {
    this.changeInfoForm = this.formBuilder.group({
      name: { value: this.userInfo.name, disabled: this.disabledName },
      email: { value: this.userInfo.email, disabled: this.disabledEmail }
    });
  }
  updateProfile(campo) {
    this.changeInfoForm.controls[campo].enable();
    const jsonData = this.formStatus.getJsonFromForm(this.changeInfoForm);
    this.userProfileServices.actualizarDatoPerfil(jsonData)
      .subscribe((respuesta: UsuarioPerfilResponse) => {
          if (respuesta.success) {
            this.toastr.success(campo === 'name' ? 'Usuario' +
              ' actualizado exitosamente' : 'Correo' + ' actualizado exitosamente',
              'Actualizar ' + campo === 'name' ? 'Usuario' : 'Correo');
            this.editableSwitcher(campo);
            this.actualizar();
          } else {
            this.toastr.error('Error al actualizar ' + campo === 'name' ? 'Usuario' : 'Correo',
              'Actualizar ' + campo === 'name' ? 'Usuario' : 'Correo');
          }
        }, error => {
          this.errorService.handleError(error);
        }
      );
  }
  actualizar() {
    this.clickUpdateEvent.emit(true);
  }
  public editableSwitcher(campo: string, cancelar = false) {
    if (campo === 'email') {
      this.changeInfoForm = this.formStatus.cambiarEstado(this.changeInfoForm, this.disabledEmail, [campo]);
      this.disabledEmail = !this.disabledEmail;
      if (cancelar) {
        this.changeInfoForm.controls.email.setValue(this.userInfo.email);
      }
    }
    if (campo === 'name') {
      this.changeInfoForm = this.formStatus.cambiarEstado(this.changeInfoForm, this.disabledName, [campo]);
      this.disabledName = !this.disabledName;
      if (cancelar) {
        this.changeInfoForm.controls.name.setValue(this.userInfo.name);
      }
    }
  }
}
