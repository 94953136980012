import {Globals} from 'src/app/Globals';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RespuestaMonitoreoService {

  constructor(private http: HttpClient, private global: Globals) {
  }

  getEncuestas(proyectoId, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/proyectos/' + proyectoId + '/encuestas', search, pagination);
    return this.http.get(url, {headers});
  }

  getEncuesta(proyectoId, encuestaId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/encuestas/' + encuestaId;
    return this.http.get(url, {headers});
  }

  crearEncuesta(proyectoId, formData) {
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/encuestas';
    return this.http.post(url, formData);
  }

  borrarEncuesta(proyectoId, encuestaId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/encuestas/' + encuestaId;
    return this.http.delete(url, {headers});
  }

  editarEncuesta(proyectoId, encuestaId, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/encuestas/' + encuestaId;
    return this.http.put(url, jsonData, {headers});
  }

  getInstanciasActores(encuestaId, search, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/encuestas/' + encuestaId + '/instancias', search, pagination);
    return this.http.get(url, {headers});
  }

  getInstanciaActor(encuestaId, instanciaId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/encuestas/' + encuestaId + '/instancias/' + instanciaId;
    return this.http.get(url, {headers});
  }

  editarRespuestaInstancia(encuestaId, instanciaId, instanciaRespuestaId, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/encuestas/' + encuestaId + '/instancias/' + instanciaId + `/instanciasRespuestas/${instanciaRespuestaId}`;
    return this.http.put(url, jsonData, {headers});
  }

  getEncuestaRespuestas(proyectoId, xmlFormId, search) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/proyectos/' + proyectoId + '/forms/' + xmlFormId + '/respuestas', search, null);
    return this.http.get(url, {headers});
  }

  procesarRespuestas(proyectoId, xmlFormId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/forms/' + xmlFormId + '/procesarRespuestas';
    return this.http.post(url, {}, {headers});
  }

  // getEncuestaEstructura(proyectoId, xmlFormId) {
  //   const headers = this.global.getHeader();
  //   const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/forms/' + xmlFormId + '/estructuras';
  //   return this.http.get(url, {headers});
  // }

  publicarEncuesta(proyectoId, xmlFormId) {
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/forms/' + xmlFormId + '/publicar';
    return this.http.put(url, null);
  }

  cambiarEstadoEncuesta(proyectoId, xmlFormId, jsonData) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/forms/' + xmlFormId + '/estados';
    return this.http.put(url, jsonData, {headers});
  }

  exportarEncuestaXls(proyectoId, xmlFormId) {
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/forms/' + xmlFormId + '/exportar';
    return this.http.get(url, {responseType: 'blob'});
  }

  exportarRespuestasEncuesta(proyectoId, xmlFormId) {
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/forms/' + xmlFormId + '/exportarRespuestas';
    return this.http.get(url, {responseType: 'blob'});
  }

  exportarRespuestasEncuestaSinMedia(proyectoId, encuestaId) {
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/forms/' + encuestaId + '/exportarRespuestasWA';
    return this.http.get(url, {responseType: 'blob'});
  }

  getRespuestasTest() {
    const headers = this.global.getHeaderODK();
    const url = 'https://deswedd.espy.cloud/v1/projects/7/forms/build_Untitled-Form_15900925243.svc/Submissions';
    return this.http.get(url, {headers});
  }

  editarRespuestaEnketo(projectId, xmlFormId, instanciaId) {
    const headers = this.global.getHeaderODK();
    const url = `${environment.ODK_CENTRAL_ENDPOINT}/projects/${projectId}/forms/${xmlFormId}/submissions/${instanciaId}/edit`;
    return this.http.get(url, {headers, observe: 'response'});
  }

  goToEditarRespuestaEnketo(proyectoId, encuestaId, uuid) {
    const headers = this.global.getHeader();
    const url = `${this.global.API_ENDPOINT_ES}/proyectos/${proyectoId}/forms/${encuestaId}/uuid/${uuid}`;
    return this.http.get(url, {headers});
  }
}
