import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ConfigTalleresFacilitadoresService {

  constructor(private http: HttpClient, private global: Globals) { }

  getProyectoTalleresFacilitadores(proyectoId, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url: string = this.global.makeUrl('/proyectos/' + proyectoId + '/proyectosTalleresFacilitadores?page=disable', search, pagination);
    return this.http.get(url, { headers });
  }

  actualizarProyectoTalleresFacilitadores(proyectoId, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosTalleresFacilitadores';
    return this.http.patch(url, json, { headers });
  }

  crearProyectoTalleresFacilitadores(proyectoId, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosTalleresFacilitadores';
    return this.http.post(url, json, { headers });
  }

  borrarProyectoTallerFacilitador(proyectoId, jsonData) {
    const options = {
      headers: this.global.getHeader(),
      body : jsonData
    };
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosTalleresFacilitadores';
    return this.http.delete(url, options);
  }
}
