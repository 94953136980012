import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from './../../../util/services/error.service';
import { PortafolioService } from './services/portafolio.service';
import { LocalStorageService } from './../../../util/local-storage.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PortafoliosResponse, PortafolioResponse } from './interfaces/portafolio-response';
import { Portafolio } from './interfaces/portafolio';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-portafolios',
  templateUrl: './portafolios.component.html',
  styleUrls: ['./portafolios.component.sass']
})
export class PortafoliosComponent implements OnInit {

  heading = 'Portafolios';
  subheading = 'En este módulo se encuentra la lista de portafolios.';
  icon = 'pe-7s-portfolio icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'descripcion', 'acciones'];

  private token;
  portafolios: Portafolio[];
  searchForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder,
    private localStorage: LocalStorageService, private portafolioService: PortafolioService, private errorService: ErrorService, private toastr: ToastrService,
    private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getPortafolios();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getPortafolios() {
    this.cargando = true;
    this.portafolioService.getPortafolios(this.token, this.searchForm.value, this.pagination)
      .subscribe((respuesta: PortafoliosResponse) => {
        this.portafolios = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_portafolios/get402').then(result => {
      if (result) {
        this.router.navigate(['./', id], { relativeTo: this.route });
      }
    });
  }

  borrar(id: number) {
    this.portafolioService.borrarPortafolio(id, this.token)
      .subscribe((respuesta: PortafolioResponse) => {
        if (respuesta.success) {
          this.toastr.success('Portafolio borrado exitosamente', 'Borrar Portafolio');
        }
        this.getPortafolios();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getPortafolios();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getPortafolios();
  }

  pageChange() {
    this.getPortafolios();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

}
