import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDashboardsBixComponent } from './ngx-dashboards-bix/ngx-dashboards-bix.component';
import {NgxPowerBiModule} from 'ngx-powerbi';
import {SpinnerModule} from '../spinner/spinner.module';

@NgModule({
  declarations: [NgxDashboardsBixComponent],
  imports: [
    CommonModule,
    NgxPowerBiModule,
    SpinnerModule
  ], exports: [
    NgxDashboardsBixComponent,
    NgxPowerBiModule
  ]
})
export class NgxDashboardsBixModule { }
