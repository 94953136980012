import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-listado-cabeceras',
  templateUrl: './listado-cabeceras.component.html',
  styleUrls: ['./listado-cabeceras.component.sass']
})
export class ListadoCabecerasComponent implements OnInit {

  total: number;
  pagination = { page: 1, per_page: 10 };
  displayedColumns = ['created_at', 'updated_at'];
  @Input() cabeceras: any[];
  selected: any;
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  verDetalle(id) {
    this.router.navigate(['./', id], { relativeTo: this.route });
  }

}
