import { TalleresFacilitadoresComponent } from './talleres-facilitadores/talleres-facilitadores.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgSelectModule } from '@ng-select/ng-select';
import { SwalComponentModule } from './../../util/swal-component/swal-component.module';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { NgbPaginationModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { SpinnerModule } from './../../util/spinner/spinner.module';
import { LayoutModule } from './../../Layout/layout.module';
import { ParametricosProyectoComponent } from './parametricos-proyecto.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ParametricosProyectoRoutingModule } from './parametricos-proyecto-routing.module';
import { PortafoliosComponent } from './portafolios/portafolios.component';
import { EstadosProyectoComponent } from './estados-proyecto/estados-proyecto.component';
import { EstadosActoresComponent } from './estados-actores/estados-actores.component';
import { ComponentesGestionComponent } from './componentes-gestion/componentes-gestion.component';
import { ComponentesMonitoreoComponent } from './componentes-monitoreo/componentes-monitoreo.component';
import { TiposDocumentoComponent } from './tipos-documento/tipos-documento.component';
import { DimensionesComponent } from './dimensiones/dimensiones.component';
import { RolesActoresComponent } from './roles-actores/roles-actores.component';
import { CrearComponenteGestionComponent } from './componentes-gestion/crear-componente-gestion/crear-componente-gestion.component';
import { DetalleComponenteGestionComponent } from './componentes-gestion/detalle-componente-gestion/detalle-componente-gestion.component';
import { CrearComponenteMonitoreoComponent } from './componentes-monitoreo/crear-componente-monitoreo/crear-componente-monitoreo.component';
import { DetalleComponenteMonitoreoComponent } from './componentes-monitoreo/detalle-componente-monitoreo/detalle-componente-monitoreo.component';
import { CrearDimensionComponent } from './dimensiones/crear-dimension/crear-dimension.component';
import { DetalleDimensionComponent } from './dimensiones/detalle-dimension/detalle-dimension.component';
import { CrearEstadoActorComponent } from './estados-actores/crear-estado-actor/crear-estado-actor.component';
import { DetalleEstadoActorComponent } from './estados-actores/detalle-estado-actor/detalle-estado-actor.component';
import { CrearEstadoProyectoComponent } from './estados-proyecto/crear-estado-proyecto/crear-estado-proyecto.component';
import { DetalleEstadoProyectoComponent } from './estados-proyecto/detalle-estado-proyecto/detalle-estado-proyecto.component';
import { CrearPortafolioComponent } from './portafolios/crear-portafolio/crear-portafolio.component';
import { DetallePortafolioComponent } from './portafolios/detalle-portafolio/detalle-portafolio.component';
import { CrearRolActorComponent } from './roles-actores/crear-rol-actor/crear-rol-actor.component';
import { DetalleRolActorComponent } from './roles-actores/detalle-rol-actor/detalle-rol-actor.component';
import { CrearTipoDocumentoComponent } from './tipos-documento/crear-tipo-documento/crear-tipo-documento.component';
import { DetalleTipoDocumentoComponent } from './tipos-documento/detalle-tipo-documento/detalle-tipo-documento.component';
import { BotoneraCreacionModule } from 'src/app/util/botonera-creacion/botonera-creacion.module';
import { BotonFiltroModule } from 'src/app/util/boton-filtro/boton-filtro.module';
import { BotoneraEdicionModule } from 'src/app/util/botonera-edicion/botonera-edicion.module';
import { DatosNoEncontradosModule } from 'src/app/util/datos-no-encontrados/datos-no-encontrados.module';
import { ProgramasComponent } from './portafolios/programas/programas.component';
import { CrearProgramaComponent } from './portafolios/programas/crear-programa/crear-programa.component';
import { DetalleProgramaComponent } from './portafolios/programas/detalle-programa/detalle-programa.component';
import { ConfiguracionesComponent } from './configuraciones/configuraciones.component';
import { CrearConfiguracionParaproComponent } from './configuraciones/crear-configuracion-parapro/crear-configuracion-parapro.component';
import { DetalleConfiguracionParaproComponent } from './configuraciones/detalle-configuracion-parapro/detalle-configuracion-parapro.component';
import { DocumentosEstadoComponent } from './documentos-estado/documentos-estado.component';
import { CrearDocumentoEstadoComponent } from './documentos-estado/crear-documento-estado/crear-documento-estado.component';
import { DetalleDocumentoEstadoComponent } from './documentos-estado/detalle-documento-estado/detalle-documento-estado.component';
import { DimensionTemasComponent } from './dimensiones/dimension-temas/dimension-temas.component';
import { CrearDimensionTemaComponent } from './dimensiones/dimension-temas/crear-dimension-tema/crear-dimension-tema.component';
import { DetalleDimensionTemaComponent } from './dimensiones/dimension-temas/detalle-dimension-tema/detalle-dimension-tema.component';
import { TemaIndicadoresComponent } from './dimensiones/dimension-temas/tema-indicadores/tema-indicadores.component';
import { CrearTemaIndicadorComponent } from './dimensiones/dimension-temas/tema-indicadores/crear-tema-indicador/crear-tema-indicador.component';
import { DetalleTemaIndicadorComponent } from './dimensiones/dimension-temas/tema-indicadores/detalle-tema-indicador/detalle-tema-indicador.component';
import { TalleresTiposComponent } from './talleres-tipos/talleres-tipos.component';
import { CrearTallerTipoComponent } from './talleres-tipos/crear-taller-tipo/crear-taller-tipo.component';
import { DetalleTallerTipoComponent } from './talleres-tipos/detalle-taller-tipo/detalle-taller-tipo.component';
import { ProyectosGobiernoComponent } from './proyectos-gobierno/proyectos-gobierno.component';
import { CrearProyectoGobiernoComponent } from './proyectos-gobierno/crear-proyecto-gobierno/crear-proyecto-gobierno.component';
import { DetalleProyectoGobiernoComponent } from './proyectos-gobierno/detalle-proyecto-gobierno/detalle-proyecto-gobierno.component';
import { RespuestasPredeterminadasComponent } from './respuestas-predeterminadas/respuestas-predeterminadas.component';
import { CrearRespuestaPredeterminadaComponent } from './respuestas-predeterminadas/crear-respuesta-predeterminada/crear-respuesta-predeterminada.component';
import { DetalleRespuestaPredeterminadaComponent } from './respuestas-predeterminadas/detalle-respuesta-predeterminada/detalle-respuesta-predeterminada.component';
import { PoolPreguntasComponent } from './pool-preguntas/pool-preguntas.component';
import { PoolCrearPreguntaComponent } from './pool-preguntas/pool-crear-pregunta/pool-crear-pregunta.component';
import { PoolDetallePreguntaComponent } from './pool-preguntas/pool-detalle-pregunta/pool-detalle-pregunta.component';
import { OpcionesPreguntaComponent } from './pool-preguntas/pool-detalle-pregunta/opciones-pregunta/opciones-pregunta.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PreguntasTiposComponent } from './preguntas-tipos/preguntas-tipos.component';
import { CrearPreguntaTipoComponent } from './preguntas-tipos/crear-pregunta-tipo/crear-pregunta-tipo.component';
import { DetallePreguntaTipoComponent } from './preguntas-tipos/detalle-pregunta-tipo/detalle-pregunta-tipo.component';
import { CrudOpcionesPredeterminadasComponent } from './preguntas-tipos/detalle-pregunta-tipo/crud-opciones-predeterminadas/crud-opciones-predeterminadas.component';
import { InversoresComponent } from './inversores/inversores.component';
import { CrearInversorComponent } from './inversores/crear-inversor/crear-inversor.component';
import { DetalleInversorComponent } from './inversores/detalle-inversor/detalle-inversor.component';
import { InversoresTiposComponent } from './inversores-tipos/inversores-tipos.component';
import { CrearInversoresTiposComponent } from './inversores-tipos/crear-inversores-tipos/crear-inversores-tipos.component';
import { DetalleInversoresTiposComponent } from './inversores-tipos/detalle-inversores-tipos/detalle-inversores-tipos.component';
import { EncuestasTiposComponent } from './encuestas-tipos/encuestas-tipos.component';
import { CrearEncuestaTipoComponent } from './encuestas-tipos/crear-encuesta-tipo/crear-encuesta-tipo.component';
import { DetalleEncuestaTipoComponent } from './encuestas-tipos/detalle-encuesta-tipo/detalle-encuesta-tipo.component';
import { TalleresParticipantesComponent } from './talleres-participantes/talleres-participantes.component';
import { CrearTallerParticipanteComponent } from './talleres-participantes/crear-taller-participante/crear-taller-participante.component';
import { DetalleTallerParticipanteComponent } from './talleres-participantes/detalle-taller-participante/detalle-taller-participante.component';
import { CrearTallerFacilitadorComponent } from './talleres-facilitadores/crear-taller-facilitador/crear-taller-facilitador.component';
import { DetalleTallerFacilitadorComponent } from './talleres-facilitadores/detalle-taller-facilitador/detalle-taller-facilitador.component';
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faPlus, faInfo} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [ParametricosProyectoComponent,
    // Portafolios
    PortafoliosComponent,
    CrearPortafolioComponent,
    DetallePortafolioComponent,
    // Programas
    ProgramasComponent,
    CrearProgramaComponent,
    DetalleProgramaComponent,
    // Estados de Proyecto
    EstadosProyectoComponent,
    CrearEstadoProyectoComponent,
    DetalleEstadoProyectoComponent,
    // Estados de Actores
    EstadosActoresComponent,
    CrearEstadoActorComponent,
    DetalleEstadoActorComponent,
    // Componentes de Gestion
    ComponentesGestionComponent,
    CrearComponenteGestionComponent,
    DetalleComponenteGestionComponent,
    // Componentes de Monitoreo
    ComponentesMonitoreoComponent,
    CrearComponenteMonitoreoComponent,
    DetalleComponenteMonitoreoComponent,
    // Tipos de Documento
    TiposDocumentoComponent,
    CrearTipoDocumentoComponent,
    DetalleTipoDocumentoComponent,
    // Dimensiones
    DimensionesComponent,
    CrearDimensionComponent,
    DetalleDimensionComponent,
    // Roles de Actor
    RolesActoresComponent,
    CrearRolActorComponent,
    DetalleRolActorComponent,
    ConfiguracionesComponent,
    CrearConfiguracionParaproComponent,
    DetalleConfiguracionParaproComponent,
    DocumentosEstadoComponent,
    CrearDocumentoEstadoComponent,
    DetalleDocumentoEstadoComponent,
    DimensionTemasComponent,
    CrearDimensionTemaComponent,
    DetalleDimensionTemaComponent,
    TemaIndicadoresComponent,
    CrearTemaIndicadorComponent,
    DetalleTemaIndicadorComponent,
    TalleresTiposComponent,
    CrearTallerTipoComponent,
    DetalleTallerTipoComponent,
    ProyectosGobiernoComponent,
    CrearProyectoGobiernoComponent,
    DetalleProyectoGobiernoComponent,
    RespuestasPredeterminadasComponent,
    CrearRespuestaPredeterminadaComponent,
    DetalleRespuestaPredeterminadaComponent,
    PoolPreguntasComponent,
    PoolCrearPreguntaComponent,
    PoolDetallePreguntaComponent,
    OpcionesPreguntaComponent,
    PreguntasTiposComponent,
    CrearPreguntaTipoComponent,
    DetallePreguntaTipoComponent,
    CrudOpcionesPredeterminadasComponent,
    InversoresComponent,
    CrearInversorComponent,
    DetalleInversorComponent,
    InversoresTiposComponent,
    CrearInversoresTiposComponent,
    DetalleInversoresTiposComponent,
    EncuestasTiposComponent,
    CrearEncuestaTipoComponent,
    DetalleEncuestaTipoComponent,
    TalleresParticipantesComponent,
    CrearTallerParticipanteComponent,
    DetalleTallerParticipanteComponent,
    TalleresFacilitadoresComponent,
    CrearTallerFacilitadorComponent,
    DetalleTallerFacilitadorComponent,
    ],
  imports: [
    CommonModule,
    ParametricosProyectoRoutingModule,
    LayoutModule,
    BotoneraCreacionModule,
    BotonFiltroModule,
    BotoneraEdicionModule,
    DatosNoEncontradosModule,
    SpinnerModule,
    MatTableModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    SweetAlert2Module,
    NgbPaginationModule,
    MatInputModule,
    FlexLayoutModule,
    FontAwesomeModule,
    MatTabsModule,
    NgbAccordionModule,
    MatDialogModule,
    MatSelectModule,
    SwalComponentModule,
    MatButtonModule,
    NgSelectModule,
    NgxPermissionsModule.forChild(),
    MatCheckboxModule,
    MatIconModule
  ],
})
export class ParametricosProyectoModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faPlus, faInfo);
  }
}
