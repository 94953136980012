import { CrearFormacionDigitalComponent } from './crear-formacion-digital/crear-formacion-digital.component';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from './../../../../util/services/error.service';
import { FormacionDigitalService } from './services/formacion-digital.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {InformacionExcelMiembroGrupoAhorroComponent} from '../grupo-ahorro-gestion-proyecto/detalle-grupo-gestion/periodos/miembros/informacion-excel-miembro-grupo-ahorro/informacion-excel-miembro-grupo-ahorro.component';
import {InformacionReportesExcelComponent} from './informacion-reportes-excel/informacion-reportes-excel.component';

// Interfaz de apptitude
interface Apptitude {
  id: number;
  campania: string;
  facilitador: string;
  estudiante: string;
  nombre_usuario: number;
  modulo: string;
  fecha_inicio: string;
  fecha_fin: string;
  completado: number;
  utilidad: number;
}

// Interfaz de apprendo
interface Apprendo {
  id: number;
  nombre: string;
  ci: number;
  empresa: string;
  ingreso_app: string;
  progreso: string;
  calificacion: string;
  aprobado: string;
}

// Interfaz de matrix
interface Matrix {
  id: number;
  estudiante: number;
  m: number;
  overall1: string;
  overall2: string;
  participacion_5: string;
  tareas_100: string;
  participacion_5_present: string;
}
@Component({
  selector: 'app-formacion-digital-gestion-proyecto',
  templateUrl: './formacion-digital-gestion-proyecto.component.html',
  styleUrls: ['./formacion-digital-gestion-proyecto.component.sass']
})
export class FormacionDigitalGestionProyectoComponent implements OnInit {
  cargando: boolean;
  labelImport2: ElementRef;
  total: number;
  pagination = { page: 1, per_page: 10 };
  displayedColumns = ['campania', 'datos', 'modulo', 'fechas', 'resumen'];
  cabeceras: any[] = [];
  searchForm: FormGroup;
  mostrarReporte = false;
  proyectoId: number;
  selected: any;

  constructor(
    private router: Router, private route: ActivatedRoute, private fB: FormBuilder, public dialog: MatDialog,
    private formacionDigitalService: FormacionDigitalService, private errorService: ErrorService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.proyectoId = +this.route.snapshot.paramMap.get('idProyecto');
    this.makeSearchForm();
  }

  getFormacionDigitalCabeceras() {
    this.cargando = true;
    this.formacionDigitalService.getFormacionesDigitales(this.proyectoId, this.searchForm.value, this.pagination)
      .subscribe((response: any) => {
        this.cabeceras = response.data;
      }, error => {
        this.errorService.handleError(error);
      }, () => {
        this.cargando = false;
      });
  }


  makeSearchForm() {
    this.searchForm = this.fB.group({
      tipo_reporte: '',
    });
  }

  crear() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.router.navigate(['./', id], { relativeTo: this.route });
  }

  borrar(id: number) {
    return;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  onClick(event: any) {
    return;
  }

  pageChange() {
    return;
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  changeSelected() {
    this.getFormacionDigitalCabeceras();
  }

  subir() {
    this.dialog.open(CrearFormacionDigitalComponent,
      {
        width: '700px',
        data: this.proyectoId
      });
  }

  changeTab() {
    this.cabeceras = [];
    this.searchForm.reset();
  }

  verInformacion(){
    this.dialog.open(
      InformacionReportesExcelComponent,
      {
        width: '800px'
      }
    );
  }


}
