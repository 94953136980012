import { FormStatusService } from './../../../../util/services/form-status/form-status.service';
import { EstadoProyectoService } from './../services/estado-proyecto.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from './../../../../util/services/error.service';
import { LocalStorageService } from './../../../../util/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EstadoProyectoResponse } from '../interfaces/estado-proyecto-response';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-crear-estado-proyecto',
  templateUrl: './crear-estado-proyecto.component.html',
  styleUrls: ['./crear-estado-proyecto.component.sass']
})
export class CrearEstadoProyectoComponent implements OnInit {
  public nuevoEstado: FormGroup;
  private token;
  process: boolean;
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService, private fS: FormStatusService,
    private estadoProyectoService: EstadoProyectoService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
  }

  makeForm() {
    this.nuevoEstado = this.fB.group({
      nombre: [null, Validators.required],
      codigo: [null, Validators.required],
      descripcion: [null]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoEstado);
    this.estadoProyectoService.crearEstadoProyecto(this.token, jsonData)
      .subscribe((respuesta: EstadoProyectoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Estado de proyecto creado exitosamente', 'Crear estado de proyecto');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoEstado.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
