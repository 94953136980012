import { ConfigProyectoGestionResponse } from './../interfaces/config-proyecto-gestion-response';
import { ConfigProyectoGestionService } from './../services/config-proyecto-gestion.service';
import { Component, OnInit, Inject } from '@angular/core';
import { CombosResponse } from 'src/app/util/interfaces/combos-response';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Combo } from 'src/app/util/interfaces/combo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ConfigProyectoGestion } from '../interfaces/config-proyecto-gestion';

export interface DialogData {
  proyectoId: number;
  componenteGestionId: number;
}
@Component({
  selector: 'app-detalle-config-proyecto-gestion',
  templateUrl: './detalle-config-proyecto-gestion.component.html',
  styleUrls: ['./detalle-config-proyecto-gestion.component.sass']
})
export class DetalleConfigProyectoGestionComponent implements OnInit {

  public proyectoGestionForm: FormGroup;
  editable = true;
  configuracionId: number;
  proyectoId: number;
  cargando: boolean;
  process: boolean;
  private token;
  componenteGestion: ConfigProyectoGestion;
  componentesGestion: Combo[];

  constructor(public dialogRef: MatDialogRef<DetalleConfigProyectoGestionComponent>, private fB: FormBuilder, private comboService: CombosService,
    private configProyectoGestionService: ConfigProyectoGestionService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.proyectoId = this.data.proyectoId;
    this.configuracionId = this.data.componenteGestionId;
    this.token = this.localStorage.getTokenFromStorage();
    this.makeForm();
    this.getComponentesGestionCombo();
    this.get();
  }

  makeForm() {
    this.proyectoGestionForm = this.fB.group({
      pro_componente_gestion_id: [{ value: null, disabled: this.editable }, Validators.required],
    });
  }

  get() {
    this.cargando = true;
    this.configProyectoGestionService.getProyectoGestion(this.proyectoId, this.configuracionId, this.token)
      .subscribe((respuesta: ConfigProyectoGestionResponse) => {
        this.componenteGestion = respuesta.data;
        this.proyectoGestionForm.patchValue(this.componenteGestion);
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }
  actualizar() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.proyectoGestionForm);
    this.configProyectoGestionService.actualizarProyectoGestion(this.proyectoId, this.configuracionId, this.token, jsonData)
      .subscribe((respuesta: ConfigProyectoGestionResponse) => {
        if (respuesta.success) {
          this.toastr.success('Proyecto gestión actualizado correctamente', 'Actualizar Proyecto Gestión');
          this.get();
          this.editableSwitcher();
          this.dialogRef.close('updated');
        }
      }, error => {
        this.errorService.handleError(error);
        this.proyectoGestionForm.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

  public editableSwitcher() {
    this.proyectoGestionForm = this.fS.cambiarEstado(this.proyectoGestionForm, this.editable);
    this.editable = !this.editable;
  }

  getComponentesGestionCombo() {
    this.comboService.getComponentesGestionCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.componentesGestion = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }


}
