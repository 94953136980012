import {ConfigTalleresParticipantesService} from './../../../../../editar-proyecto/config-talleres-participantes/services/config-talleres-participantes.service';
import {ConfigTalleresFacilitadoresService} from './../../../../../editar-proyecto/config-talleres/services/config-talleres-facilitadores.service';
import {TallerFormadorResponse} from './../interfaces/taller-formador-response';
import {TallerFormadorService} from './../services/taller-formador.service';
import {Component, OnInit, Inject} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Combo, ComboActores} from 'src/app/util/interfaces/combo';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormStatusService} from 'src/app/util/services/form-status/form-status.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from 'src/app/util/services/error.service';
import {LocalStorageService} from 'src/app/util/local-storage.service';
import {CombosService} from 'src/app/util/services/combos/combos.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ComboActoresResponse} from 'src/app/util/interfaces/combos-response';
import {TallerParticipantesService} from '../../taller-participante/services/taller-participantes.service';

export interface DialogData {
  tallerId: number;
  proyectoId: number;
  tipo: string;
}

@Component({
  selector: 'app-crear-taller-formador',
  templateUrl: './crear-taller-formador.component.html',
  styleUrls: ['./crear-taller-formador.component.sass']
})
export class CrearTallerFormadorComponent implements OnInit {
  public formador: FormGroup;
  private tallerId: number;
  private token;
  process: boolean;
  actores: any[];
  private proyectoId: number;
  tipo: string;
  facilitador: any[];

  constructor(public dialogRef: MatDialogRef<CrearTallerFormadorComponent>, private fB: FormBuilder,
              private tallerFormadorService: TallerFormadorService, private fS: FormStatusService, private toastr: ToastrService,
              private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private comboService: CombosService, private configTalleresFacilitadoresService: ConfigTalleresFacilitadoresService,
              private configTalleresParticipantesService: ConfigTalleresParticipantesService, private tallerParticipanteService: TallerParticipantesService) {
  }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.tallerId = this.data.tallerId;
    this.proyectoId = this.data.proyectoId;
    this.tipo = this.data.tipo;
    this.makeForm();
    this.getActores();
    this.getFacilitadores();
  }

  makeForm() {
    this.formador = this.fB.group({
      selected_id: [null, Validators.required],
      lugar: [null, Validators.required],
      fecha: [null, Validators.required],
      miembros: [null, Validators.required],
      pro_proyecto_taller_participante_id: [null],
      pro_proyecto_taller_facilitador_id: [null]
    });
  }

  getActores() {
    if (this.tipo === 'F') {
      this.configTalleresFacilitadoresService.getProyectoTalleresFacilitadores(this.proyectoId)
        .subscribe((respuesta: any) => {
          this.actores = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        });
    } else {
      this.configTalleresParticipantesService.getProyectoTalleresFacilitadores(this.proyectoId)
        .subscribe((respuesta: any) => {
          this.actores = respuesta.data;
        }, error => {
          this.errorService.handleError(error);
        });
    }
  }

  getFacilitadores() {
    const searchV = {tipo: 'F'};
    const paginator = {page: 'disable'};
    this.tallerParticipanteService.getTallerParticipantes(null, this.tallerId, searchV, paginator)
      .subscribe((response: any) => {
        this.facilitador = response.data;
        console.log(response.data);
      }, error => {
        this.errorService.handleError(error);
      });
  }

  crear() {
    this.process = true;
    if (this.tipo === 'F') {
      this.formador.get('pro_proyecto_taller_facilitador_id').setValue(this.formador.get('selected_id').value);
    } else {
      this.formador.get('pro_proyecto_taller_participante_id').setValue(this.formador.get('selected_id').value);
    }
    this.formador.get('selected_id').disable();
    const jsonData = this.fS.getJsonFromForm(this.formador);
    this.tallerFormadorService.crearTallerFormador(this.token, this.tallerId, jsonData)
      .subscribe((respuesta: TallerFormadorResponse) => {
        if (respuesta.success) {
          this.toastr.success('Instancia creada exitosamente', 'Crear Instancia');
          this.dialogRef.close('created');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.formador.enable();
      }).add(() => {
      this.process = false;
    });
  }

  volver() {
    this.dialogRef.close();
  }

  onSelectionChanged(event) {
    const toSet = [];
    event.value.forEach(element => {
      const obj = {pro_grupo_taller_miembro_id: element};
      toSet.push(obj);
    });
    this.formador.get('miembros').setValue(toSet);
  }

}
