import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class TipoDocumentoService {

  constructor(private http: HttpClient, private global: Globals) { }

  getTiposDocumento(token, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/documentosProyectosTipos', search, pagination);
    return this.http.get(url, { headers });
  }
  getTipoDocumento(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/documentosProyectosTipos/' + id;
    return this.http.get(url, { headers });
  }
  actualizarTipoDocumento(id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/documentosProyectosTipos/' + id;
    return this.http.put(url, json, { headers });
  }
  crearTipoDocumento(token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/documentosProyectosTipos';
    return this.http.post(url, json, { headers });
  }
  borrarTipoDocumento(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/documentosProyectosTipos/' + id;
    return this.http.delete(url, { headers });
  }
}
