import { Globals } from './../../../../../Globals';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormacionDigitalService {

  constructor(private http: HttpClient, private global: Globals) { }

  getFormacionesDigitales(proyectoId, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl(`/proyectos/${proyectoId}/formacionesDigitalesCabeceras`, search, pagination);
    return this.http.get(url, { headers });
  }

  getFormacionDigital(proyectoId, formacionDigitalId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + `/proyectos/${proyectoId}/formacionesDigitalesCabeceras/${formacionDigitalId}`;
    return this.http.get(url, { headers });
  }

  crearFormacionDigital(proyectoId, formData) {
    const url = this.global.API_ENDPOINT_ES + `/proyectos/${proyectoId}/formacionesDigitalesCabeceras`;
    return this.http.post(url, formData);
  }
}
