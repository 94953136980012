import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includesStrPipe'
})
export class IncludesStrPipePipe implements PipeTransform {

  transform(value: any, args?: string): boolean {
    let result;
    if (value.includes(args)) {
      result = true;
    } else {
      result = false;
    }
    return result;
  }

}
