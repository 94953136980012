import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ConfigProyectoInversoresService {

  constructor(private http: HttpClient, private global: Globals) { }

  getInversores(proyectoId, search = null, pagination = null) {
    const headers = this.global.getHeader();
    const url: string = this.global.makeUrl('/proyectos/' + proyectoId + '/proyectosInversores', search, pagination);
    return this.http.get(url, { headers });
  }
  getInversor(proyectoId, inversorId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosInversores/' + inversorId;
    return this.http.get(url, { headers });
  }
  actualizarInversor(proyectoId, inversorId, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosInversores/' + inversorId;
    return this.http.put(url, json, { headers });
  }
  crearInversor(proyectoId, json) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosInversores';
    return this.http.post(url, json, { headers });
  }
  borrarInversor(proyectoId, inversorId) {
    const headers = this.global.getHeader();
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/proyectosInversores/' + inversorId;
    return this.http.delete(url, { headers });
  }
}
