import { FilesService } from './../../../../../util/services/files/files.service';
import { UserProfileDialogComponent } from './user-profile-dialog/user-profile-dialog.component';
import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from '../../../../../theme-options';
import { Router } from '@angular/router';
import { User } from '../../../../../auth/user';
import { LocalStorageService } from '../../../../../util/local-storage.service';
import { AuthService } from '../../../../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from 'src/app/Globals';
import { PerfilResponse } from './user-profile-dialog/interfaces/perfil-response.interface';
import { UserProfileService } from './user-profile-dialog/services/user-profile.service';
import { Perfil } from './user-profile-dialog/interfaces/perfil.interface';
import { ErrorService } from '../../../../../util/services/error.service';
import { PermissionControllerService } from 'src/app/util/permission-controller/permission-controller.service';
export interface URL {
  url_image: string;
}
@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {
  cargando: boolean;
  perfil: Perfil;
  public usuario: User;
  imageToShow: any;
  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }
  constructor(
    private userProfileServices: UserProfileService,
    private errorService: ErrorService,
    private global: Globals,
    public globals: ThemeOptions,
    private router: Router, private authService: AuthService,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog, private fileService: FilesService,
    private permissionController: PermissionControllerService) {
  }

  logout() {
    // this.permissionController.cancelarRefrescarPermisos();
    this.authService.logout();
  }

  verPerfil() {
    const dialogRef = this.dialog.open(UserProfileDialogComponent, {
      width: '800px',
      data: { url_image: this.imageToShow }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        result = dialogRef.componentInstance.data;
      }
      if (result instanceof Object) {
        this.imageToShow = result.url_image;
      } else {
        this.imageToShow = result;
      }
    });
  }

  ngOnInit() {
    this.getPerfil();
    this.usuario = this.localStorageService.getUserFromStorage();
    // Acá se procede a cargar los permisos del usuario
  }

  getPerfil() {
    this.cargando = true;
    this.userProfileServices.getPerfil()
      .subscribe((respuesta: PerfilResponse) => {
        this.perfil = respuesta.data;
        this.imageToShow = this.global.FILE_ENDPOINT + respuesta.data.foto_usuario;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  /* getProfilePicture(fileUrl: string) {
     this.fileService.getUserFiles(fileUrl).subscribe(respuesta => {
       this.createImageFromBlob(respuesta);
       this.cargando = false;
     }, error => {
       console.warn(' ¡Atención! No se ha encontrado la foto de perfil del usuario.');
       this.imageToShow = './assets/images/avatars/avatar-man.png';
     }).add(() => { this.cargando = false; });
   }*/

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
