import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusquedaActoresComponent} from './busqueda-actores.component';
import {GrillaActoresComponent} from './grilla-actores/grilla-actores.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {BotonFiltroModule} from '../boton-filtro/boton-filtro.module';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {DatosNoEncontradosModule} from '../datos-no-encontrados/datos-no-encontrados.module';
import {SpinnerModule} from '../spinner/spinner.module';

@NgModule({
    declarations: [
        BusquedaActoresComponent,
        GrillaActoresComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatTableModule,
        MatSelectModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatInputModule,
        BotonFiltroModule,
        NgbAlertModule,
        DatosNoEncontradosModule,
        SpinnerModule
    ],
    exports: [
        BusquedaActoresComponent
    ],
    bootstrap: [
        GrillaActoresComponent
    ]
})
export class BusquedaActoresModule { }
