import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class PortafolioService {

  constructor(private http: HttpClient, private global: Globals) { }

  getPortafolios(token, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/portafolios', search, pagination);
    return this.http.get(url, { headers });
  }
  getPortafolio(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/portafolios/' + id;
    return this.http.get(url, { headers });
  }
  actualizarPortafolio(id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/portafolios/' + id;
    return this.http.put(url, json, { headers });
  }
  crearPortafolio(token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/portafolios';
    return this.http.post(url, json, { headers });
  }
  borrarPortafolio(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/portafolios/' + id;
    return this.http.delete(url, { headers });
  }
}
