import { CrearConfigProyectoUbicacionComponent } from './crear-config-proyecto-ubicacion/crear-config-proyecto-ubicacion.component';
import { ConfigProyectoUbicacionResponse } from './interfaces/config-proyecto-ubicacion-response';
import { ConfigProyectoUbicacion, UbicacionDepartamento, UbicacionCiudad, UbicacionComunidad } from './interfaces/config-proyecto-ubicacion';
import { Component, OnInit, Input } from '@angular/core';
import { ConfigProyectoUbicacionService } from './services/config-proyecto-ubicacion.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-config-proyecto-ubicacion',
  templateUrl: './config-proyecto-ubicacion.component.html',
  styleUrls: ['./config-proyecto-ubicacion.component.sass']
})
export class ConfigProyectoUbicacionComponent implements OnInit {

  @Input() proyectoId: number;
  @Input() paisId: number;
  cargando: boolean;
  private token;
  ubicaciones: ConfigProyectoUbicacion[];
  departamentos: UbicacionDepartamento[];
  ciudades: UbicacionCiudad[];
  comunidades: UbicacionComunidad[];
  total: number;
  constructor(private localStorage: LocalStorageService,
    private proyectoUbicacionService: ConfigProyectoUbicacionService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.getProyectoUbicaciones();
  }

  getProyectoUbicaciones() {
    this.cargando = true;
    this.proyectoUbicacionService.getProyectoUbicaciones(this.token, this.proyectoId, null, {page: 'disable'})
      .subscribe((respuesta: ConfigProyectoUbicacionResponse) => {
        this.ubicaciones = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    const dialogRef = this.dialog.open(CrearConfigProyectoUbicacionComponent, {
      width: '700px',
      data: { proyectoId: this.proyectoId, paisId: this.paisId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getProyectoUbicaciones();
      }
    });
  }

}
