import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class TemaIndicadorService {

  constructor(private http: HttpClient, private global: Globals) { }

  getTemaIndicadores(token, temaId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/temas/' + temaId + '/indicadores', search, pagination);
    return this.http.get(url, { headers });
  }
  getTemaIndicador(temaId, id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/temas/' + temaId + '/indicadores/' + id;
    return this.http.get(url, { headers });
  }
  actualizarTemaIndicador(temaId, id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/temas/' + temaId + '/indicadores/' + id;
    return this.http.put(url, json, { headers });
  }
  crearTemaIndicador(temaId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/temas/' + temaId + '/indicadores';
    return this.http.post(url, json, { headers });
  }
  borrarTemaIndicador(temaId, id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/temas/' + temaId + '/indicadores/' + id;
    return this.http.delete(url, { headers });
  }
}
