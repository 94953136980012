import { DocumentosEstadoService } from './services/documentos-estado.service';
import { DocumentosEstadosResponse, DocumentoEstadoResponse } from './interfaces/documento-estado-response';
import { DocumentoEstado } from './interfaces/documento-estado';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-documentos-estado',
  templateUrl: './documentos-estado.component.html',
  styleUrls: ['./documentos-estado.component.sass']
})
export class DocumentosEstadoComponent implements OnInit {


  heading = 'Documento Estados';
  subheading = 'En este módulo se encuentra la lista de documentos estados.';
  icon = 'pe-7s-look icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'descripcion', 'acciones'];

  private token;

  documentosEstados: DocumentoEstado[];
  searchForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder,
    private localStorage: LocalStorageService, private documentoEstadoService: DocumentosEstadoService, private errorService: ErrorService,
    private toastr: ToastrService, private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getEstadosProyecto();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getEstadosProyecto() {
    this.cargando = true;
    this.documentoEstadoService.getDocumentosEstados(this.token, this.searchForm.value, this.pagination)
      .subscribe((respuesta: DocumentosEstadosResponse) => {
        this.documentosEstados = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_documentos_estados/get447').then(result => {
      if (result) {
        this.router.navigate(['./', id], { relativeTo: this.route });
      }
    });
  }

  borrar(id: number) {
    this.documentoEstadoService.borrarDocumentoEstado(id, this.token)
      .subscribe((respuesta: DocumentoEstadoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Documento estado borrado exitosamente', 'Borrar Documento Estado');
        }
        this.getEstadosProyecto();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getEstadosProyecto();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getEstadosProyecto();
  }

  pageChange() {
    this.getEstadosProyecto();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
