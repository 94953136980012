/* tslint:disable:variable-name */
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ErrorService} from '../../services/error.service';
import {ComboPaisesResponse, CombosResponse} from '../../interfaces/combos-response';
import {CombosService} from '../../services/combos/combos.service';
import {LocalStorageService} from '../../local-storage.service';
import {ParametrosData} from '../../../Pages/Login/interfaces/parametros';

@Component({
  selector: 'app-grilla-personas',
  templateUrl: './grilla-personas.component.html',
  styleUrls: ['./grilla-personas.component.scss']
})
export class GrillaPersonasComponent implements OnInit {

  cargando: boolean;
  sinBusqueda = true;
  searchForm: FormGroup;
  paramDefault: ParametrosData;
  personas: any[] = [];
  tiposDocumento: any[] = [];
  paises: any[] = [];
  selected = null;
  displayedColumns = ['persona', 'documento', 'acciones'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public withoutUserReceived: any,
    private matDialogRef: MatDialogRef<GrillaPersonasComponent>,
    private fB: FormBuilder,
    private comboService: CombosService,
    private localStorage: LocalStorageService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
    this.paramDefault = this.localStorage.getParametrosFromStorage();
    this.makeForm();
    this.getPaises();
    this.getTiposDocumentos();
  }

  makeForm() {
    this.searchForm = this.fB.group({
      withoutUser: null,
      nombre_completo: null,
      nro_documento: null,
      adm_pais_id: { value: null, disabled: true },
      adm_documento_tipo_id: { value: null, disabled: true },
    });

    this.searchForm.get('nro_documento').valueChanges.subscribe(value => {
      if (value) {
        this.searchForm.controls.adm_pais_id.enable();
        this.searchForm.controls.adm_documento_tipo_id.enable();
      } else {
        this.searchForm.controls.adm_pais_id.disable();
        this.searchForm.controls.adm_documento_tipo_id.disable();
      }
    });
  }

  getPersonas() {
    this.cargando = true;
    if (this.withoutUserReceived) {
      this.searchForm.get('withoutUser').patchValue(true);
    }
    this.comboService.getPersonasOptimizado(this.searchForm.value)
      .subscribe((response: any) => {
        this.personas = response.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {
        this.cargando = false;
      });
  }

  getTiposDocumentos() {
    this.comboService.getTipoDocumentosCombo()
      .subscribe((respuesta: CombosResponse) => {
        this.tiposDocumento = respuesta.data;
        if (this.paramDefault.TIPODOCUMENTO) {
          this.searchForm.controls.adm_documento_tipo_id.setValue(this.paramDefault.TIPODOCUMENTO);
        }
      }, error => {
        this.errorService.handleError(error);
      });
  }

  getPaises() {
    this.comboService.getPaisesCombo()
      .subscribe((respuesta: ComboPaisesResponse) => {
        this.paises = respuesta.data;
        if (this.paramDefault.PAIS) {
          this.searchForm.controls.adm_pais_id.setValue(this.paramDefault.PAIS);
        }
      }, error => {
        this.errorService.handleError(error);
      });
  }

  closeBusqueda() {
    this.matDialogRef.close();
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  filtrar() {
    const nombre_completo = this.searchForm.get('nombre_completo').value;
    const nro_documento = this.searchForm.get('nro_documento').value;
    const adm_pais_id = this.searchForm.get('adm_pais_id').value;
    const adm_documento_tipo_id = this.searchForm.get('adm_documento_tipo_id').value;
    if (!nombre_completo && !nro_documento && !adm_pais_id && !adm_documento_tipo_id) {
      this.sinBusqueda = true;
      return;
    } else {
      this.sinBusqueda = false;
    }
    this.getPersonas();
  }

  onReset() {
    this.searchForm.reset();
    this.sinBusqueda = true;
  }

  personaSeleccionada(object: any) {
    this.matDialogRef.close(object);
  }
}
