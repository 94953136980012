import { Globals } from './../../../../../Globals';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportesGrupoAhorroService {

  constructor(private http: HttpClient, private globals: Globals) { }

  getGruposAhorroReporte(idProyecto) {
    const headers = this.globals.getHeader();
    const url = this.globals.API_ENDPOINT_ES + `/proyectos/${idProyecto}/gruposAhorrosReportes`;
    return this.http.get(url, { headers });
  }

  getGruposAhorroMiembrosReporte(idGrupoAhorro) {
    const headers = this.globals.getHeader();
    const url = this.globals.API_ENDPOINT_ES + `/gruposAhorros/${idGrupoAhorro}/gruposAhorrosMiembrosReportes`;
    return this.http.get(url, { headers });
  }

  getCiclosMiembrosReporte(idCiclo) {
    const headers = this.globals.getHeader();
    const url = this.globals.API_ENDPOINT_ES + `/gruposCiclos/${idCiclo}/gruposCiclosMiembrosReportes`;
    return this.http.get(url, { headers });
  }

  getGrupoAhorroCiclosReporte(idGrupoAhorro) {
    const headers = this.globals.getHeader();
    const url = this.globals.API_ENDPOINT_ES + `/gruposAhorros/${idGrupoAhorro}/gruposCiclosReportes`;
    return this.http.get(url, { headers });
  }
}
