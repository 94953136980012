import { CombosService } from 'src/app/util/services/combos/combos.service';
import { CombosResponse, ComboActoresResponse } from 'src/app/util/interfaces/combos-response';
import { TallerParticipanteResponse } from './../interfaces/taller-participante-response';
import { TallerParticipantesService } from './../services/taller-participantes.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ComboActores } from 'src/app/util/interfaces/combo';

export interface DialogData {
  tallerId: number;
  proyectoId: number;
}
@Component({
  selector: 'app-crear-taller-participante',
  templateUrl: './crear-taller-participante.component.html',
  styleUrls: ['./crear-taller-participante.component.sass']
})
export class CrearTallerParticipanteComponent implements OnInit {

  public participante: FormGroup;
  private tallerId: number;
  proyectoId: number;
  private token;
  process: boolean;
  actores: ComboActores[];
  participantes: any[];
  constructor(public dialogRef: MatDialogRef<CrearTallerParticipanteComponent>, private fB: FormBuilder,
    private tallerParticipanteService: TallerParticipantesService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private comboService: CombosService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.tallerId = this.data.tallerId;
    this.proyectoId = this.data.proyectoId;
    this.makeForm();
  }

  makeForm() {
    this.participante = this.fB.group({
      pro_actor_id: [null, Validators.required],
      tipo: ['P', Validators.required],
      actor: [{ value: null, disabled: true }]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.participante);
    this.tallerParticipanteService.crearTallerParticipante(this.token, this.tallerId, jsonData)
      .subscribe((respuesta: TallerParticipanteResponse) => {
        if (respuesta.success) {
          this.toastr.success('Participante creado exitosamente', 'Crear Participante');
          this.dialogRef.close('created');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.participante.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

  onCloseBusqueda(data: any): void {
    if (data) {
      const actor = `${data.nombre} ${data.apellido} - ${data.documento}`;
      this.participante.get('pro_actor_id').patchValue(data.pro_actor_id);
      this.participante.get('actor').patchValue(actor);
    }
  }
}
