import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from './user';
import { LocalStorageService } from '../util/local-storage.service';
import { ErrorService } from '../util/services/error.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginService } from '../Pages/Login/services/login.service';
import { ParametrosResponse } from '../Pages/Login/interfaces/parametros';
import { LoginResponse } from '../Pages/Login/interfaces/login';
import { PermissionControllerService } from '../util/permission-controller/permission-controller.service';
import {NgxPermissionsService} from 'ngx-permissions';

// import {tokenNotExpired} from 'angular2-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private usuario: User;

  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private router: Router,
              private localStorageService: LocalStorageService,
              private loginService: LoginService, private errorService: ErrorService,
              private ngxPermissions: NgxPermissionsService,
              private toastr: ToastrService, private permissionController: PermissionControllerService) {
  }

  get isLoggedIn() {
    const usuario = this.localStorageService.getUserFromStorage();
    if (usuario !== undefined && usuario !== null) {
      return true;
    }
    return false;

  }

  login(usuario: User, redirectUrl = null) {
    if (usuario !== undefined) {
      this.localStorageService.storeOnLocalStorage(usuario);
      this.parametrosPorDefecto();

      if (redirectUrl == null) {
        this.goToHome(usuario);
      } else {
        this.router.navigate([redirectUrl]).catch(this.handleError.bind(this, usuario));
      }
    }
  }

  public handleError = (error, usuario: User) => {
    this.goToHome(usuario);
    return Observable.throw(error);
  }

  private goToHome(usuario) {
    this.loggedIn.next(true);

    if (usuario.userData !== undefined && usuario.userData.misPermisos.length === 0) {
      this.router.navigate(['inicio']);
    } else {
      this.router.navigate(['inicio']);
    }

  }

  // logout() {
  //   this.localStorageService.cleanStorage();
  //   localStorage.clear();
  //   this.loggedIn.next(false);
  //   this.router.navigate(['/login']);
  // }

  logout() {
    this.usuario = this.localStorageService.getUserFromStorage();
    if (this.usuario == null) {
      this.router.navigate(['/login']);
      return;
    } else {
      // console.log(this.usuario.userData.access_token);

      this.loginService.logoutUsuario(this.usuario.userData.access_token)
        .subscribe((datos: LoginResponse) => {
          this.localStorageService.cleanStorage();
          this.permissionController.clearController();
          this.ngxPermissions.flushPermissions();
          this.goToLogin();
        },
          error => {
            const respuesta = error;
            if (respuesta.error.success === false) {
              const mensaje = respuesta.error.errors.join('<br>');
              this.toastr.error(mensaje, 'Error: ' + error.status);

              // En teoria con el interceptor ya no deberiamos tener que testear error 401
              // // Si el status es 401 enviamos directamente al login ya
              // // que quiere decir que este usuario ya no esta autorizado
              if (error.status === 401) {
                this.goToLogin();
              }

            } else {
              this.toastr.error('Ocurrio un error', 'Error: ' + error.status);
            }
          }).add(() => {  // Se llama a esta parte del codigo al finalizar la ejecución. Sea exitosa o no
          });
    }
  }

  goToLogin() {
    this.localStorageService.cleanStorage();
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  parametrosPorDefecto() {
    this.loginService.parametrosPorDefecto()
      .subscribe((datos: ParametrosResponse) => {
        this.localStorageService.storeParametros(datos.data);
      },
        error => {
          this.errorService.handleError(error);
        });
  }

  public getToken(): string {
    return this.localStorageService.getTokenFromStorage();
  }

  setToken(newToken: string) {
    this.localStorageService.setToken(newToken);
  }

  setRefreshToken(newToken: string) {
    this.localStorageService.setRefreshToken(newToken);
  }

  // public isAuthenticated(): boolean {
  //   // get the token
  //   const token = this.getToken();
  //   // return a boolean reflecting
  //   // whether or not the token is expired
  //   return tokenNotExpired(null, token);
  // }

  public renewAccessToken() {
    return this.loginService.refreshToken(this.localStorageService.getRefreshTokenFromStorage());
  }
}
