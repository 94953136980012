import { TemaIndicadorResponse } from './../interfaces/tema-indicador-response';
import { TemaIndicadorService } from './../services/tema-indicador.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';

export interface DialogData {
  temaId: number;
}
@Component({
  selector: 'app-crear-tema-indicador',
  templateUrl: './crear-tema-indicador.component.html',
  styleUrls: ['./crear-tema-indicador.component.sass']
})
export class CrearTemaIndicadorComponent implements OnInit {

  public nuevoIndicador: FormGroup;
  private temaId: number;
  private token;
  process: boolean;
  constructor(public dialogRef: MatDialogRef<CrearTemaIndicadorComponent>, private fB: FormBuilder, private router: Router, private route: ActivatedRoute,
    private temaIndicadorService: TemaIndicadorService, private fS: FormStatusService, private toastr: ToastrService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.temaId = this.data.temaId;
    this.makeForm();
  }

  makeForm() {
    this.nuevoIndicador = this.fB.group({
      nombre: [null, Validators.required],
      codigo: [null, Validators.required],
      es_ponderado: [null],
      descripcion: [null]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevoIndicador);
    this.temaIndicadorService.crearTemaIndicador(this.temaId, this.token, jsonData)
      .subscribe((respuesta: TemaIndicadorResponse) => {
        if (respuesta.success) {
          this.toastr.success('Indicador creado exitosamente', 'Crear Indicador');
          this.dialogRef.close('created');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevoIndicador.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.dialogRef.close();
  }

}
