import { WorkingOnItModule } from './util/working-on-it/working-on-it.module';
import { ProyectosModule } from './Pages/proyectos/proyectos.module';
import { ParametricosProyectoModule } from './Pages/parametricos-proyecto/parametricos-proyecto.module';
import { TrendModule } from 'ngx-trend';
import { DatosNoEncontradosModule } from './util/datos-no-encontrados/datos-no-encontrados.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { ArchitectUIState, rootReducer } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LaddaModule } from 'angular2-ladda';
import { NgxLoadingModule } from 'ngx-loading';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CountUpModule } from 'ngx-countup';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NouisliderModule } from 'ng2-nouislider';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TextMaskModule } from 'angular2-text-mask';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTreeModule } from '@angular/material/tree';
import { ThemeOptions } from './theme-options';
import { DrawerComponent } from './Layout/Components/header/elements/drawer/drawer.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GaugeModule } from 'angular-gauge';
import { PageTitleComponent } from './Layout/Components/page-title/page-title.component';
import { LoginComponent } from './Pages/Login/login/login.component';
import { PerfilComponent } from './Pages/Usuario/perfil/perfil.component';
import { Globals } from './Globals';
import { LayoutModule } from './Layout/layout.module';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { AuthTokenExpiredInterceptor } from './auth/auth-token-expired-interceptor';
import { HomeComponent } from './Pages/home/home.component';
import { RecuperarPasswordComponent } from './Pages/Login/login/recuperar-password/recuperar-password.component';
import { RecuperarExitoComponent } from './Pages/Login/login/recuperar-password/recuperar-exito/recuperar-exito.component';
import { SpinnerModule } from './util/spinner/spinner.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BotonFiltroModule } from './util/boton-filtro/boton-filtro.module';
import { BotoneraEdicionModule } from './util/botonera-edicion/botonera-edicion.module';
import { BotoneraCreacionModule } from './util/botonera-creacion/botonera-creacion.module';
import { AdministracionComponent } from './Pages/Administracion/administracion.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {RegistrarUsuarioComponent} from './Pages/Login/login/registrar-usuario/registrar-usuario.component';
import {OlvideContrasenhaComponent} from './Pages/Login/login/olvide-contrasenha/olvide-contrasenha.component';
import { NumberFormatPipe } from './util/pipes/numberFormat.pipe';
import {SwalComponentModule} from './util/swal-component/swal-component.module';
// Import your library
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppPipesModule } from './util/pipes/app-pipes.module';
import { ReporteComponent } from './Pages/Administracion/reporte/reporte.component';
import {NgxDashboardsBixModule} from './util/ngx-dashboards-bix/ngx-dashboards-bix.module';
import { BusquedaPersonasModule } from './util/busqueda-personas/busqueda-personas.module';
import { BusquedaActoresModule } from './util/busqueda-actores/busqueda-actores.module';
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    LoginComponent,
    PerfilComponent,
    HomeComponent,
    RecuperarPasswordComponent,
    RecuperarExitoComponent,
    AdministracionComponent,
    RegistrarUsuarioComponent,
    OlvideContrasenhaComponent,
    ReporteComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgReduxModule,
        CommonModule,
        LoadingBarRouterModule,
        LayoutModule,
        
        // Angular Bootstrap Components

        PerfectScrollbarModule,
        NgbModule,
        LaddaModule,
        FormsModule,
        ReactiveFormsModule,
        NgBootstrapFormValidationModule.forRoot(),
        NgxLoadingModule.forRoot({}),
        SweetAlert2Module.forRoot({
          provideSwal: Swal.mixin({
            buttonsStyling: false,
            customClass: {
              popup: 'modal-content',
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn'
            }
          })
        }),
        ToastrModule.forRoot(),
        SlickCarouselModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        CountUpModule,
        NouisliderModule,
        NgSelectModule,
        SelectDropDownModule,
        NgMultiSelectDropDownModule.forRoot(),
        HttpClientModule,
        TextMaskModule,
        TextareaAutosizeModule,

        // Charts
        NgApexchartsModule,
        GaugeModule.forRoot(),

        // Angular Material Components
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatStepperModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTreeModule,
        MatRippleModule,
        MatCheckboxModule,
        LottieModule.forRoot({ player: playerFactory }),
        SpinnerModule,
        MatButtonModule,
        FlexLayoutModule,
        BotonFiltroModule,
        BotoneraEdicionModule,
        DatosNoEncontradosModule,
        BotoneraCreacionModule,
        ParametricosProyectoModule,
        ProyectosModule,
        WorkingOnItModule,
        TrendModule,
        // Maps Module
        LeafletModule,
        SwalComponentModule,
        // Permisos
        NgxPermissionsModule.forRoot(),
        AppPipesModule,
        NgxDashboardsBixModule,
        BusquedaPersonasModule,
        BusquedaActoresModule
    ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,

    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenExpiredInterceptor,
      multi: true
    }, { provide: MAT_DATE_LOCALE, useValue: 'es-US' },
    ConfigActions,
    ThemeOptions,
    Globals,
    NgbActiveModal
  ],
  exports: [
    PageTitleComponent,
    PagesLayoutComponent,
    DrawerComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(  private ngRedux: NgRedux<ArchitectUIState>,
                private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
