import {Component, Input, OnInit} from '@angular/core';
import {ProyectosService} from '../services/proyectos.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '../../../util/local-storage.service';

@Component({
  selector: 'app-indicadores-proyecto',
  templateUrl: './indicadores-proyecto.component.html',
  styleUrls: ['./indicadores-proyecto.component.sass']
})
export class IndicadoresProyectoComponent implements OnInit {
  dashboardId: string;
  filter: string;
  proyectoId: number;
  constructor(private localStorage: LocalStorageService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.proyectoId = +this.route.snapshot.paramMap.get('idProyecto');
    this.filter = `&filter=vw_preguntas_respuestas/proyecto_id%20eq%20${this.proyectoId}`;
    this.dashboardId = this.localStorage.getParametrosFromStorage().DASHBOARD3_ID;
  }

}
