import { NgxPermissionsService } from 'ngx-permissions';
import { ConfiguracionParaproResponse, ConfiguracionesParaproResponse } from './interfaces/configuracion-parapro-response';
import { ConfiguracionesParaproService } from './services/configuraciones-parapro.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfiguracionParapro } from './interfaces/configuracion-parapro';

@Component({
  selector: 'app-configuraciones',
  templateUrl: './configuraciones.component.html',
  styleUrls: ['./configuraciones.component.sass']
})
export class ConfiguracionesComponent implements OnInit {

  heading = 'Configuraciones';
  subheading = 'En este módulo se encuentra la lista de configuraciones.';
  icon = 'pe-7s-settings icon-gradient bg-ripe-malin';
  cargando: boolean;
  total: number;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'codigo', 'tipo', 'valor', 'descripcion', 'acciones'];

  private token;
  configuraciones: ConfiguracionParapro[];
  searchForm: FormGroup;
  constructor(private router: Router, private route: ActivatedRoute, private fB: FormBuilder,
    private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private configuracionesParProService: ConfiguracionesParaproService, private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getConfiguraciones();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre: '',
      codigo: ''
    });
  }

  getConfiguraciones() {
    this.cargando = true;
    this.configuracionesParProService.getConfiguraciones(this.token, this.searchForm.value, this.pagination)
      .subscribe((respuesta: ConfiguracionesParaproResponse) => {
        this.configuraciones = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  crear() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  verDetalle(id: number) {
    this.permissionService.hasPermission('PARPRO/mant_configuraciones/get417').then(result => {
      if (result) {
        this.router.navigate(['./', id], { relativeTo: this.route });
      }
    });
  }

  borrar(id: number) {
    this.configuracionesParProService.borrarConfiguracion(id, this.token)
      .subscribe((respuesta: ConfiguracionParaproResponse) => {
        if (respuesta.success) {
          this.toastr.success('Configuración borrada exitosamente', 'Borrar Configuración');
        }
        this.getConfiguraciones();
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getConfiguraciones();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getConfiguraciones();
  }

  pageChange() {
    this.getConfiguraciones();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
