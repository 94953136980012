import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LocalStorageService} from '../../../../../../util/local-storage.service';
import {ErrorService} from '../../../../../../util/services/error.service';
import {ToastrService} from 'ngx-toastr';
import {TallerParticipantesService} from '../taller-participante/services/taller-participantes.service';
import {MatDialog} from '@angular/material/dialog';
import {TallerParticipanteResponse, TallerParticipantesResponse} from '../taller-participante/interfaces/taller-participante-response';
import {CrearTallerParticipanteComponent} from '../taller-participante/crear-taller-participante/crear-taller-participante.component';
import {DetalleTallerParticipanteComponent} from '../taller-participante/detalle-taller-participante/detalle-taller-participante.component';
import {HttpErrorResponse} from '@angular/common/http';
import {InformacionMiembroExcelComponent} from '../taller-participante/informacion-miembro-excel/informacion-miembro-excel.component';

@Component({
  selector: 'app-taller-facilitadores',
  templateUrl: './taller-facilitadores.component.html',
  styleUrls: ['./taller-facilitadores.component.sass']
})
export class TallerFacilitadoresComponent implements OnInit {
  @Input() tallerId: number;
  @Input() proyectoId: number;
  private token;
  pagination = { page: 1, per_page: 10 };
  selected = null;
  displayedColumns = ['nombre', 'documento', 'presente', 'acciones'];
  cargando: boolean;
  uploading: boolean;
  total: number;
  participantes: any[];
  searchForm: FormGroup;
  file: any;

  constructor(private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
              private tallerParticipanteService: TallerParticipantesService, public dialog: MatDialog, private fB: FormBuilder) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.makeSearchForm();
    this.getParticipantes();
  }

  makeSearchForm() {
    this.searchForm = this.fB.group({
      nombre_completo: '',
      documento: '',
      tipo: 'F'
    });
  }

  getParticipantes() {
    this.cargando = true;
    this.tallerParticipanteService.getTallerParticipantes(this.token, this.tallerId, this.searchForm.value, this.pagination)
      .subscribe((respuesta: TallerParticipantesResponse) => {
        this.participantes = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }, ()=> {
        this.cargando = false;
      });
  }

  crear() {
    const dialogRef = this.dialog.open(CrearTallerParticipanteComponent, {
      width: '700px',
      data: { tallerId: this.tallerId, proyectoId: this.proyectoId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'created') {
        this.getParticipantes();
      }
    });
  }

  verDetalle(id: number) {
    // const dialogRef = this.dialog.open(DetalleTallerParticipanteComponent, {
    //   width: '700px',
    //   data: { tallerId: this.tallerId, participanteId: id, proyectoId: this.proyectoId }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result === 'updated') {
    //     this.getParticipantes();
    //   }
    // });
  }

  borrar(id: number) {
    this.tallerParticipanteService.borrarTallerParticipante(this.tallerId, id)
      .subscribe((respuesta: TallerParticipanteResponse) => {
        if (respuesta.success) {
          this.toastr.success('Facilitador borrado exitosamente', 'Borrar Facilitador');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { this.getParticipantes(); });
  }

  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.searchForm.get('tipo').setValue('F');
    this.getParticipantes();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getParticipantes();
  }

  pageChange() {
    this.getParticipantes();
  }
  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  fileChangeEvent(files): void {
    this.uploading = true;
    this.file = files[0];
    if (this.validateFile()) {
      const formData = new FormData();
      formData.append('file', this.file, this.file.name);
      formData.append('id_proyecto', `${this.proyectoId}`);
      this.cargando = true;
      this.tallerParticipanteService.importarTalleresParticipantes(this.tallerId, formData)
        .subscribe((respnse: any) => {
          if (respnse.success) {
            this.toastr.success('Miembros cargados exitosamente', 'Carga de Miembros');
          }
        }, error => {
          this.errorService.handleError(error);
          console.log(error);
          this.cargando = false;
        }, () => {
          this.cargando = false;
          this.getParticipantes();
        }).add(()=> {
        this.uploading = false;
      });
    } else {
      this.toastr.error('Solo se permiten archivos de tipo  Excel', 'Atención');
      this.cargando = false;
      this.uploading = false;
    }

  }

  validateFile(): boolean {
    const allowedExtensions = /(.xls|.xlsx)$/i;
    if (!allowedExtensions.exec(this.file.name)) {
      return false;
    } else {
      return true;
    }
  }

  verInformacion(){
    this.dialog.open(
      InformacionMiembroExcelComponent,
      {
        width: '500px'
      }
    );
  }

}
