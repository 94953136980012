import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '../../../../../util/services/error.service';
import { LocalStorageService } from '../../../../../util/local-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CombosService } from '../../../../../util/services/combos/combos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GrupoAhorroService } from '../services/grupo-ahorro.service';
import { GrupoAhorroResponse } from '../interfaces/grupo-ahorro-response.interface';
import { GrupoAhorro } from '../interfaces/grupo-ahorro.interface';
import { Combo } from '../../../../../util/interfaces/combo';
import { CombosResponse } from '../../../../../util/interfaces/combos-response';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { ReportesGrupoAhorroService } from '../services/reportes-grupo-ahorro.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-detalle-grupo-gestion',
  templateUrl: './detalle-grupo-gestion.component.html',
  styleUrls: ['./detalle-grupo-gestion.component.sass']
})
export class DetalleGrupoGestionComponent implements OnInit {
  idProyecto: number;
  idGrupo: number;
  periodos = [];
  private token;
  cargando: boolean;
  pagination = { page: 1, per_page: 10 };
  grupoAhorro: GrupoAhorro;
  editable = true;
  selected = null;
  searchForm: FormGroup;
  comunidades: Combo[];
  total: number;
  displayedColumns = ['periodo', 'aporte_social', 'fechas', 'estado', 'acciones'];
  ciclos;
  downloading: boolean;
  reporteData: any;
  proyectoData: any;

  constructor(
    private gruposAhorroService: GrupoAhorroService,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    private fB: FormBuilder,
    private fs: FormStatusService,
    private route: ActivatedRoute,
    private router: Router,
    private reportesGrupoAhorroService: ReportesGrupoAhorroService,
    private readonly sweetAlert2Loader: SweetAlert2LoaderService
  ) {
    this.token = this.localStorage.getTokenFromStorage();
  }


  ngOnInit() {
    console.log(history.state.data);
    this.proyectoData = history.state.data;
    console.log(this.proyectoData);
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idGrupo = +this.route.snapshot.paramMap.get('idGrupo');
    this.makeForm();
    //this.getGrupoGestion();
    //this.getComunidadesCombo();
    //this.getGrupoAhorro();
    this.getCiclos();
  }

  makeForm() {
    this.searchForm = this.fB.group({
      nro_ciclo: ''
    });
  }

  /*getGrupoAhorro() {
    this.cargando = true;
    this.gruposAhorroService.getGrupoAhorro(this.token, this.idProyecto, this.idGrupo )
      .subscribe((respuesta: any) => {
        this.grupoAhorro = respuesta.data;
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  } */

  private getCiclos() {
    this.cargando = true;
    this.gruposAhorroService.getCiclos(this.idProyecto, this.idGrupo, this.searchForm.value, this.pagination)
      .subscribe((respuesta: any) => {
        this.ciclos = respuesta.data;
        console.log(this.ciclos)
        this.total = respuesta.metadata.total;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.cargando = false;
      });
  }

  borrar() { }

  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }

  volver() {
    this.router.navigate(['../../../../../'], { relativeTo: this.route });
  }


  onReset() {
    this.pagination.page = 1;
    this.searchForm.reset();
    this.getCiclos();
  }

  filtrar() {
    this.pagination.page = 1;
    this.getCiclos();
  }

  pageChange() {
    this.getCiclos();
  }

  onClick(event: any) {
    event ? this.filtrar() : this.onReset();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  verDetalle(id: number) {
    this.router.navigate(['./', 'ciclos', id], { relativeTo: this.route });
  }

  crearCiclo() {
    this.router.navigate(['./ciclos/new'], { relativeTo: this.route });
  }

  cambiarEstado(id) {
    const text = '<b>' + '¿Desea cerrar el ciclo? <br> <small><i> Obs: Una vez cerrado el ciclo del grupo de ahorro, no podrá registrar más información para el ciclo. </i></small>' + '</b>';
    this.sweetAlert2Loader.swal.then((swal) =>
      swal.fire({
        html: text,
        // imageUrl: './assets/images/trash-can.png',
        imageWidth: 50,
        imageHeight: 50,
        showCancelButton: true,
        confirmButtonText: 'Sí, cerrar ciclo!',
        confirmButtonColor: '#FF0000',
        cancelButtonText: 'No, cancelar',
        focusCancel: true,
        width: 400
      }).then((result) => {
        if (result.value) {
          const estado = { estado: 'C' };
          const jsonData = JSON.stringify(estado);
          this.gruposAhorroService.actualizarCiclo(this.idProyecto, this.idGrupo, id, jsonData)
            .subscribe((response: any) => {
              if (response.success) {
                this.toastr.success('El ciclo ha sido cerrado correctamente', 'Cerrar Ciclo');
              }
            }, error => {
              this.errorService.handleError(error);
            }, () => {
              this.getCiclos();
            });
        }
        // else if (result.dismiss === Swal.DismissReason.cancel) {
        //   Swal.fire(
        //     'Cancelled',
        //     'Your imaginary file is safe :)',
        //     'error'
        //   );
        // }
      })
    )
  }

  descargarReporte() {
    this.downloading = true;
    this.reportesGrupoAhorroService.getGrupoAhorroCiclosReporte(this.idGrupo)
      .subscribe((response: any) => {
        this.reporteData = response.data;
      }, error => {
        this.errorService.handleError(error);
        this.downloading = false;
      }, () => {
        this.exportAsExcel();
      });
  }

  exportAsExcel() {
    const exportedData = [];
    const excelFileName =  'grupos-ahorro-ciclos'
    this.reporteData.forEach(value => {
      const obj = {
        nro_ciclo: value.nro_ciclo,
        cantidad_miembros: (value.cantidad_miembros) ? value.cantidad_miembros : '0',
        aporte_fondo_social: (value.aporte_fondo_social) ? value.aporte_fondo_social : '0',
        aporte_fondo_prestamo: (value.aporte_fondo_prestamo) ? value.aporte_fondo_prestamo : '0',
        limite_acciones: (value.limite_acciones) ? value.limite_acciones : '0',
        valor_multa: (value.valor_multa) ? value.valor_multa : '0',
        monto_acumulado_social: (value.monto_acumulado_social) ? value.monto_acumulado_social : '0',
        estado: (value.estado === 'A') ? 'Activo' : 'Cerrado'
      }
      exportedData.push(obj);
    });
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(exportedData);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    XLSX.writeFile(workBook, `${excelFileName}.xlsx`);
    this.downloading = false;
  }


}
