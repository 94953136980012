import { PreguntaTipoResponse } from './../interfaces/pregunta-tipo-response';
import { PreguntasTiposService } from './../services/preguntas-tipos.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-crear-pregunta-tipo',
  templateUrl: './crear-pregunta-tipo.component.html',
  styleUrls: ['./crear-pregunta-tipo.component.sass']
})
export class CrearPreguntaTipoComponent implements OnInit {

  public nuevaPreguntaTipo: FormGroup;
  process: boolean;
  constructor(private fB: FormBuilder, private router: Router, private route: ActivatedRoute, private preguntatipoService: PreguntasTiposService,
    private fS: FormStatusService, private toastr: ToastrService, private errorService: ErrorService) { }

  ngOnInit() {
    this.makeForm();
  }

  makeForm() {
    this.nuevaPreguntaTipo = this.fB.group({
      nombre: [undefined, Validators.required],
      codigo: [undefined, Validators.required],
      descripcion: [undefined],
      tiene_opciones: [undefined]
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevaPreguntaTipo);
    this.preguntatipoService.crearPreguntaTipo(jsonData)
      .subscribe((respuesta: PreguntaTipoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Pregunta Tipo creada exitosamente', 'Crear Pregunta Tipo');
          this.volver();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevaPreguntaTipo.enable();
      }).add(() => { this.process = false; });
  }

  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
