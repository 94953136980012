import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../../../../util/local-storage.service';
import {DimensionesService} from '../services/dimensiones.service';
import {Dimension} from '../interfaces/dimension.interface';
import {DimensionResponse} from '../interfaces/dimension-response.interface';
import {ErrorService} from '../../../../../util/services/error.service';
import {CombosResponse} from '../../../../../util/interfaces/combos-response';
import {CombosService} from '../../../../../util/services/combos/combos.service';
import {Combo} from '../../../../../util/interfaces/combo';
import {ActorResponse} from '../../../actores-proyecto/interfaces/actor-response.interface';

@Component({
  selector: 'app-detalle-dimension-monitoreo-proyecto',
  templateUrl: './detalle-dimension-monitoreo-proyecto.component.html',
  styleUrls: ['./detalle-dimension-monitoreo-proyecto.component.sass']
})
export class DetalleDimensionMonitoreoProyectoComponent implements OnInit {
  dimensionForm: FormGroup;
  private token;
  cargando: boolean;
  dimension: Dimension;
  editable = false;
  idProyecto: number;
  dimensiones: Combo[];
  idDimension: number;
  constructor(
    private router: Router,
    private fB: FormBuilder,
    private toastr: ToastrService,
    private localStorage: LocalStorageService,
    private dimensionesService: DimensionesService,
    private comboService: CombosService,
    private errorService: ErrorService,
    private route: ActivatedRoute
  ) {
    this.token = this.localStorage.getTokenFromStorage();
    this.dimensionForm = fB.group({
      pro_dimension_id: null,
      activo: null
    });
  }

  ngOnInit() {
    this.idProyecto = +this.route.snapshot.paramMap.get('idProyecto');
    this.idDimension = +this.route.snapshot.paramMap.get('id');
    this.getDimensionProyecto();
    this.getDimensionesCombo();
  }

  getDimensionProyecto() {
    this.cargando = true;
    this.dimensionesService.getDimension(this.token, this.idProyecto, this.idDimension)
      .subscribe((respuesta: DimensionResponse) => {
          this.dimension = respuesta.data;
          this.dimensionForm.patchValue(this.dimension);
          this.dimensionForm.disable();
        }, error => {
          this.errorService.handleError(error);
        }
      ).add(() => {
      this.cargando = false;
    });
  }
  private getDimensionesCombo() {
    this.comboService.getDimensionesCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.dimensiones = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }).add(() => {});
  }
  editarDimensionProyecto(bandera: string) {
    if (bandera === 'C') {
      this.dimensionForm.reset();
      this.getDimensionProyecto();
    }
    this.editable = !this.editable;
    this.dimensionForm.enabled ? this.dimensionForm.disable() : this.dimensionForm.enable();
  }
  actualizarDimensionProyecto() {
    this.dimensionesService.editarDimension(this.token, this.idProyecto, this.idDimension, JSON.stringify(this.dimensionForm.value))
      .subscribe((respuesta: ActorResponse) => {
          this.toastr.success('Dimensión editada exitosamente', 'Edición de Dimensión');
          this.dimensionForm.disable();
          this.editable = false;
          this.getDimensionProyecto();
        },
        error => {
          this.toastr.error('Error al editar Dimensión', 'Edición de Dimensión', { toastClass: 'toastr-custom-error' });
        }).add(() => {
    });
  }
  evento($event: MouseEvent) {
    $event.preventDefault();
    this.volver();
  }
  volver() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

}
