import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class ConfigProyectoUbicacionService {

  constructor(private http: HttpClient, private global: Globals) { }

  getProyectoUbicaciones(token, proyectoId, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/proyectos/' + proyectoId + '/ubicaciones', search, pagination);
    return this.http.get(url, { headers });
  }
  getProyectoUbicacion(proyectoId, ubicacionId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/ubicaciones/' + ubicacionId;
    return this.http.get(url, { headers });
  }
  actualizarProyectoUbicacion(proyectoId, ubicacionId, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/ubicaciones/' + ubicacionId;
    return this.http.put(url, json, { headers });
  }
  crearProyectoUbicacion(token, proyectoId, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/ubicaciones';
    return this.http.post(url, json, { headers });
  }
  borrarProyectoUbicacion(proyectoId, ubicacionId, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/proyectos/' + proyectoId + '/ubicaciones/' + ubicacionId;
    return this.http.delete(url, { headers });
  }
}
