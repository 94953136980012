import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UbicacionComunidad } from '../interfaces/config-proyecto-ubicacion';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { ErrorService } from 'src/app/util/services/error.service';
import { ToastrService } from 'ngx-toastr';
import { ConfigProyectoUbicacionService } from '../services/config-proyecto-ubicacion.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DetalleConfigProyectoUbicacionComponent } from '../detalle-config-proyecto-ubicacion/detalle-config-proyecto-ubicacion.component';

@Component({
  selector: 'app-ubicacion-comunidades',
  templateUrl: './ubicacion-comunidades.component.html',
  styleUrls: ['./ubicacion-comunidades.component.sass']
})
export class UbicacionComunidadesComponent implements OnInit {

  @Input() comunidades: UbicacionComunidad[];
  @Input() proyectoId: number;
  @Output() changed?: EventEmitter<boolean> = new EventEmitter<boolean>();

  displayedColumns = ['nombre', 'ciudad', 'departamento', 'acciones'];
  selected = null;
  private token;
  constructor(private localStorage: LocalStorageService, private errorService: ErrorService, private toastr: ToastrService,
    private configUbicacionService: ConfigProyectoUbicacionService, private dialog: MatDialog) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
  }

  changeBackGroundColor(event, row) {
    this.selected = event.type == 'mouseover' ? row.id : null;
  }

  verDetalle(id: number) {
    const dialogRef = this.dialog.open(DetalleConfigProyectoUbicacionComponent, {
      width: '700px',
      data: { proyectoId: this.proyectoId, ubicacionId: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'updated') {
        this.changed.emit(true);
      }
    });
  }

  borrar(id: number) {
    this.configUbicacionService.borrarProyectoUbicacion(this.proyectoId, id, this.token)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Ubicación borrada exitosamente', 'Borrar Ubicación');
        }
        this.changed.emit(true);
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }, () => { });
  }
}
