import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SearchMiembroComponent } from './search-miembro/search-miembro.component';

@Component({
  selector: 'app-add-miembro',
  templateUrl: './add-miembro.component.html',
  styleUrls: ['./add-miembro.component.sass']
})
export class AddMiembroComponent implements OnInit {

  @Input() proyectoId: number;
  @Output() data: EventEmitter<any> = new EventEmitter();
  constructor(
    private matDialog: MatDialog
  ) { }

  ngOnInit() {
  }

  openSearcher() {
    this.matDialog.open(SearchMiembroComponent, {
      width: '700px',
      data: { proyectoId: this.proyectoId }
    }).afterClosed().subscribe((result: any) => {
      this.data.emit(result);
    });
  }

}
