import { CombosResponse } from './../../../../../util/interfaces/combos-response';
import { Combo } from 'src/app/util/interfaces/combo';
import { CombosService } from 'src/app/util/services/combos/combos.service';
import { ConfiguracionProyectoResponse } from './../interfaces/configuracion-proyecto-response';
import { Component, OnInit, Inject } from '@angular/core';
import { ConfiguracionesProyectoService } from '../services/configuraciones-proyecto.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/util/services/error.service';
import { LocalStorageService } from 'src/app/util/local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';

export interface DialogData {
  proyectoId: number;
}
@Component({
  selector: 'app-crear-configuracion-proyecto',
  templateUrl: './crear-configuracion-proyecto.component.html',
  styleUrls: ['./crear-configuracion-proyecto.component.sass']
})
export class CrearConfiguracionProyectoComponent implements OnInit {

  public nuevaConfiguracion: FormGroup;
  private proyectoId: number;
  private token;
  configuraciones: Combo[];
  process: boolean;
  constructor(public dialogRef: MatDialogRef<CrearConfiguracionProyectoComponent>, private fB: FormBuilder, private router: Router, private route: ActivatedRoute,
    private configuracionProyectoService: ConfiguracionesProyectoService, private fS: FormStatusService, private toastr: ToastrService, private comboService: CombosService,
    private errorService: ErrorService, private localStorage: LocalStorageService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.token = this.localStorage.getTokenFromStorage();
    this.proyectoId = this.data.proyectoId;
    this.makeForm();
    this.getConfiguracionesCombo();
  }

  makeForm() {
    this.nuevaConfiguracion = this.fB.group({
      pro_configuracion_id: [null, Validators.required],
    });
  }

  crear() {
    this.process = true;
    const jsonData = this.fS.getJsonFromForm(this.nuevaConfiguracion);
    this.configuracionProyectoService.crearConfiguracion(this.token, this.proyectoId, jsonData)
      .subscribe((respuesta: ConfiguracionProyectoResponse) => {
        if (respuesta.success) {
          this.toastr.success('Configuración creada exitosamente', 'Crear Configuración');
          this.dialogRef.close('created');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
        this.nuevaConfiguracion.enable();
      }).add(() => { this.process = false; });
  }

  getConfiguracionesCombo() {
    this.comboService.getConfiguracionesCombo(this.token)
      .subscribe((respuesta: CombosResponse) => {
        this.configuraciones = respuesta.data;
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

  volver() {
    this.dialogRef.close();
  }

}
