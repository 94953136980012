import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../../../Globals';

@Injectable({
  providedIn: 'root'
})
export class DimensionesService {

  constructor(
    private http: HttpClient,
    private global: Globals
  ) { }
  getDimensiones(token, idProyecto, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/dimensiones', search, pagination);
    return this.http.get(url, {headers});
  }

  getDimension(token, idProyecto, idDimension, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/dimensiones/' + idDimension, search, pagination);
    return this.http.get(url, {headers});
  }

  crearDimension(token, idProyecto, jsonData, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/dimensiones', search, pagination);
    return this.http.post(url, jsonData, {headers});
  }

  editarDimension(token, idProyecto, idDimension, jsonData, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/dimensiones/' + idDimension, search, pagination);
    return this.http.put(url, jsonData, {headers});
  }

  borrarDimension(token, idProyecto, idDimension, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url = this.global.makeUrl('/proyectos/' + idProyecto + '/dimensiones/' + idDimension, search, pagination);
    return this.http.delete(url, {headers});
  }
}
