import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuDetalleProyectoComponent } from './menu-detalle-proyecto.component';

@NgModule({
  declarations: [
    MenuDetalleProyectoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    NgbDropdownModule
  ],
  exports: [
    MenuDetalleProyectoComponent
  ]
})
export class MenuDetalleProyectoModule { }
