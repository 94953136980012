import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Overlay} from '@angular/cdk/overlay';
import {GrillaPersonasComponent} from './grilla-personas/grilla-personas.component';

@Component({
  selector: 'app-busqueda-personas',
  templateUrl: './busqueda-personas.component.html',
  styleUrls: ['./busqueda-personas.component.scss']
})
export class BusquedaPersonasComponent implements OnInit {

  @Input() withoutUser = false;
  @Output() data: EventEmitter<any> = new EventEmitter();

  constructor(
    private matDialog: MatDialog,
    private overlay: Overlay,
  ) { }

  ngOnInit() {
  }

  buscar() {
    this.matDialog.open(GrillaPersonasComponent, {
      width: '65vw',
      maxHeight: '70vh',
      autoFocus: false,
      data: this.withoutUser,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    }).afterClosed().subscribe((result: any) => {
      this.data.emit(result);
    });
  }
}
