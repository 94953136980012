import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class TallerTipoService {

  constructor(private http: HttpClient, private global: Globals) { }

  getTalleresTipos(token, search = null, pagination = null) {
    const headers = this.global.getHeader(token);
    const url: string = this.global.makeUrl('/gruposTalleresTipos', search, pagination);
    return this.http.get(url, { headers });
  }
  getTallerTipo(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleresTipos/' + id;
    return this.http.get(url, { headers });
  }
  actualizarTallerTipo(id, token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleresTipos/' + id;
    return this.http.put(url, json, { headers });
  }
  crearTallerTipo(token, json) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleresTipos';
    return this.http.post(url, json, { headers });
  }
  borrarTallerTipo(id, token) {
    const headers = this.global.getHeader(token);
    const url = this.global.API_ENDPOINT_ES + '/gruposTalleresTipos/' + id;
    return this.http.delete(url, { headers });
  }
}
